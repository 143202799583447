import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import AssetViewer from 'components/AssetViewer';
import DeleteDialog from 'components/DeleteDialog';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import Loading from 'components/UI/Loading';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteRequests,
  getRequests,
  getSingleRequest,
} from 'redux/actions/RequestsAction';
import { RESET_BLOCK_REQUESTS } from 'redux/reducers/RequestsReducer';
import RequestMoreDetails from '../ReviewerModal/RequestMoreDetails';
import ViewRequestDetails from './ViewRequestDetails';
import { Modal } from 'react-bootstrap';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';

const RequesterModal = ({
  selectRequest,
  setSelectRequest,
  setAssetData,
  setSelectedAsset,
}) => {
  if (!selectRequest) return;
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { requestCode } = useParams();
  const history = useHistory();
  const parentRef = useRef(null);

  const [assetViewer, setAssetViewer] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [hideHeader, setHideHeader] = useState(false);

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const { requestData: { code = '', status, user: requestUser } = {} } =
    selectRequest || {};

  const createdByMe = user?.code === requestUser?.code;

  const {
    updateRequest: { success: updateSuccess, error },
    deleteRequest: { loading: loadingDelete, success: successDelete },
    getSingleRequest: { data: singleData = {}, loading: singleLoading },
  } = useSelector(({ requests }) => requests);

  useEffect(() => {
    if (!singleLoading && selectRequest?.requestData?.code) {
      dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
  }, [selectRequest?.requestData?.code]);

  const toggleHandler = () => {
    setStep(step - 1);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const setReceiptList = (list) => {
    setAssetData && setAssetData(list);
  };

  const handleClose = () => {
    setSelectedAsset(null);
    setAssetData && setAssetData(null);
    dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'requestReceipts' });
    if (requestCode) history.push('/requests/funds');
    if (isLoaded) {
      dispatch(getRequests({ view_as: 'reviewer' }));
    }
    if (step === 2 && !isLoaded) {
      toggleHandler();
    } else {
      setSelectRequest(null);
      setAssetViewer(false);
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'getSingleRequest' });
    }
    toggleAssetViewer(false);
    setIsLoaded(false);
  };

  useEffect(() => {
    if (successDelete) {
      handleClose();
    }
  }, [successDelete]);

  useEffect(() => {
    if (updateSuccess) {
      setIsLoaded(true);
      dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
    if (error) setIsLoaded(false);
  }, [updateSuccess]);

  if (!selectRequest) return <div />;

  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <ViewRequestDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            setAssetViewer={handleAssetViewer}
            selectRequest={selectRequest}
            handleClose={handleClose}
            getReceiptList={setReceiptList}
            hideHeader={setHideHeader}
            parentRef={parentRef}
          />
        );
      case 2:
        return (
          <RequestMoreDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectRequest={singleData?.data?.fundRequest}
            userCode={user?.code}
            hideTitle
          />
        );

      default:
        return;
    }
  };

  const attemptDelete = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    dispatch(deleteRequests(code));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            handleTogglePopover();
            attemptDelete();
          }}
          className="actionLink text-danger svg-danger"
        >
          <Trash className="mr-4" /> <span>Delete request</span>
        </div>
      </div>
    );
  };

  const modalTitle = {
    1: 'Request details',
    2: 'Request more information',
  };

  return (
    <>
      <ModalContainerWrap
        parentRef={parentRef}
        isWhite={step > 1}
        modalName={modalTitle[step]}
        onClose={handleClose}
        action={
          step === 1 &&
          ['pending', 'draft'].includes(status) &&
          createdByMe && <Actions />
        }
        isPopoverOpen={isPopoverOpen}
        handleTogglePopover={handleTogglePopover}
        loader={<PaymentSkeleton />}
        loading={singleLoading}
        goBack={step > 1 && !isLoaded ? () => setStep(1) : null}
      >
        {renderCard()}
      </ModalContainerWrap>

      {deleting && (
        <Modal show={deleting} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete reqeust`}
            subTitle="Are you sure you want to delete this request? This action cannot be undone"
            onCancel={() => setDeleting(false)}
            onDelete={handleDelete}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}
    </>
  );
};

export default RequesterModal;
