import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { Skeleton } from 'antd';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import RequestEmptyStateData from 'pages/Reimbursement/requestEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getReimbursements } from 'redux/actions/ReimbursementsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { employeeColumnsReimbursement } from 'utils/mockData';
import { getQueryParams, updateStatus } from 'utils/utility';
import { buildEmployeeReimbursementTableData } from '../../../utils/helper';
import AddReimbursement from '../EmployeeModal/AddReimbursement';
import AddReimbursementBankDetails from '../EmployeeModal/AddReimbursementBankDetails';
import ViewReimbursementDetails from '../EmployeeModal/ViewReimbursementDetails';
import '../style.less';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import { approvalState } from 'components/FundRequest/ReviewerModal/approvalState';
import ReimbursementModal from '../ManagerModal';

const EmployeeReimbursement = ({
  isOtherPopoverOpen,
  setIsOtherPopoverOpen,
  isOpen,
  toggleHandler,
}) => {
  const [isOpenView, setIsOpenView] = useState(false);
  const [selectReimbursement, setSelectReimbursement] = useState(null);
  const [addBankDetails, setAddBankDetails] = useState(false);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const location = useLocation();
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetData, setAssetData] = useState(null);
  const [isApprover, setIsApprover] = useState(false);
  const {
    getReimbursement: { data: { meta = {} } = {}, data = {}, loading, success },
    reimbursementReceipts: { data: receiptAssets },
  } = useSelector(({ reimbursement }) => reimbursement);
  const [filteredQuery, setFilteredQuery] = useState({ status: ['pending'] });

  const {
    downloadAsset: { data: { asset } = {}, success: assetSuccess },
  } = useSelector(({ assets }) => assets);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (assetSuccess) {
      window.open(asset.url, 'Download');
    }
  }, [assetSuccess]);

  const { permissions } = allPermissions();

  const canView = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-view'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-create'],
  });

  const { page, total, hasMore, perPage, nextPage } = meta;
  const { reimbursements = [] } = data;
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  useEffect(() => {
    if (!reimbursements.length && canView && !location?.search)
      dispatch(getReimbursements({ view_as: 'reviewer' }));
    return () => {
      if ((filtered || location?.search) && canView)
        dispatch(getReimbursements({ view_as: 'reviewer' }));
    };
  }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getReimbursements({
          ...filteredQuery,
          view_as: 'reviewer',
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(getReimbursements({ view_as: 'reviewer', ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (location?.search && canView) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getReimbursements({ view_as: 'reviewer', status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  const handleRowClick = (row) => {
    const { yourTurnToApprove } = approvalState({
      data: row?.reimbursementsData,
      user,
    });

    setIsApprover(!!yourTurnToApprove);

    setSelectReimbursement(row);
    if (!isOtherPopoverOpen) {
      // toggleModal();
      setIsOpenView(!isOpenView);
    }
  };

  const handleFilter = (query) => {
    isFiltered.current = !!Object.keys(query).length;
    const { status: s, currency: c, from: f, to: t } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const to = t ? t.toString() : undefined;
    const from = f ? f.toString() : undefined;

    if ((!from && to) || (from && !to)) {
      return toastError('To filter by date, please set from and to date');
    }

    setFilteredQuery({
      status,
      currency,
      from,
      to,
      view_as: 'reviewer',
    });

    dispatch(
      getReimbursements({
        view_as: 'reviewer',
        status,
        currency,
        from,
        to,
      }),
    );
  };

  const handlePreviousPage = (page) => {
    dispatch(getReimbursements({ perPage, page, view_as: 'reviewer', ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getReimbursements({ perPage, page, view_as: 'reviewer', ...filteredQuery }));
  };

  useEffect(() => {
    dispatch(getBeneficiaryBank());
  }, []);

  const assetSingleData = {
    ...selectReimbursement?.reimbursementsData,
    type: 'Reimbursement',
  };

  if (loading)
    return (
      <section className="pt-3 fade-in">
        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />
          <div className="ms-auto">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '7rem' }}
            />
          </div>
        </div>

        <div className="mt-4">
          <Table
            columns={employeeColumnsReimbursement}
            data={buildEmployeeReimbursementTableData(reimbursements)}
            pagination
            hasCheckBox={false}
            loading
          />
        </div>
      </section>
    );

  return (
    <div className="reimbursements-wrapper">
      <TopBar
        showFilter
        searchVal={search}
        showBarSearch
        setSearchVal={setSearch}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withOutSearch
        withDate
      />
      <PendingOnboardingNotice />

      {!reimbursements.length ? (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no reimbursements for this filter"
                bodyText="Alter the filter to see your reimbursement"
                withButton={false}
              />
            </div>
          ) : (
            <RequestEmptyStateData
              openReimbursementModal={toggleHandler}
              showAction={canCreate}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12}>
                <Table
                  columns={employeeColumnsReimbursement}
                  data={buildEmployeeReimbursementTableData(reimbursements)}
                  onRowClick={handleRowClick}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={loading ? false : true}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  loading={loading}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      {/* {selectReimbursement && (
        <ViewReimbursementDetails
          setAssetData={setAssetData}
          setSelectedAsset={setSelectedAsset}
          selectReimbursement={selectReimbursement}
          setSelectReimbursement={setSelectReimbursement}
        />
      )} */}

      <ModalComponent
        active={!!selectReimbursement}
        selectedAsset={selectedAsset}
        assetData={assetData}
        setSelectedAsset={setSelectedAsset}
        singleData={assetSingleData}
        scroll={false}
      >
        {isApprover ? (
          <ReimbursementModal
            setAssetData={setAssetData}
            setSelectedAsset={setSelectedAsset}
            selectReimbursement={selectReimbursement}
            setSelectReimbursement={setSelectReimbursement}
          />
        ) : (
          <ViewReimbursementDetails
            setAssetData={setAssetData}
            setSelectedAsset={setSelectedAsset}
            selectReimbursement={selectReimbursement}
            setSelectReimbursement={setSelectReimbursement}
          />
        )}
      </ModalComponent>

      {!addBankDetails ? (
        <AddReimbursement
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          setAddBankDetails={setAddBankDetails}
        />
      ) : (
        <AddReimbursementBankDetails
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          setAddBankDetails={setAddBankDetails}
        />
      )}
    </div>
  );
};

export default EmployeeReimbursement;
