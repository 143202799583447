import {
  ApproveCheck,
  BankIcon,
  CancelICon,
  DownloadIcon,
  MailIcon,
  BankNoteO1Icon,
  RetryIcon,
  SendIcon,
  CalenderIcon,
  CheckIcon,
  SlashCircleIcon,
  UploadIcon,
  Lightning,
} from 'assets/icons';
import { TransactionLogo } from 'assets/images';
import classNames from 'classnames';
import ConfirmDialog from 'components/ConfirmDialog';
import { CurrencyType, TransactionStatusType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import ReceiptPdf from 'components/ReceiptPdf';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import TransactionDrawer from 'components/TransactionModal/TransactionDrawer';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import { toastError, toastSuccess } from 'components/UI/toast';
import { jsPDF, PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import 'jspdf-autotable';
import BatchPaymentDialog from 'pages/BatchPayment/BatchPaymentDialog';
import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { getCard } from 'redux/actions/CardAction';
import { Skeleton } from 'antd';
import {
  approveTransaction,
  cancelTransaction,
  exportTransaction,
  getTransaction,
  requeryTransaction,
  requestTransactionDetails,
  retryTransaction,
  getSingleTransaction,
  editTransactionById,
} from 'redux/actions/TransactionsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  columns,
  columnsTransactionCSV,
  columnsTransactionQuickBooks,
  columnsTransactionXero,
} from 'utils/mockData';
import { FEES, getQueryParams, updateStatus } from 'utils/utility';
import { ReactComponent as ReloadIcon } from '../../assets/icons/retry-icon.svg';
import {
  buildExportTransactionData,
  buildExportTransactionQuickBooks,
  buildExportTransactionXero,
  buildTransactionTableData,
  capitalizeFirstLetter,
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
} from '../../utils/helper';
import RetryTransactionModal from './components/RetryTransactionModal';
import TransactionEmptyStateData from './transactionEmptystateData';
import { createBulkPayment, payNowAction } from 'redux/actions/PaymentAction';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { approvalState } from './approvalState';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import DeclineRequest from 'components/FundRequest/ReviewerModal/DeclineRequest';
import CustomModal from 'components/UI/Modal';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import FloatingCta from 'components/BulkAction/FloatingCta';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { syncAccountingEntity } from 'redux/actions/IntegrationsActions';
import BujetiPayModal from 'components/bujetiPayModal';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import TransactionDetails from 'components/TransactionModal/TransactionDetails';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import { automate } from 'redux/actions/AutomationAction';

const TransactionTable = ({
  transactionCode = null,
  handleSelect,
  onRowSelect,
  queryParams,
  hasCustomEmptyState = false,
  customEmptyState,
  setOpenBulkAssign,
}) => {
  const [selectTransaction, setSelectTransaction] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const history = useHistory();
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatusType,
    ...CurrencyType,
  ]);
  const [cardFilterData, setCardFilterData] = useState({});
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({ ...queryParams });
  const [isRetryVisible, setIsRetryVisible] = useState(false);
  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [csvPlatform, setCsvPlatform] = useState([]);
  const [fees, setFees] = useState(0);
  const [source, setSource] = useState();
  const [payNow, setPayNow] = useState(true);
  const [loadActions, setLoadActions] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedRowInformation, setSelectedRowInformation] = useState(null);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [action, setAction] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [showSendFunds, setShowSendFunds] = useState(false);
  const [recipientCode, setRecipientCode] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetData, setAssetData] = useState(null);

  const {
    getCard: { data: cardData, loading: isCardLoading, success: isCardSuccess },
  } = useSelector(({ card }) => card);

  const {
    getTransaction: { data: { meta = {} } = {}, data = {}, loading, success },
    exportTransaction: {
      data: { transactions: exportData = [] } = {},
      success: exportSuccesss,
      loading: exportLoading,
    },
    getSingleTransaction: {
      data: singleTransaction = {},
      error,
      success: successSingleTransaction,
    },
    retryTransaction: { loading: retryLoading, success: retrySuccess },
    approveTransaction: { loading: approveLoading, success: approveSuccess },
    cancelTransaction: { loading: cancelLoading, success: cancelSuccess },
    editTransactionById: { loading: loadingUpdate },
    requeryTransaction: { loading: loadingRequery, success: successRequery },
  } = useSelector(({ transaction }) => transaction);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
    createBulkPayment: { success: paymentCompleted, loading: isPaying },
  } = useSelector(({ payments }) => payments);

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const { page, total, hasMore, perPage, nextPage } = meta;
  const { transactions = [] } = data;
  const {
    getBudget: { data: budgetData = {} },
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    selectedTableRows: { selectedRows, type: rowType },
  } = useSelector(({ table }) => table);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const activeTxCode = useRef(null);

  const { permissions } = allPermissions();

  const canViewTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-view'],
  });

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const toggleHandler = () => {
    return history.push('/expenses/budgets');
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
    setAssets({
      ...assets,
      assetsList: row?.transactionData?.receipts,
    });
    activeTxCode.current = null;
  };

  useEffect(() => {
    dispatch(getCard());
  }, []);

  const onHandleAssetViewer = () => {
    setAssets({
      ...assets,
      visible: true,
    });
  };

  const rows = useMemo(() => buildTransactionTableData(transactions), [transactions]);

  useEffect(() => {
    if (transactions.length) {
      const allTransactions = transactions.map((transaction) => {
        return {
          value: transaction.payer ? transaction.payer.code : null,
          label: transaction.payer
            ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
            : '-',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (initialValue, index, array) =>
          array.findIndex((v2) => v2.value === initialValue.value) === index,
      );

      const newItem = { title: 'Payer', list: uniqueArray };

      if (!filterData.some((item) => item.title === newItem.title)) {
        setFilterData([...filterData, newItem]);
      }

      if (transactionCode) {
        dispatch(getSingleTransaction(transactionCode));
      }
    }
  }, [transactions]);

  useEffect(() => {
    if (paymentCompleted) {
      setIsSubmit(false);
      dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: true } });
      dispatch(getTransaction({ ...filteredQuery }));
    }
  }, [paymentCompleted]);

  useEffect(() => {
    if (successSingleTransaction && transactionCode) {
      const row = buildTransactionTableData([singleTransaction?.data]);
      const element = row.find(
        ({ transactionData: { code } }) => code === transactionCode,
      );
      setSelectTransaction(element);
    }
  }, [successSingleTransaction]);

  useEffect(() => {
    if (!!budgetData?.budgets?.length) {
      const data = [];
      budgetData?.budgets.forEach((budget) => {
        const { code, name } = budget;
        data.push({
          value: code,
          label: name,
          isSelected: false,
        });
      });
      setFilterData([...filterData, { title: 'Budget', list: data }]);
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    if (!isCardLoading && cardData?.cards?.length > 0) {
      const cardFilterOptions = cardData?.cards?.map((datum) => {
        return {
          label: datum.name,
          value: datum.code,
          isSelected: false,
        };
      });

      if (filterData?.find((item) => item.title === 'Card')) {
        return undefined;
      }
      setCardFilterData({ title: 'Card', list: cardFilterOptions });
      setFilterData([...filterData, { title: 'Card', list: cardFilterOptions }]);
    }
  }, [isCardLoading, cardData]);

  useEffect(() => {
    if (!transactions.length && !location?.search && !filtered && canViewTransaction) {
      dispatch(getTransaction({ ...queryParams }));
    }
    if (!budgetData?.budgets && canViewBudget) dispatch(getBudgets());

    return () => {
      if (filtered && canViewTransaction) dispatch(getTransaction({ ...queryParams }));
    };
  }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canViewTransaction) {
      filteredQuery.search = debouncedValue;
      dispatch(getTransaction({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewTransaction) {
      delete filteredQuery.search;
      dispatch(getTransaction({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (location?.search && canViewTransaction) {
      const code = getQueryParams(location?.search, 'card');
      if (code) dispatch(getTransaction({ card: [code] }));
      // isFiltered.current = true;

      const status = getQueryParams(location?.search, 'status');
      if (status) {
        setFilteredQuery({ status });
        dispatch(getTransaction({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  const handleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'Amount');
    const [min, max] = amount[0]?.value || [];
    const min_amount = min ? min * 100 : undefined;
    const max_amount = max ? max * 100 : undefined;
    const queryPayload = {
      ...query,
      ...queryParams,
      min_amount,
      max_amount,
    };

    setFilteredQuery(queryPayload);

    dispatch(getTransaction(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleExport = () => {
    dispatch(
      exportTransaction({ ...filteredQuery, search: debouncedValue ?? undefined }),
    );
  };

  const handleExportPlatform = (platform) => {
    dispatch(
      exportTransaction({ ...filteredQuery, search: debouncedValue ?? undefined }),
    );
    if (platform === 'quickbooks') setCsvPlatform(csvDataQuickBooks);
    if (platform === 'xero') setCsvPlatform(csvDataXero);
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = 'Transactions Report';
    const headers = [
      columnsTransactionCSV
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportTransactionData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Transaction Statement.pdf');
  };
  const csvData = [
    columnsTransactionCSV
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportTransactionData(exportData),
  ];

  const csvDataXero = [
    columnsTransactionXero.map((item) => item.Header),
    ...buildExportTransactionXero(exportData),
  ];

  const csvDataQuickBooks = [
    columnsTransactionQuickBooks.map((item) => item.Header),
    ...buildExportTransactionQuickBooks(exportData),
  ];

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([
      { title: 'Amount', type: 'slider' },
      ...TransactionStatusType,
      ...CurrencyType,
      cardFilterData,
    ]);
  };

  const handlePagination = (page) => {
    isFiltered.current = true;
    dispatch(getTransaction({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandleRetryClick = () => {
    setIsRetryVisible(true);
  };

  const clearLocationState = () => {
    history.replace({ state: {} });
    dispatch(getTransaction({ ...queryParams }));
    if (canViewBudget) dispatch(getBudgets());
  };

  useEffect(() => {
    if (location?.search && isFiltered.current && cardFilterData) {
      const Card = getQueryParams(location?.search, 'card');
      if (Card) {
        setFilterData(updateStatus(filterData, 'Card', Card));
      }
    }
  }, [isFiltered, location, cardFilterData]);

  const tableColumn = useMemo(
    () =>
      columns.filter((item) => {
        if (canEditTransaction) {
          if (selectedRows.length > 0) {
            return ['Paid on', 'Files', 'Action'].includes(item.Header) === false;
          } else {
            return item;
          }
        } else {
          return item.Header !== 'Actions';
        }
      }),
    [transactions, selectedRows.length],
  );

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();

    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());
    setIsLoaded(false);

    if (['pay_again', 'repeat_transaction'].includes(type)) {
      setShowSendFunds(true);
      setRecipientCode(value);
      return;
    }
    if ('syncZoho' === type) return handleSyncZoho(value);
    if (!['approve_schedule', 'decline_request'].includes(type))
      return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
    setSource();
  };

  useEffect(() => {
    if (
      retrySuccess ||
      cancelSuccess ||
      successRequery ||
      approveSuccess ||
      successPayNow ||
      successReview
    ) {
      closeModal();
      isFiltered.current = true;
      dispatch(getTransaction({ ...filteredQuery }));
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getSingleTransaction' });
      activeTxCode.current = null;
      setIsLoaded(true);
      setIsSchedule(false);
    }
  }, [
    retrySuccess,
    cancelSuccess,
    successRequery,
    approveSuccess,
    successPayNow,
    successReview,
  ]);

  useEffect(() => {
    if (error) {
      setIsPopoverOpen(false);
      activeTxCode.current = null;
    }
  }, [error]);

  const possibleSource =
    selectedOption?.balance?.code ||
    selectedOption?.source?.code ||
    selectedOption?.budget?.code;

  const handleConfirm = ({ schedule }) => {
    const code = selectedOption?.code;
    if (type === 'retry') {
      dispatch(retryTransaction(code));
    } else if (type === 'cancel') {
      dispatch(cancelTransaction(code));
    } else if (type === 'refresh') {
      dispatch(requeryTransaction(code));
    } else if (type === 'resolve') {
      dispatch(approveTransaction(code));
    } else if (type === 'pay') {
      if (possibleSource) {
        return dispatch(payNowAction({ code }));
      } else if (!source) {
        return toastError('Select a source');
      }
      return dispatch(payNowAction({ code, source: source.value }));
    } else if (['approve and pay', 'approve', 'approve_schedule'].includes(type)) {
      handleApproveRequest({
        schedule,
        code: selectedOption?.approvalRequest?.code,
      });
    }
  };

  const handleApproveRequest = ({ schedule, code }) => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Receipt downloaded');
  };

  const handleSyncZoho = (transaction) => {
    dispatch(
      syncAccountingEntity({
        codes: [transaction.code],
        platform: 'zoho',
        element: 'transactions',
      }),
    );
  };

  useEffect(() => {
    if (singleTransaction?.data) setLoadActions(true);
  }, [singleTransaction?.data]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.transactionData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleTransaction(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      useMemo(() => {
        if (selectedData?.transactionData?.code !== activeTxCode.current) {
          setLoadActions(false);
          if (['success', 'processed', 'pending'].includes(status))
            fetchSingleData(selectedData);
        }
      }, []);

      const { canApprovePay, yourTurnToApprove } = approvalState({
        data: singleTransaction?.data,
        user,
      });

      if (!loadActions && ['success', 'processed', 'pending'].includes(status))
        return (
          <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
            <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
          </div>
        );

      return (
        <div className="actions-dialog">
          {!yourTurnToApprove && ['scheduled', 'declined'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) => {
                setIsPopoverOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              No options available
            </div>
          )}

          {loadActions && yourTurnToApprove && (
            <>
              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setPayNow(canApprovePay);
                    actionHandler(event, 'approve and pay', singleTransaction?.data);
                  }}
                >
                  <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                  Approve and pay
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setPayNow(false);
                  actionHandler(event, 'approve', singleTransaction?.data);
                }}
              >
                <CheckIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                Approve only
              </div>

              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setIsSchedule(true);
                    actionHandler(event, 'approve_schedule', singleTransaction?.data);
                  }}
                >
                  <CalenderIcon
                    stroke="#79716B"
                    width={16}
                    height={16}
                    className="mr-4"
                  />
                  Approve and schedule
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setDeclineRequest(true);
                  setIsLoaded(false);
                  actionHandler(event, 'decline_request', singleTransaction?.data);
                }}
              >
                <SlashCircleIcon
                  stroke="#79716B"
                  width={16}
                  height={16}
                  className="mr-4"
                />
                Decline request
              </div>

              <div
                className="actionLink gap-8"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(automate());
                }}
              >
                <Lightning
                  style={{
                    marginLeft: '2px',
                    marginRight: '0px',
                    color: '#57534E',
                  }}
                />{' '}
                Automation
              </div>
            </>
          )}

          {['processed', 'processing'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, 'refresh', selectedData?.transactionData)
              }
            >
              <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
              Refresh transaction
            </div>
          )}

          {canEditTransaction && status === 'out-of-policy' && (
            <>
              <div
                className="actionLink"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(
                    requestTransactionDetails(selectedData?.transactionData?.code),
                  );
                  setIsPopoverOpen(true);
                }}
              >
                <MailIcon width="18" height="15" stroke="#79716B" /> Request details
              </div>
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(event, 'resolve', selectedData?.transactionData)
                }
              >
                <ApproveCheck /> Resolve
              </div>
            </>
          )}

          {['failed'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, 'retry', selectedData?.transactionData)
              }
            >
              <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Retry
              transaction
            </div>
          )}

          {['approved'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, 'pay', selectedData?.transactionData)
              }
            >
              <BankNoteO1Icon style={{ marginLeft: '-4px', marginRight: '6px' }} /> Make
              payment
            </div>
          )}

          {['pending'].includes(status) && (
            <div
              className="actionLink svg-danger text-danger ms-1"
              onClick={(event) =>
                actionHandler(event, 'cancel', selectedData?.transactionData)
              }
            >
              <CancelICon className=" mr-4" width={10} height={10} /> Cancel transaction
            </div>
          )}

          {['success'].includes(status) && (
            <>
              <div
                className="actionLink ms-1"
                onClick={(event) =>
                  actionHandler(event, 'pay_again', {
                    code: selectedData?.transactionData?.recipient?.code,
                    email: selectedData?.transactionData?.recipient?.email,
                    amount: selectedData?.transactionData?.amount,
                    source: selectedData?.transactionData?.source,
                    category: selectedData?.transactionData?.category,
                    bank_account: selectedData?.transactionData?.bank_account,
                  })
                }
              >
                <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Make
                new payment
              </div>
              <div
                className="actionLink ms-1"
                onClick={(event) =>
                  actionHandler(event, 'repeat_transaction', {
                    code: selectedData?.transactionData?.recipient?.code,
                    amount: selectedData?.transactionData?.amount,
                    source: selectedData?.transactionData?.source,
                    category: selectedData?.transactionData?.category,
                    bank_account: selectedData?.transactionData?.bank_account,
                    repeat: true,
                  })
                }
              >
                <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
                Repeat transaction
              </div>
              <PDFDownloadLink
                document={
                  <ReceiptPdf
                    data={{
                      ...selectedData?.transactionData,
                      senderAccount: singleTransaction?.data?.senderAccount,
                    }}
                    companyData={companyData}
                  />
                }
                fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
                style={{
                  textDecoration: 'none',
                  height: 40,
                  display: 'flex',
                  width: '100%',
                  fontFamily: 'Inter var !important',
                  color: '#212529',
                  alignItems: 'center',
                }}
                className="actionLink"
                onClick={(event, instance) => generatePdfSuccess(event, instance)}
              >
                <DownloadIcon className="mr-4" width={16} height={16} />
                Download receipt
              </PDFDownloadLink>
              {!selectedData?.transactionData?.isZohoSynced && (
                <div
                  className="actionLink"
                  onClick={(event) =>
                    actionHandler(event, 'syncZoho', selectedData?.transactionData)
                  }
                >
                  <UploadIcon className="mr-4" width={16} height={16} />
                  Sync to Zoho Books
                </div>
              )}
            </>
          )}
        </div>
      );
    },
    [singleTransaction?.data?.code, loadActions],
  );

  useEffect(() => {
    if (!balances?.budgets?.length) dispatch(getBalances());
  }, []);

  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  const calculateSum = (requestLists) => {
    let totalSum = requestLists.reduce(
      (total, { transactionData: { amount = 0 } }) => amount + total,
      0,
    );

    return totalSum / 100;
  };

  useEffect(() => {
    if (rowType === 'payments') {
      let totalAllSum = calculateSum(selectedRows);

      const filteredApprovedRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'approved' === rowStatus,
      );

      setSelectedRowInformation({
        all: {
          count: selectedRows.length,
          sum: totalAllSum,
        },
        approved: {
          count: filteredApprovedRows?.length,
        },
      });

      setFilteredSelectedRows({
        approved: filteredApprovedRows,
      });
    }
  }, [selectedRows.length]);

  const handlePay = () => {
    if (action === 'pay') {
      let hasNoSource = 0;
      const payload = {
        items: filteredSelectedRows?.['approved']?.map(
          ({ transactionData: { code = '', source } }) => {
            if (!source) hasNoSource += 1;
            return {
              code,
              source: source?.code,
            };
          },
        ),
      };
      if (hasNoSource > 0)
        return toastError(`${hasNoSource} of the selected items has no source`);
      dispatch(createBulkPayment(payload));
    }
  };

  const handleAction = (data) => {
    if (['pay'].includes(data)) {
      setTotalAmount(calculateSum(filteredSelectedRows['approved']) ?? 0);
      setFees((selectedRowInformation?.['approved']?.count ?? 0) * FEES);
    }
    setAction(data);
    setIsSubmit(!isSubmit);
  };

  const handleUpdateRequest = (code, field, value) => {
    dispatch(
      editTransactionById({
        [field]: value,
        transaction_code: code,
        showSuccessToast: false,
      }),
    );
  };

  const show = !!transactions?.length || (filtered && !transactions?.length);
  const loadingState = loading && !filtered;

  if (loadingState)
    return (
      <section className="pt-3 fade-in">
        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />

          <div className="ms-auto d-flex gap-2">
            {Array.from({ length: 2 }).map((_, i) => (
              <Skeleton.Button
                key={i}
                active
                shape="square"
                style={{ borderRadius: 8, height: '40px', width: '7rem' }}
              />
            ))}
          </div>
        </div>

        <div className="mt-4">
          <Table columns={tableColumn} data={rows} hasCheckBox={false} loading />
        </div>
      </section>
    );

  return (
    <>
      <TopBar
        showFilter={show}
        addOnClick={toggleHandler}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        searchVal={search}
        setSearchVal={setSearch}
        showBarSearch={show}
        addExport={show}
        exportLoading={exportLoading}
        handleFilterApply={handleFilter}
        withDate
        dateTitle="Transaction Date"
        handleExport={handleExport}
        handleExportPlatform={handleExportPlatform}
        csvData={csvData}
        csvPlatform={{ quickbooks: csvDataQuickBooks, xero: csvDataXero }}
        exportPDF={exportPDF}
        clearFilters={clearFilters}
        showRetry={false}
        onRetryClick={onHandleRetryClick}
      />
      {!transactions.length ? (
        <>
          {hasCustomEmptyState ? (
            customEmptyState
          ) : (
            <div>
              {isFiltered.current ? (
                <div className="tabinnerWrapper">
                  <NoData
                    headerText={`You have no transactions for this ${
                      location?.search ? 'card' : 'filter'
                    }`}
                    bodyText="Alter filter to see transactions"
                    withButton={true}
                    buttonLabel="View all transactions"
                    onClickHandler={clearLocationState}
                  />
                </div>
              ) : (
                <TransactionEmptyStateData handleSelect={handleSelect} />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={loadingState ? false : canEditTransaction}
                  currentPage={page}
                  onRowSelect={onRowSelect}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  // getSelectedRows={ApprovalModal}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loadingState}
                />
              </Col>
            </Row>
            {selectedRows.length > 0 && rowType === 'payments' && (
              <FloatingCta
                hasApproval={false}
                selectedRowInformation={selectedRowInformation}
                selectedRows={selectedRows}
                handleAction={handleAction}
                setOpenBulkAssign={setOpenBulkAssign}
              />
            )}
          </Container>

          <ModalComponent
            active={!!selectTransaction}
            selectedAsset={selectedAsset}
            assetData={assetData}
            setSelectedAsset={setSelectedAsset}
            singleData={selectTransaction?.transactionData}
            scroll={false}
          >
            <TransactionDetails
              setAssetData={setAssetData}
              setSelectedAsset={setSelectedAsset}
              selectTransaction={selectTransaction}
              setSelectTransaction={setSelectTransaction}
            />
          </ModalComponent>

          {isRetryVisible && (
            <RetryTransactionModal
              show={isRetryVisible}
              onHide={() => setIsRetryVisible(false)}
            />
          )}
        </>
      )}

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} transaction`}
          subTitle={`Are you sure you want to ${type} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={type === 'cancel'}
          extraChild={
            (!possibleSource || selectedOption.status === 'failed') && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      setSource(val);
                      selectedOption.status === 'failed' &&
                        handleUpdateRequest(selectedOption?.code, 'source', val.value);
                    }}
                    value={source}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
          actionBtnText="Confirm"
          loading={
            retryLoading ||
            cancelLoading ||
            loadingRequery ||
            approveLoading ||
            loadingPayNow ||
            loadingReview
          }
          disable={loadingUpdate}
          dialogIcon={type === 'retry' ? <ReloadIcon /> : null}
        />
      </Modal>

      {selectedRows.length > 0 && rowType === 'payments' && (
        <>
          {action === 'pay' && (
            <Modal
              show={isSubmit}
              centered
              dialogClassName="custom-dialog batch-payment-confirm"
            >
              <BatchPaymentDialog
                disabled={isPaying}
                onClick={handlePay}
                onCancel={() => {
                  setIsSubmit(!isSubmit);
                }}
                amount={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={totalAmount.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                description={
                  <>
                    You are about to {action}{' '}
                    <span className="fw-bolder">
                      {action === 'decline'
                        ? selectedRowInformation?.['pending']?.count
                        : action === 'pay'
                        ? selectedRowInformation?.['approved']?.count
                        : '0'}{' '}
                      requests.
                    </span>
                  </>
                }
                fee={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={fees.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                icon={<BankIcon width="24" height="24" />}
                title={`Please confirm your ${action === 'pay' ? 'payment' : 'decline'}.`}
                total={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={(totalAmount + fees).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                loading={isPaying}
                addNote={action === 'decline'}
              />
            </Modal>
          )}
        </>
      )}

      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => {
            setIsSchedule(false);
          }}
          loading={loadingReview}
          setSchedule={handleConfirm}
          // handleCreate={onHandleSubmit}
        />
      </Modal>

      <ModalComponent active={!!declineRequest} scroll={false}>
        <ModalContainerWrap
          isWhite
          modalName="Decline request"
          onClose={() => {
            setDeclineRequest(false);
          }}
        >
          <DeclineRequest
            onClose={() => {
              setDeclineRequest(false);
            }}
            selectRequest={selectedOption}
            userCode={user?.user?.code}
            isLoaded={isLoaded}
            kebabAction
            hideTitle
          />
        </ModalContainerWrap>
      </ModalComponent>

      {showSendFunds && (
        <BujetiPayModal recipientCode={recipientCode} setOpenModal={setShowSendFunds} />
      )}
    </>
  );
};

export default memo(TransactionTable);
