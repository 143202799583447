import {
  AudioOutlined,
  BankOutlined,
  DownCircleOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  UpCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import BankIcon3 from 'assets/icons/bank-alt.svg';
import { default as defaultBank } from 'assets/icons/bank.svg';
import BanksIcons from 'assets/icons/banks';
import AmericanExpressCard from 'assets/images/card/americanExpressCard.png';
import MasterSvg from 'assets/images/card/mastercard.svg';
import MasterCard from 'assets/images/card/mastercardBlack.svg';
import VerveCard from 'assets/images/card/verve.png';
import VerveSvg from 'assets/images/card/verve.svg';
import VisaSvg from 'assets/images/card/visa.svg';
import UnknownCard from 'assets/images/card/unknown.svg';
import VisaCard from 'assets/images/card/visaBlack.png';
import FileUpload from 'components/Table/FileUpload';
import ImgCard from 'components/UI/ImgCard';
import { differenceInDays, isYesterday } from 'date-fns';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import dayjs from 'dayjs';
import numeral from 'numeral';
import FileAttachmentComponent from 'pages/Policy/components/FileAttachmentComponent';
import CurrencyFormat from 'react-currency-format';
import { addSinglePolicyDocument } from 'redux/actions/PoliciesAction';
import uniqolor from 'uniqolor';
import { CreditCard02, PdfIcon, PlusIcon, WarningIcon } from '../assets/icons/index';
import NameBadge from '../components/UI/NameBadge';
import BadgeType from '../components/UI/Table/BadgeType';
import { Banks } from './banks';
import { numFormatter } from './utility';
import { updateBill, updateScheduledBill } from 'redux/actions/BillAction';
import { QUERY_TYPE_MAP } from 'utils/utility';
import { icon } from 'components/Spiner';

import {
  HourGlass,
  Loading01,
  ClockStopWatch,
  Xcircle,
  AlertCirclePlain,
  CheckIcon,
  CalendarIcon,
  File04,
  PauseIcon,
  Lock03,
  ShieldTick,
  Shield01,
} from 'assets/icons';

export const cellType = {
  CHECKBOX: 'checkbox',
  STRING: 'string',
  DOUBLE_STRING: 'double-string',
  MODIFY: 'modify',
  STRING_BOLD: 'string-bold',
  STRING_BADGE: 'string-badge',
  BADGE: 'badge',
  SELECTION: 'selection',
  DOTTED_BADGE: 'dotted-badge',
  EXPANDER: 'expander',
  PROGRESS_BAR: 'progress-bar',
  LINE_STATUS: 'line-status',
  AVATAR: 'avatar',
  AVATAR_LIST: 'avatar-list',
  DOUBLE_BADGE: 'double-badge',
  FILE: 'file',
};

const REQUEST_TYPES = {
  Payments: 'Payment',
  Reimbursements: 'Out of Pocket',
  Batch: 'Batch',
  Invoice: 'Invoice',
  Bill: 'Bill',
};

//NB: please leave uncategorized as the last item, should this grow.
export const categoryBadgeMap = [
  {
    styles: { bg: '#ECFDF3', border: '#A6F4C5', color: '#027A48' },
    statuses: ['sales', 'A', 'F'],
  },
  {
    styles: { bg: '#FEF3F2', border: '#FECDCA', color: '#B42318' },
    statuses: ['refund', 'D', 'E', 'B'],
  },
  {
    styles: { bg: '#FFFAEB', border: '#FEDF89', color: '#B54708' },
    statuses: ['software', 'G', 'H', 'I'],
  },
  {
    styles: { bg: '#F8F9FC', border: '#D5D9EB', color: '#363F72' },
    statuses: ['tax', 'taxes', 'J', 'K', 'L'],
  },
  {
    styles: { bg: '#F0F9FF', border: '#B9E6FE', color: '#026AA2' },
    statuses: ['logistics', 'M', 'N', 'O'],
  },
  {
    styles: { bg: '#FDF2FA', border: '#FCCEEE', color: '#C11574' },
    statuses: ['marketing', 'S', 'Q', 'R'],
  },
  {
    styles: { bg: '#EEF4FF', border: '#C7D7FE', color: '#3538CD' },
    statuses: ['insurance', 'C', 'T', 'U'],
  },
  {
    styles: { bg: '#F4F3FF', border: '#D9D6FE', color: '#5925DC' },
    statuses: ['entertainment', 'V', 'W', 'X'],
  },
  {
    styles: { bg: '#EFF8FF', border: '#B2DDFF', color: '#175CD3' },
    statuses: ['payroll', 'Y', 'Z', 'P'],
  },
  {
    styles: { bg: '#FEF6EE', border: '#F9DBAF', color: '#B93815' },
    statuses: ['supplies'],
  },
  {
    styles: { bg: '#FAFAF9', border: '#D8D8D6', color: '#44403C' },
    statuses: ['uncategorized', 'add category'],
  },
];

export function asciiToHex(string) {
  if (!string) return;

  const match = categoryBadgeMap.find(({ statuses }) =>
    statuses.some((status) => status.toLowerCase() === string.toLowerCase()),
  );

  return match?.styles.color || '';
}

export const validatePassword = (password) => {
  const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]*)(?=.*[!@#$%^&*_-])(?=.{8,})',
  );
  return passwordRegex.test(password);
};

export const removeSpaces = (str) => {
  return String(str)?.replace(/\s/g, '');
};

export const convertNaNToZero = (value) => {
  return isNaN(value) ? 0 : value;
};

export const checkForManageWord = (str) => {
  const regex = /\manage\b/g;
  return regex.test(str);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getCurrency = (name) => {
  switch (name) {
    case 'EUR':
      return '€';
    case 'ZAR':
      return 'R';
    case 'USD':
      return '$';
    case 'RWF':
      return 'RF';
    case 'XOF':
      return 'CFA';
    case 'NGN':
      return '₦';
    case 'GHS':
      return 'GH¢';
    case 'KES':
      return 'K';
  }
};

/**
 * Get sum of the values of the passed key in the passed array
 * @param array
 * @param keyName
 * @returns {number}
 */
export const getTotalAmount = (array = [], keyName) => {
  if (!(Array.isArray(array) && array.length)) return 0;
  return array.reduce((total, data) => {
    return +total + (+data[keyName] || 0);
  }, 0);
};

export const bankLabelAliaser = (item, size = 32) => {
  const bankCode = (value) => {
    return value?.length === 3 ? `000${value}` : value;
  };

  const logo =
    Banks.find((item) => bankCode(item.value) === item?.bankCode)?.icon ??
    BanksIcons[item?.bankName?.toLowerCase()] ??
    item?.logo;

  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div className="text-sm d-flex align-items-center">
        <img
          src={logo ?? defaultBank}
          alt="bank-log"
          loading="lazy"
          style={{
            padding: 3,
            width: size,
            height: size,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 8,
            borderColor: '#E7E5E4',
            color: '#44403C',
            marginRight: 10,
          }}
        />
        {item.name}
      </div>
      <span className="text-sm text-start px-2" style={{ marginLeft: 'auto' }}>
        <CurrencyFormat
          prefix={getCurrency(item?.currency)}
          value={convertNaNToZero(item?.balance / 100)}
          displayType="text"
          thousandSeparator={true}
        />
      </span>
    </div>
  );
};

export const FormattedCurrency = ({ style, className, value, prefix }) => {
  const formattedValue = convertNaNToZero((value / 100).toFixed(2));

  return (
    <CurrencyFormat
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      prefix={prefix}
      decimalScale={2}
      fixedDecimalScale={true}
      className={className}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span style={style} className="m-0 p-0">
            {integerPart}
            <span style={{ color: '#A9A29D' }} className="m-0 p-0">
              .{decimalPart}
            </span>
          </span>
        );
      }}
    />
  );
};

export const getAvailableBalance = (
  arrayItems,
  imgSize = 32,
  showExternalAccount = true,
  topUp = false,
) => {
  if (!Array.isArray(arrayItems)) return [];

  return arrayItems?.map((item) => {
    if (
      ['linked', 'direct-debit'].includes(item?.accountType) &&
      (item?.mandateStatus !== 'granted' ||
        !item?.isReadyForDebit ||
        !showExternalAccount)
    ) {
      return null;
    }
    // This is to cater to occurrences of said function where 'budget' || 'balance' strings were used
    if (typeof imgSize === 'string') {
      imgSize = 32;
    }

    const value = item?.isLinked && !topUp ? item?.bankAccount : item?.code;

    return {
      type: item?.type,
      value,
      isDirectDebit:
        ['linked', 'direct-debit'].includes(item?.accountType) &&
        item?.mandateStatus === 'granted',
      name: item?.name,
      amount: item?.balance / 100,
      accountName: item?.accountName,
      bank: item?.bankName,
      bankAccount: item?.bankAccount,
      subaccount: item?.subaccount,
      accountNumber: item?.number,
      currency: item?.currency,
      linkedOn: item?.linkedOn,
      label: bankLabelAliaser(item, imgSize),
      isFunded: item?.isFunded,
      overSpent: item?.overSpent,
    };
  });
};

export const groupSourceOptions = (filteredOption) => {
  const balance = filteredOption
    ?.filter((item) => item?.type === 'balance')
    .sort(({ amount: firstBalance }, { amount: secondBalance }) => {
      return secondBalance >= firstBalance ? 1 : -1;
    });
  const budget = filteredOption
    ?.filter((item) => item?.type === 'budget')
    .sort(({ amount: firstBalance }, { amount: secondBalance }) => {
      return secondBalance >= firstBalance ? 1 : -1;
    });

  const groupedSource = [
    { label: 'Accounts', options: balance?.length ? balance : [] },
    { label: 'Budgets', options: budget?.length ? budget : [] },
  ];

  return groupedSource;
};

export const getCard = (title, type = 'png') => {
  switch (title?.toLowerCase()) {
    case 'mastercard':
      return <img src={MasterSvg} alt="MasterCard" className="w-100" />;
    case 'visa':
      return (
        <img src={type === 'png' ? VisaCard : VisaSvg} alt="VisaCard" className="w-100" />
      );
    case 'americanexpress':
      return <img src={AmericanExpressCard} alt="AmericanExpress" className="w-100" />;
    case 'verve':
      return (
        <img src={type === 'png' ? VerveCard : VerveSvg} alt="Verve" className="w-100" />
      );
    case 'unknown':
      return <img src={UnknownCard} alt="unknown" className="w-100" />;
    default:
      return (
        <img src={type === 'png' ? VerveCard : VerveSvg} alt="Verve" className="w-100" />
      );
  }
};

export const getDocmentTypes = (type) => {
  switch (type) {
    case 'ip':
      return 'International Passport';
    case 'dl':
      return 'Drivers license';
    case 'vi':
      return 'Voters Card';
    case 'rcNumber':
      return 'RC Number';
    case 'nin':
      return 'nin';
    case 'C_of_T':
      return 'Certificate of Incorporated Trustees';
    case 'Scum_L_C':
      return 'SCUML Certificate';
    case 'cacITForm1':
      return 'CAC IT Form 1';
    case 'bvn':
      return 'bvn';
    case 'cac':
      return 'CAC Document';
    case 'bnNumber':
      return 'BN Number';
    case 'utility-Bill':
      return 'Utility Bill';
    case 's_Doc':
      return 'CAC 2 / Share Allotment';
    case 'incorp_C':
      return 'Certificate of Incoporation';
  }
};

export const statusIconAliaser = (status) => {
  if (!status) return null;

  const strokeMap = {
    '#4F7A21': ['approved'],
    '#039855': ['paid', 'partially paid'],
    '#4CA30D': ['active', 'success', 'processed', 'completed'],
  };

  const getStrokeColor = (status) => {
    const color = Object.keys(strokeMap).find((key) =>
      strokeMap[key].includes(status.toLowerCase()),
    );
    return color || '#000000';
  };

  switch (status?.toLowerCase()) {
    case 'pending':
      return <HourGlass />;

    case 'verified':
      return <ShieldTick />;

    case 'unverified':
      return <Shield01 />;

    case 'pause':
    case 'paused':
      return <PauseIcon />;

    case 'blocked':
      return <Lock03 />;

    case 'verifying':
    case 'in review':
    case 'processing':
      return <Loading01 stroke={status === 'in review' ? '#5C5C5A' : null} />;

    case 'overdue':
      return <ClockStopWatch />;

    case 'declined':
    case 'cancelled':
    case 'disabled':
      return <Xcircle />;

    case 'scheduled':
      return <CalendarIcon width="12" height="12" stroke="#0E9384" />;

    case 'failed':
    case 'expired':
    case 'rejected':
      return <AlertCirclePlain />;

    case 'draft':
      return <File04 />;

    case 'paid':
    case 'active':
    case 'approved':
    case 'success':
    case 'processed':
    case 'completed':
    case 'partially paid':
      return <CheckIcon stroke={getStrokeColor(status)} width="12" height="12" />;

    default:
      return null;
  }
};

const pattern = /(\d+)(?:[ ,.]?\d{3})*(?:[.,]\d{2})?/g;
/**
 * Get amount from any amount from any format(EU/US/EN/FR)
 * @param {*} data
 * @returns
 */
export const getNumerical = (data) => {
  if (!data || !data.length) return 0;
  const match = data.match(pattern);
  if (match) {
    // Remove all non-digit characters from the matched number
    const cleanedNumber = match[0].replace(/[^\d]/g, '');

    // Check if there's a decimal part
    if (/\.\d{2}$|,\d{2}$/.test(match[0])) {
      // Return as-is for numbers with a decimal part
      return parseInt(cleanedNumber, 10);
    }
    // Multiply by 100 if no decimal part
    return parseInt(cleanedNumber, 10) * 100;
  }
  return null;
};

export const getColor = (title = '') => {
  if (!title) return '';
  switch (title?.toLowerCase()) {
    case 'success':
    case 'processed':
    case 'completed':
    case 'active':
      return '#4CA30D';

    case 'paid':
    case 'partially paid':
      return '#099250';

    case 'approved':
      return '#4F7A21';

    case 'expired':
    case 'rejected':
    case 'declined':
    case 'cancelled':
    case 'disabled':
    case 'failed':
    case 'blocked':
      return '#D92D20';

    case 'overdue':
      return '#E04F16';

    case 'pause':
    case 'paused':
    case 'pending':
      return '#CA8504';

    case 'physical':
    case 'member':
    case 'batch':
      return '#363F72';

    case 'approved':
      return '#F0ECF9';

    case 'virtual':
      return '#ECF3F9';

    case 'manager':
      return '#3538CD';

    case 'everyone':
      return '#F3F4F6';

    case 'in review':
    case 'unverified':
      return '#5C5C5A';

    case 'processing':
    case 'verifying':
      return '#DC6803';

    case 'archived':
      return '#885A1A';

    case 'scheduled':
      return '#0E9384';

    case 'inactive':
    case 'draft':
    case 'invited':
      return '#7F7F7D';

    case 'freeze':
      return '#FFFACE';

    case 'employee':
      return '#5925DC';

    case 'bill':
      return '#B93815';

    case 'deleted':
      return '#ffcece';

    case 'admin':
      return '#175CD3';

    case 'out of pocket':
      return '#363F72';

    case 'invoice':
    case 'imported':
      return '#026AA2';

    case 'flash':
      return '#F2F4F1';

    case 'payment':
      return '#7FB705';

    case 'debit':
      return '#000000';

    case 'credit':
    case 'verified':
    case 'withinrange':
      return '#039855';

    case 'overspent':
      return '#D92D20';

    case 'out-of-policy':
      return '#B54708';
  }
};

export const getSign = (title = '') => {
  switch (title?.toLocaleLowerCase()) {
    case 'debit':
      return `-`;
    case 'credit':
      return `+`;
  }
};

export const getMethod = (title = '') => {
  switch (title?.toLocaleLowerCase()) {
    case 'withdrawal':
      return 1;
    case 'top up':
      return 2;
    case 'closure':
      return 3;
  }
};

export const getInternationalFormat = (countryCode, localFormat) => {
  if (!countryCode || !localFormat) return '';
  let localFormatWithoutSpace = localFormat.replace(/\W+/g, '').toString();
  let newLocalFormat =
    countryCode === 234 && localFormatWithoutSpace.length === 11
      ? localFormatWithoutSpace.substring(1)
      : localFormat;

  return '+' + (countryCode + newLocalFormat).replace(/\s/g, '');
};

export const validateNigeriaNumber = (internationalFormat) => {
  if (internationalFormat) {
    const countryCode = internationalFormat.substring(0, 4);
    const phone = internationalFormat.substring(4);
    const phoneLength =
      phone.charAt(0) === '0' ? phone.length === 11 : phone.length === 10;
    if (countryCode === '+234') return !!phoneLength;
    return true;
  }
};

export const currencySign = ['NGN'];
export const cardCurrencySign = ['NGN', 'USD'];

export function capitalizeFirstLetter(string) {
  return string && String(string)?.charAt(0)?.toUpperCase() + String(string)?.slice(1);
}

export const truncateText = (text, start, end) => {
  return text?.length > end ? `${text?.substr(start, end)}...` : text;
};

/**
 * Get percentage of base
 * @param base
 * @param value
 * @returns {number} the percentage of base that value represents
 */
export function getPercentage(base, value) {
  return Math.round((value * 100) / base);
}

/**
 *
 * @param {*} base old value
 * @param {*} value new value
 * @returns
 */
export function getVariation(base, value) {
  const difference = value - base;
  if (base === 0) return 100;
  const variation = getPercentage(base, difference) || 0;
  return Math.abs(variation);
}

/**
 * get signed variation to know if an amount increased or decreased over time
 * @param {*} base old value
 * @param {*} value new value
 * @returns
 */
export function getSignedVariation(base, value) {
  const difference = Number(value) - Number(base);
  if (Number(base) === 0) return '+100';
  const variation = getPercentage(Number(base), difference) || 0;
  return (difference >= 0 ? '+' : '') + variation;
}

export const percentageValue = (base, value) => {
  return getPercentage(base, value) > 100 ? 100 : getPercentage(base, value);
};

export function getTopSpendPercentage(value) {
  return Math.round(value * 100);
}

export function buildChartDataForExpenseHead(data, type) {
  switch (type) {
    case 'categories':
      return data.map(
        ({ category, transactionCount, transactionVolume, currency = 'NGN' }) => ({
          name: category?.name,
          currency,
          value: Number(transactionVolume) / 100,
          count: transactionCount,
        }),
      );
    case 'vendors':
      return data.map(
        ({ vendor, transactionCount, transactionVolume, currency = 'NGN' }) => ({
          name: vendor?.name,
          currency,
          value: Number(transactionVolume) / 100,
          count: transactionCount,
        }),
      );
    default:
      return [];
  }
}

export function buildChartDataFromSpenders(spenders) {
  return spenders.map(({ amount, firstName, lastName }) => ({
    name: `${firstName} ${lastName}`,
    amount: amount / 100,
  }));
}

export function buildChartDataFromTransaction(transactions) {
  return Object.entries(transactions).map(
    ([key, { amount, spent, name, created_at }]) => ({
      name: name || created_at || key,
      spent: spent || amount / 100 || 0,
    }),
  );
}

export function buildCategoryChartFromTransaction(transactions) {
  return transactions
    ? Object.values(transactions).map(({ year, month, totalAmount, weekday }) => {
        const yearToString = year?.toString();
        return {
          name:
            weekday ??
            `${month} ${yearToString.substring(
              yearToString.length - 2,
              yearToString.length,
            )}`,
          spent: totalAmount / 100 || 0,
        };
      })
    : [];
}

export function getPaidTo(transaction) {
  if (transaction === null) return '-';
  const { recipient, description } = transaction ?? {};
  if (recipient) return recipient.name ?? `${recipient.firstName} ${recipient.lastName}`;
  return description;
}

export const getInitials = (firstName, lastName, name) => {
  if (firstName) {
    return `${firstName?.charAt(0)}`.toUpperCase();
  }
  const firstNonEmptyString = name?.split(' ').find((element) => !!element) || '-';
  return `${firstNonEmptyString.charAt(0)}`.toUpperCase();
};

export function buildExportTransactionData(data) {
  return data.map((body) => [
    getPaidTo(body),
    `${body.currency}${numeral(body.amount / 100).format('0,0')}`,
    body.payer ? `${body.payer.firstName} ${body.payer.lastName}` : '-',
    body.category?.name || 'Uncategorized',
    body.card
      ? `${toTitle(body?.card?.type)}••${body.card.last_4}`
      : body.bank_account
      ? 'Transfer'
      : '-',
    body.budget ? body.budget.name : 'Main Balance',
    body.description ? body?.description || body.narration : '-',
    getFormattedDate(body.created_at),
    body.status,
  ]);
}

export function buildExportVendorsData(data) {
  return data?.map((body) => [
    body?.name,
    body?.email,
    body?.phoneNumber?.localFormat,
    body?.description,
    body?.website,
    body?.address?.country,
    body?.address?.state,
    body?.address?.city,
    body?.address?.street,
    body?.tin,
    body?.taxWithHolding,
    body?.bankAccounts[0]?.bankName,
    body?.bankAccounts[0]?.bankCode,
    body?.bankAccounts[0]?.number,
    body?.categories?.length
      ? body?.categories?.map((item) => item?.name).join(', ')
      : '',
    body?.status,
    getFormattedDate(body?.created_at),
  ]);
}

export function buildExportTransactionXero(data) {
  return data.map((body) => [
    format(new Date(body.created_at), 'd/MM/yyyy'),
    body.description ? body?.description || body.narration : '-',
    `-${numeral(body.amount / 100).format('0,0')}`,
    getPaidTo(body),
    (body.category?.name || 'Uncategorized').toLowerCase(),
  ]);
}

export function buildExportTransactionQuickBooks(data) {
  return data.map((body) => [
    format(new Date(body.created_at), 'd/MM/yyyy'),
    body.description ? body?.description || body.narration : '-',
    `-${numeral(body.amount / 100).format('0,0')}`,
  ]);
}

export function accountStatementData(data) {
  if (data) {
    return data.map((body) => [
      body.code,
      body.account,
      body.type,
      `${body.currency}${body.amount}`,
      `${body.currency}${body.balance}`,
      body.sourceName,
      body.sourceAccount,
      body.beneficiaryName,
      body.beneficiaryAccount,
      getFormattedDate(body.created_at),
      body.description || body.narration || '-',
    ]);
  } else {
    return [];
  }
}

export function buildTeamsTableData(teams = []) {
  return teams.map((team) => {
    const { budgets = [] } = team;
    const newData = {
      ...team,
      teamsData: team,
      role: {
        value: team.role.toLowerCase() === 'manager' ? 'Manager' : 'Member',
        color: 'transparent',
      },
      name: `${team.user.firstName} ${team.user.lastName}`,
      emailAddress: team.user.email,
      assignedBudget: {
        type: 'assignedBudget',
        code: null,
        value: '(Add to a budget)',
        number: budgets.length > 1 ? `+${budgets.length - 1}` : null,
        icon: <PlusCircleOutlined />,
      },
      createdDate: getFormattedDate(team.created_at),
      status: { value: team.status.toLowerCase(), color: getColor(team.status) },
      modify: true,
    };

    if (budgets && budgets.length) {
      newData['assignedBudget'] = {
        type: 'assignedBudget',
        link: `/expenses/budgets/${budgets[0].code}/overview`,
        code: budgets[0].code,
        value: budgets[0].name,
        number: budgets.length > 1 ? `+${budgets.length - 1}` : null,
        icon: <UpCircleOutlined />,
        downIcon: <DownCircleOutlined />,
        numberList: budgets.map((d) => {
          return { ...d, link: `/expenses/budgets/${d.code}/overview` };
        }),
      };
    }
    return newData;
  });
}

export function buildBatchPaymentTableData(batchs = []) {
  return batchs.map((batch, index) => {
    const newData = {
      id: index,
      batchData: batch,
      recipientName: batch['Recipient'],
      accountNumber: batch['Account Number'],
      bankCode: batch['Bank Code'],
      bankName: Banks.find((item) => item.value === batch['Bank Code'])?.label,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(batch?.Source?.currency)}
          value={batch.Amount}
          displayType="text"
          thousandSeparator={true}
        />
      ),
      currency: batch?.Source?.currency,
      bank_account: {
        bankName: Banks.find((item) => item.value === batch['Bank Code'])?.label,
        bankCode: String(batch['Bank Code']),
        accountName: batch['Recipient'],
        number: batch['Account Number'],
        currency: batch.currency,
      },
      error: batch.error,
      unformatedAmount: batch.Amount,
      category: {
        value: batch.Category?.label,
        color: asciiToHex(batch.Category?.label),
        code: batch.Category?.value,
      },
      source: {
        value: batch?.Source?.name,
        color: asciiToHex(batch?.Source?.name) /*'#FFFACE'*/,
      },
      sourceCode: batch?.Source?.value,
      description: batch.Description,
      modify: true,
    };
    return newData;
  });
}

export function buildVendorsTableData(vendors = []) {
  return vendors.map((vendor) => {
    const categories = vendor.categories || [];
    const contact =
      vendor.email ||
      (vendor.address && `${vendor.address.street}, ${vendor.address.city}`) ||
      'Add contact';
    const description = vendor.description ? vendor.description : 'Add description';
    const newData = {
      ...vendor,
      vendorData: vendor,
      street: vendor?.address?.street,
      city: vendor?.address?.city,
      state: vendor?.address?.stateOrProvince,
      nameAndAddressOrEmail: {
        type: 'nameAndType',
        value: vendor?.name || '-',
        subValue: description,
        img: <ImgCard gap={false} initials={getInitials(null, null, vendor?.name)} />,
      },
      contact,
      categories: {
        type: 'categories',
        code: categories.length && categories[0].code,
        value: (categories.length && categories[0].name) || 'Add category',
        number: categories.length > 1 ? `+${categories.length - 1}` : null,
        icon: null,
        color: asciiToHex(categories.length && categories[0].name),
      },
      checkBoxDisabled: ['delete'].includes(vendor.status),
      createdDate: getFormattedDate(vendor.created_at),
      status: { value: vendor.status, color: getColor(vendor.status), isStatus: true },
      modify: !!['active', 'pause', 'invited', 'blocked'].includes(vendor.status),
    };

    return newData;
  });
}

export function buildPolicyTableData(policies = [], hasPermission) {
  return policies.map((policy) => {
    const lastModifiedBy = policy?.lastModifiedBy
      ? `${policy.lastModifiedBy?.firstName} ${policy.lastModifiedBy?.lastName ?? ''}`
      : null;

    const newData = {
      ...policy,
      policyData: policy,
      nameAndType: policy?.name,
      file: policy?.documents?.length ? (
        <>
          {hasPermission ? (
            <FileAttachmentComponent
              documents={policy?.documents?.map((item) => item.asset)}
            />
          ) : (
            '-'
          )}
        </>
      ) : (
        <>
          {['active', 'inactive'].includes(policy?.status) ? (
            <FileUpload
              showUploadedFiles={true}
              acceptedFile={{
                'application/pdf': ['.pdf'],
              }}
              callbackAction={addSinglePolicyDocument}
              getDocuments={'getPolicyDocuments'}
              addDocument={'addSinglePolicyDocument'}
              callbackPayload={{ policyCode: policy?.code }}
            />
          ) : (
            '-'
          )}
        </>
      ),
      description: policy?.description ?? '-',
      lastModifiedBy: lastModifiedBy
        ? {
            value: <NameBadge name={lastModifiedBy} />,
            img: (
              <ImgCard gap={false} initials={getInitials(null, null, lastModifiedBy)} />
            ),
          }
        : '-',
      status: { value: policy?.status, color: getColor(policy?.status) },
      modify: true,
    };

    return newData;
  });
}

export function buildCategorizationTableData(categorizations = []) {
  return categorizations.map((categorization) => {
    const newData = {
      categorizationData: categorization,
      code: categorization?.code,
      name: categorization?.name,
      description: categorization?.description ?? '-',
      date: getFormattedDate(categorization?.created_at),
      status: { value: categorization?.status, color: getColor(categorization?.status) },
      modify: true,
    };

    return newData;
  });
}

export function buildPendingApprovalTable(approvals = []) {
  return approvals?.map((approval, index) => {
    const type = approval?.transactionType?.name?.toLowerCase();

    const typeMapping = {
      transactions: 'transaction',
      reimbursements: 'reimbursement',
      payments: 'transaction',
      batch: 'batchTransactions',
      bill: 'bill',
    };

    const body = typeMapping[type] ?? type;
    const batchTransactions = body === 'batchTransactions';

    const sortDescription = () => {
      if (batchTransactions) {
        return approval?.batchTransactions?.name;
      }

      if (type === QUERY_TYPE_MAP.BILL) {
        return `Bill from ${approval?.bill?.vendor?.name || 'vendor'}`;
      }

      if (type === QUERY_TYPE_MAP.INVOICE) {
        return (
          approval[body]?.description ||
          approval[body]?.narration ||
          `Invoice to ${approval?.invoice?.customer?.name || 'customer'}`
        );
      }

      return approval[body]?.description || approval[body]?.narration || '-';
    };

    let singleTx = {};
    const Batchbudget = approval?.batchTransactions?.transactions[0]?.budget;
    const { receipts } = approval[body] || {};
    const { transactionType } = approval;
    const isReimbursement = transactionType?.name === 'Reimbursements';
    const reimbursment = approval?.reimbursement;
    const payer = batchTransactions
      ? `${approval?.batchTransactions?.payer?.firstName} ${approval?.batchTransactions?.payer?.lastName}`
      : `${approval[body]?.user?.firstName} ${approval[body]?.user?.lastName}`;

    singleTx = {
      ...approval,
      approvalData: approval,
      receipts,
      batchData: { ...approval?.batchTransactions, budget: Batchbudget },
      name: {
        value: <NameBadge name={payer} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, payer)} />,
      },
      // 'rule name': name,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(
            batchTransactions
              ? approval?.batchTransactions?.currency
              : approval[body]?.currency,
          )}
          value={
            batchTransactions
              ? approval?.batchTransactions?.amount / 100
              : approval[body]?.amount / 100
          }
          displayType="text"
          thousandSeparator={true}
        />
      ),
      description: sortDescription(),
      type: <BadgeType value={{ value: REQUEST_TYPES[transactionType?.name] }} isBadge />,
      budget: {
        type: 'budget',
        code: batchTransactions
          ? Batchbudget?.code
          : isReimbursement
          ? reimbursment?.budget?.code
          : approval[body]?.budget?.code,
        value: batchTransactions
          ? Batchbudget?.name
          : isReimbursement
          ? reimbursment?.budget?.name
          : approval[body]?.budget?.name || approval[body]?.balance?.name,
        icon: approval[body]?.budget ? <PieChartOutlined /> : <BankOutlined />,
        link: batchTransactions
          ? Batchbudget?.budget && !['deleted'].includes(Batchbudget?.status)
            ? `/expenses/budgets/${Batchbudget?.code}/overview`
            : null
          : isReimbursement
          ? reimbursment?.budget && !['deleted'].includes(reimbursment?.budget?.status)
            ? `/expenses/budgets/${reimbursment?.budget?.code}/overview`
            : null
          : approval[body]?.budget &&
            !['deleted'].includes(approval[body]?.budget?.status)
          ? `/expenses/budgets/${approval[body]?.budget?.code}/overview`
          : null,
      },
      date: (
        <NameBadge
          name={
            batchTransactions
              ? getFormattedDate(approval?.batchTransactions?.created_at)
              : approval?.created_at
              ? getFormattedDate(approval?.created_at)
              : 'Null'
          }
        />
      ),
      evidence: ICONS[receipts?.length && receipts[0]?.type] || '-',
      checkBoxDisabled: body === 'batchTransactions',
      modify: true,
      status: {
        value: approval?.status,
        color: getColor(approval?.status),
        isStatus: true,
      },
    };

    return {
      ...singleTx,
    };
  });
}

const ICONS = {
  pdf: <FilePdfOutlined />,
  jpg: <FileJpgOutlined />,
  jpeg: <FileJpgOutlined />,
  png: <FileImageOutlined />,
  xlsx: <FileExcelOutlined />,
  mp4: <VideoCameraOutlined />,
  mpeg: <AudioOutlined />,
  'text/csv': <FileExcelOutlined />,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': <FileExcelOutlined />,
  xls: <FileExcelOutlined />,
};

export function buildBudgetTableData(budgets = []) {
  return budgets.map((budget) => {
    const { name, isFunded, available, spent, amount } = budget;
    const availableToShow = isFunded ? available : Math.max(amount - spent, 0);

    return {
      budgetData: budget,
      name: {
        value: budget?.name,
        subValue: (
          <span>
            Available:{' '}
            <CurrencyFormat
              prefix={getCurrency(budget?.currency)}
              value={numFormatter(availableToShow / 100)}
              displayType="text"
              thousandSeparator={true}
              isNumericString
            />
          </span>
        ),
      },
      progress: {
        amount: budget?.amount,
        spent: budget?.spent,
        disbursed: budget?.disbursed,
        available: budget?.available,
        bufferAmount: budget?.bufferAmount,
        overspent: budget?.overspent,
        currency: budget?.currency,
      },
      spent: {
        sign: '',
        value: (
          <CurrencyFormat
            prefix={getCurrency(budget?.currency)}
            value={numFormatter(budget?.spent / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor(budget?.amount < budget?.spent ? 'overspent' : 'withinrange'),
      },
      total: budget?.amount ? (
        <CurrencyFormat
          prefix={getCurrency(budget?.currency)}
          value={numFormatter(budget?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ) : (
        '-'
      ),
      recurrence: {
        subValue: `Expires:
        ${
          budget?.schedule?.expiry_date
            ? getFormattedDate(budget.schedule.expiry_date)
            : 'Never expires'
        }`,
        value: budget?.schedule?.occurrence
          ? capitalizeFirstLetter(budget?.schedule?.occurrence)
          : 'Never',
        textColor: '#79716B',
      },
      status: budget?.status,
      code: budget?.code,
      modify: true,
    };
  });
}

export function buildBudgetLedgerTableData(transactions = []) {
  return transactions.map((ledger) => {
    const [{ type: fileType } = {}] = ledger?.transaction?.receipts ?? [];
    const name = ledger?.transaction?.payer
      ? `${ledger?.transaction?.payer?.firstName} ${ledger?.transaction?.payer?.lastName}`
      : `${ledger?.user?.firstName} ${ledger.user?.lastName}`;
    return {
      transactionData: ledger?.transaction || {},
      ledgerData: ledger,
      isTransaction: !(
        ledger?.type === 'debit' ||
        (ledger?.operation === 'withdrawal' && ledger?.type === 'credit')
      ),
      // selectTransaction?.ledgerData?.transaction?.method
      isReversal: ledger?.operation === 'withdrawal' && ledger?.type === 'credit',
      paidTo: {
        value: <NameBadge name={ledger?.paidTo} truncateLength={30} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, ledger?.paidTo)} />,
      },
      amount: {
        sign: '',
        value: (
          <CurrencyFormat
            prefix={getCurrency(ledger?.currency)}
            value={numFormatter(ledger?.amount / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor(ledger?.type),
      },

      by: {
        value: <NameBadge name={name} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },

      category: {
        value: ledger?.transaction?.category?.name || 'uncategorized',
        color: asciiToHex(ledger?.transaction?.category?.name),
      },
      paymentMethod: capitalizeFirstLetter(
        ledger?.transaction?.method ?? ledger?.operation,
      ),
      type: ledger?.card?.code ? 'card' : 'budget',
      bankAccount: ledger?.transaction?.bank_account,
      budget: {
        type: 'budget',
        code: ledger?.budget ? ledger?.budget?.code : null,
        value: ledger?.budget ? ledger?.budget?.name : 'Main Balance',
        icon:
          ledger?.budget && !['deleted'].includes(ledger?.budget?.status) ? (
            <PieChartOutlined />
          ) : (
            <BankOutlined />
          ),
        link:
          ledger?.budget && !['deleted'].includes(ledger?.budget?.status)
            ? `/expenses/budgets/${ledger?.budget?.code}/overview`
            : null,
      },
      ledgerDescription: ledger?.description,
      description: ledger?.description || ledger?.narration || '-',
      date: getFormattedDate(ledger?.created_at),
      evidence: ICONS[fileType] || '-',
      status: { value: ledger?.status, color: getColor(ledger?.status) },
      modify:
        ledger?.type === 'debit' ||
        (ledger?.operation === 'withdrawal' && ledger?.type === 'credit'),
    };
  });
}

export function buildSubAccountTransactionsTableData(transactions = []) {
  return transactions.map((transaction) => {
    const { receipts = [], transaction: relatedTransaction, paidBy } = transaction;
    const [{ type: fileType } = {}] = receipts;
    const payerName = relatedTransaction?.payer
      ? `${relatedTransaction?.payer?.firstName} ${
          relatedTransaction?.payer?.lastName ?? ''
        }`
      : paidBy
      ? `${paidBy?.firstName} ${paidBy?.lastName ?? ''}`
      : null;

    const recipientName = relatedTransaction?.recipient
      ? `${relatedTransaction?.recipient?.name}`
      : transaction?.description || transaction?.narration;

    return {
      transactionData: relatedTransaction || {},
      ledgerData: transaction,
      paidTo: {
        value: <NameBadge name={recipientName} truncateLength={30} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, recipientName)} />,
      },
      paidBy: {
        value: payerName ? <NameBadge name={payerName} truncateLength={30} /> : '-',
        img: payerName ? (
          <ImgCard gap={false} initials={getInitials(null, null, payerName)} />
        ) : null,
      },
      by: {
        value: payerName ? <NameBadge name={payerName} truncateLength={30} /> : '-',
        img: payerName ? (
          <ImgCard gap={false} initials={getInitials(null, null, payerName)} />
        ) : null,
      },
      description: transaction?.description || transaction?.narration || undefined,
      amount: {
        value: (
          <CurrencyFormat
            prefix={getCurrency(transaction?.currency)}
            value={numFormatter(Math.abs(transaction?.amount) / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor(transaction?.amount < 0 ? 'debit' : 'credit'),
      },
      category: {
        value: relatedTransaction?.category?.name || 'Uncategorized',
        color: asciiToHex(relatedTransaction?.category?.name),
      },

      type: {
        value: transaction?.amount < 0 ? 'Debit' : 'Credit',
        color: asciiToHex(transaction?.amount < 0 ? 'Debit' : 'Credit'),
      },
      paymentMethod: relatedTransaction
        ? {
            type: 'bank',
            code:
              relatedTransaction?.bank_account && relatedTransaction?.bank_account?.code,
          }
        : 'top up',
      bankAccount: relatedTransaction?.bank_account,
      balanceAfter: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter((transaction?.balanceAfter || 0) / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      budget: {
        type: 'budget',
        code: relatedTransaction?.budget ? relatedTransaction?.budget?.code : null,
        value: relatedTransaction?.budget
          ? relatedTransaction?.budget?.name
          : relatedTransaction?.balance?.name || 'Main Balance',
        icon:
          relatedTransaction?.budget &&
          !['deleted'].includes(relatedTransaction?.budget?.status) ? (
            <PieChartOutlined />
          ) : (
            <BankOutlined />
          ),
        link:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
            ? `/expenses/budgets/${transaction?.budget?.code}/overview`
            : null,
      },
      date: <NameBadge name={getFormattedDate(transaction?.when)} />,
      status: { value: transaction?.status, color: getColor(transaction?.status) },
      evidence: ICONS[fileType] || '-',
      modify: !!relatedTransaction,
    };
  });
}

export function buildSubAccountsTableData(subaccounts = []) {
  return subaccounts.map((subaccount) => {
    return {
      subaccountData: subaccount,
      name: <NameBadge name={subaccount.name} truncateLength={50} />,
      type: {
        value: subaccount?.balanceType,
        color: asciiToHex(subaccount?.balanceType),
      },
      status: { value: subaccount?.status, color: getColor(subaccount?.status) },
      balance: {
        value: (
          <CurrencyFormat
            prefix={getCurrency(subaccount?.currency)}
            value={numFormatter(Math.abs(subaccount?.balance) / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor('debit'),
      },
      // manager: <NameBadge name={subaccount.managers[0].name} truncateLength={50} />,
      date: <NameBadge name={getFormattedDate(subaccount?.created_at)} />,
    };
  });
}

export function buildPayinsTableData(transactions = [], isOverView = false) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions.length);
  return updatedData.map((transaction) => {
    const { receipts = [], transaction: relatedTransaction } = transaction;
    const [{ type: fileType } = {}] = receipts;
    const payerName = relatedTransaction?.payer
      ? `${relatedTransaction?.payer?.firstName} ${relatedTransaction?.payer?.lastName}`
      : transaction?.description || transaction?.narration;

    const recipientName = relatedTransaction?.recipient
      ? `${relatedTransaction?.recipient?.name}`
      : transaction?.description || transaction?.narration;

    return {
      transactionData: relatedTransaction,
      paidTo: {
        value: <NameBadge name={recipientName} truncateLength={30} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, recipientName)} />,
      },
      description: (
        <NameBadge
          name={transaction?.description || transaction?.narration || '-'}
          truncateLength={75}
        />
      ),
      amount: {
        value: (
          <CurrencyFormat
            prefix={getCurrency(transaction?.currency)}
            value={numFormatter(Math.abs(transaction?.amount) / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor(transaction?.amount < 0 ? 'debit' : 'credit'),
      },
      by: <NameBadge name={payerName} />,
      category: {
        value: relatedTransaction?.category?.name || 'Uncategorized',
        color: asciiToHex(relatedTransaction?.category?.name),
      },
      type: {
        value: transaction?.type || 'Credit',
        color: asciiToHex(transaction?.type),
      },
      paymentMethod: {
        type: 'bank',
        code: relatedTransaction?.bank_account && relatedTransaction?.bank_account?.code,
      },
      bankAccount: relatedTransaction?.bank_account,
      balanceBefore: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter((transaction?.balanceBefore || 0) / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      balanceAfter: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter((transaction?.balanceAfter || 0) / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      budget: {
        type: 'budget',
        code: relatedTransaction?.budget ? relatedTransaction?.budget?.code : null,
        value: relatedTransaction?.budget
          ? relatedTransaction?.budget?.name
          : relatedTransaction?.balance?.name || 'Main Balance',
        icon:
          relatedTransaction?.budget &&
          !['deleted'].includes(relatedTransaction?.budget?.status) ? (
            <PieChartOutlined />
          ) : (
            <BankOutlined />
          ),
        link:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
            ? `/expenses/budgets/${transaction?.budget?.code}/overview`
            : null,
      },
      date: <NameBadge name={getFormattedDate(transaction?.when)} />,
      status: { value: transaction?.status, color: getColor(transaction?.status) },
      evidence: ICONS[fileType] || '-',
    };
  });
}

export function buildTransactionTableData(transactions = [], isOverView = false) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions.length);
  return updatedData.map((transaction) => {
    const { receipts = [] } = transaction;
    const [{ type: fileType } = {}] = receipts;
    const payerName = `${transaction?.payer?.firstName ?? 'Unknown'} ${
      transaction?.payer?.lastName ?? ''
    }`;

    const status = !!transaction?.violations?.length
      ? 'Out-Of-Policy'
      : transaction?.status;

    return {
      transactionData: transaction,
      paidTo: {
        value: <NameBadge name={getPaidTo(transaction)?.substr(0, 30)} />,
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(
              transaction?.recipient?.firstName,
              transaction?.recipient?.lastName,
              transaction?.recipient?.name,
            )}
          />
        ),
      },

      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),

      by: {
        value: <NameBadge name={payerName} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, payerName)} />,
      },
      category: {
        value: transaction?.category?.name || 'uncategorized',
        color: asciiToHex(transaction?.category?.name),
      },
      paymentMethod: {
        ...(transaction?.card && {
          type: 'card',
          code: transaction?.card && transaction?.card?.code,
        }),
        ...(transaction?.bank_account && {
          type: 'bank',
          code: transaction?.bank_account && transaction?.bank_account?.code,
        }),
        value: transaction.card
          ? `${toTitle(transaction?.card?.type)}••${transaction?.card?.last_4}`
          : transaction?.bank_account
          ? 'Transfer'
          : 'Cash',
      },
      bankAccount: transaction?.bank_account,
      account:
        transaction?.balance?.name ??
        transaction?.budget?.name ??
        transaction?.card?.name,
      budget: {
        type: 'budget',
        code: transaction?.budget ? transaction?.budget?.code : null,
        value: transaction?.budget
          ? transaction?.budget?.name
          : transaction?.balance?.name || 'Main Balance',
        icon:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status) ? (
            <PieChartOutlined />
          ) : (
            <BankOutlined />
          ),
        link:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
            ? `/expenses/budgets/${transaction?.budget?.code}/overview`
            : null,
      },
      description: transaction?.description || transaction?.narration || '-',
      date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
      paidOn: transaction?.paidOn ? (
        <NameBadge name={getFormattedDate(transaction?.paidOn)} />
      ) : null,
      status: {
        value: status,
        color: getColor(status),
        icon: status === 'Out-Of-Policy' ? <WarningIcon className="me-1" /> : undefined,
        isStatus: true,
      },
      evidence: ICONS[fileType] || '-',
      modify: true,
    };
  });
}

export function buildSpendTransactionTableData(transactions) {
  return transactions.map((transaction) => {
    return {
      by: '-',
      to: '-',
      method: '-',
      code: transaction.code,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      date: <NameBadge name={getFormattedDate(transaction?.paidOn)} />,
      status: {
        value: transaction.status,
        color: getColor(transaction.status),
      },
    };
  });
}

export function buildProfileTransactionTableData(transactions = [], type) {
  if (!Array.isArray(transactions)) return [];
  if (type === 'spend') return buildSpendTransactionTableData(transactions);
  return transactions.map((transaction) => {
    const payerName = `${transaction?.payer?.firstName ?? 'Unknown'} ${
      transaction?.payer?.lastName ?? ''
    }`;
    const recipientName = `${transaction?.recipient?.name ?? 'Unknown'}`;
    const method = transaction?.balance?.name || transaction?.budget?.name || 'Cash';

    const bankCode = (value) => {
      return value?.length === 3 ? `000${value}` : value;
    };

    const logo =
      Banks.find((item) => bankCode(item.value) === transaction?.bank_account?.bankCode)
        ?.icon ??
      BanksIcons[transaction?.bank_account?.bankName?.toLowerCase()] ??
      transaction?.bank_account?.logo;

    return {
      transactionData: transaction,
      by: {
        value: <NameBadge name={payerName} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, payerName)} />,
      },
      to: {
        value: <NameBadge name={recipientName} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, recipientName)} />,
      },
      method: {
        type: transaction.card ? 'card' : null,
        code: transaction.card ? transaction?.card?.code : null,
        subValue: transaction.card
          ? `Expiry ${transaction?.card?.exp_month}/${transaction?.card?.exp_year}`
          : transaction?.bank_account?.number
          ? `••••${transaction.bank_account.number.substring(
              transaction.bank_account.number.length - 4,
              transaction.bank_account.number,
            )}`
          : null,
        value: transaction.card
          ? `${capitalizeFirstLetter(transaction?.card?.brand)} ${
              transaction?.card?.last_4 ?? ''
            }`
          : transaction.bank_account
          ? method
          : 'Sync',
        img: transaction.card ? (
          getCard(transaction?.card?.brand, 'svg')
        ) : transaction?.bank_account ? (
          <img
            src={logo ?? BankIcon3}
            alt="bank-log"
            style={{
              padding: 3,
              width: 35,
              height: 35,
              borderStyle: 'solid',
              borderWidth: 2,
              borderRadius: 8,
              borderColor: '#E7E5E4',
              color: '#44403C',
            }}
          />
        ) : null,
        textColor: '#79716B',
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
    };
  });
}

export function buildCustomerTransactionTableData(transactions = []) {
  return transactions.map((transaction) => {
    return {
      method: <NameBadge name={capitalizeFirstLetter(transaction?.method)} />,
      amount: (
        <CurrencyFormat
          style={{ color: '#79716B' }}
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
    };
  });
}

export function buildApproversTableData(approvers = []) {
  return approvers.map((approver) => {
    const name = `${approver?.firstName ?? 'Unknown'} ${approver?.lastName ?? ''}`;

    return {
      approverData: approver,
      name: {
        value: <NameBadge name={name} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },
      email: approver?.email ?? '',
      date: <NameBadge name={getFormattedDate(approver?.lastActivityTime)} />,
    };
  });
}

function getFileIcon(type) {
  return <PdfIcon></PdfIcon>;
}

export function buildReceiptsTableData(receipts = []) {
  return receipts.map(({ name, type, createdAt, code }) => {
    return {
      code,
      name: {
        value: <NameBadge name={name} />,
        img: getFileIcon(null, null, type),
      },
      date: <NameBadge name={getFormattedDate(createdAt)} />,
    };
  });
}

export function buildScheduledTransactionTableData(
  transactions = [],
  isOverView = false,
) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions.length);
  return updatedData.map((transaction) => {
    const { receipts = [] } = transaction;
    const [{ type: fileType } = {}] = receipts;
    const payerName = transaction?.payer
      ? `${transaction?.payer?.firstName} ${transaction?.payer?.lastName}`
      : '-';
    const recipient =
      transaction?.recipient?.name ||
      `${transaction?.recipient?.firstName} ${transaction?.recipient?.lastName}`;
    return {
      transactionData: transaction,
      paidTo: {
        value: <NameBadge name={recipient} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, recipient)} />,
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      by: {
        value: <NameBadge name={payerName} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, payerName)} />,
      },
      category: {
        value: transaction?.category?.name || 'uncategorized',
        color: asciiToHex(transaction?.category?.name),
      },
      paymentMethod: {
        ...(transaction?.card && {
          type: 'card',
          code: transaction?.card && transaction?.card?.code,
        }),
        ...(transaction?.bank_account && {
          type: 'bank',
          code: transaction?.bank_account && transaction?.bank_account?.code,
        }),
        value: transaction.card
          ? `${toTitle(transaction?.card?.type)}••${transaction?.card?.last_4}`
          : transaction?.bank_account
          ? 'Transfer'
          : 'Cash',
      },
      bankAccount: transaction?.bank_account,
      budget: {
        type: 'budget',
        code: transaction?.budget ? transaction?.budget?.code : null,
        value: transaction?.budget
          ? transaction?.budget?.name
          : transaction?.balance?.name || 'Main Balance',
        icon:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status) ? (
            <PieChartOutlined />
          ) : (
            <BankOutlined />
          ),
        link:
          transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
            ? `/expenses/budgets/${transaction?.budget?.code}/overview`
            : null,
      },
      start_date: <NameBadge name={getFormattedDate(transaction?.start_date)} />,
      next_transfer: (
        <NameBadge name={getFormattedDate(transaction?.schedule.nextDate)} />
      ),
      status: {
        value: transaction?.status,
        color: getColor(transaction?.status),
        isStatus: true,
      },
      evidence: ICONS[fileType] || '-',
      modify: true,
    };
  });
}

export function buildFailedTransactionTableData(transactions = [], isOverView = false) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions?.length);
  return updatedData.map((transaction) => ({
    transactionData: transaction,
    paidTo: <NameBadge value={getPaidTo(transaction).substr(0, 30)} />,
    amount: (
      <CurrencyFormat
        prefix={getCurrency(transaction?.currency)}
        value={numFormatter(transaction?.amount / 100)}
        displayType="text"
        thousandSeparator={true}
        isNumericString
      />
    ),
    by: {
      type: 'beneficiary',
      code: transaction?.payer ? transaction?.payer?.code : null,
      value: transaction?.payer
        ? `${transaction?.payer?.firstName} ${transaction?.payer?.lastName}`
        : null,
      // icon: transaction.payer ? <PieChartOutlined /> : null,
    },
    bankAccount: transaction?.bank_account,
    budget: {
      type: 'budget',
      code: transaction?.budget ? transaction?.budget?.code : null,
      value: transaction?.budget ? transaction?.budget?.name : '-',
      icon:
        transaction?.budget && !['deleted'].includes(transaction?.budget?.status) ? (
          <PieChartOutlined />
        ) : (
          <BankOutlined />
        ),
      link:
        transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
          ? `/expenses/budgets/${transaction?.budget?.code}/overview`
          : null,
    },
    date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
    status: { value: transaction?.status, color: getColor(transaction?.status) },
    evidence:
      ICONS[transaction?.receipts?.length && transaction?.receipts[0]?.type] || '-',
    modify: true,
  }));
}

export function buildBulkTransactionTableData(transactions = [], isOverView = false) {
  const updatedData = transactions?.slice(0, isOverView ? 5 : transactions?.length);
  return updatedData.map((transaction) => {
    const Batchbudget = transaction?.transactions[0]?.budget;
    const payerName = `${transaction?.payer?.firstName ?? ''} ${
      transaction?.payer?.lastName ?? ''
    }`;

    const category = [
      ...new Set(transaction?.transactions.map((item) => item?.category?.name) || []),
    ];

    const status = [
      ...new Set(transaction?.transactions.map((item) => item?.status) || []),
    ];

    const uniqueStatus = Object.values(
      transaction?.transactions.reduce((acc, item) => {
        acc[item?.status] = acc[item?.status] || {
          name: item?.status,
          count: null,
          type: 'badge',
        };
        acc[item?.status].count += 1;
        return acc;
      }, {}),
    );

    return {
      transactionData: transaction,
      batchName: transaction?.name,
      batchCode: transaction?.code,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={numFormatter(transaction?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),

      by: {
        value: <NameBadge name={payerName} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, payerName)} />,
      },

      paymentMethod: {
        value: 'Transfer',
      },
      bankAccount: transaction?.bank_account,
      category: {
        value: transaction?.transactions[0]?.category?.name || 'uncategorized',
        number: category.length > 1 ? <span>+{category.length - 1}</span> : 0,
        color: asciiToHex(transaction?.transactions[0]?.category?.name),
      },
      budget: {
        type: 'budget',
        code: Batchbudget ? Batchbudget?.code : null,
        value: Batchbudget ? Batchbudget?.name : '-',
        icon:
          Batchbudget && !['deleted'].includes(Batchbudget?.status) ? (
            <PieChartOutlined />
          ) : null,
        link:
          Batchbudget && !['deleted'].includes(Batchbudget?.status)
            ? `/expenses/budgets/${Batchbudget?.code}/overview`
            : null,
      },
      description: transaction?.description || transaction?.narration || '-',
      date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
      status: {
        value: transaction?.status,
        color: getColor(transaction?.status),
      },
      statusList: {
        type: 'status',
        link: null,
        code: null,
        value: transaction?.status,
        number: status.length > 1 ? <span>+{status.length - 1}</span> : null,
        icon: status.length > 1 ? <UpCircleOutlined /> : null,
        downIcon: <DownCircleOutlined />,
        numberList: uniqueStatus,
      },

      // notClickable: true,
      subRows: transaction?.transactions?.map((transaction) => ({
        transactionData: transaction,
        paidTo: getPaidTo(transaction),
        batchName: getPaidTo(transaction),
        hasSubrows: true,
        amount: (
          <CurrencyFormat
            prefix={getCurrency(transaction?.currency)}
            value={numFormatter(transaction?.amount / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),

        by: {
          value: <NameBadge name={payerName} />,
          img: <ImgCard gap={false} initials={getInitials(null, null, payerName)} />,
        },
        category: {
          value: transaction?.category?.name || 'uncategorized',
          color: asciiToHex(transaction?.category?.name),
        },
        paymentMethod: {
          ...(transaction?.card && {
            type: 'card',
            code: transaction?.card && transaction?.card?.code,
          }),
          ...(transaction?.bank_account && {
            type: 'bank',
            code: transaction?.bank_account && transaction?.bank_account.code,
          }),
          value: transaction?.card
            ? `${toTitle(transaction?.card?.type)}••${transaction?.card?.last_4}`
            : transaction?.bank_account
            ? 'Transfer'
            : '-',
          // icon: transaction.card ? <PieChartOutlined /> : null,
        },
        bankAccount: transaction?.bank_account,
        budget: {
          type: 'budget',
          code: transaction?.budget ? transaction?.budget?.code : null,
          value: transaction?.budget ? transaction?.budget?.name : '-',
          icon:
            transaction.budget && !['deleted'].includes(transaction?.budget?.status) ? (
              <PieChartOutlined />
            ) : null,
          link:
            transaction?.budget && !['deleted'].includes(transaction?.budget?.status)
              ? `/expenses/budgets/${transaction?.budget?.code}/overview`
              : null,
        },
        description: transaction?.description || transaction?.narration || '-',
        date: <NameBadge name={getFormattedDate(transaction?.created_at)} />,
        statusList: {
          value: transaction?.status,
          color: getColor(transaction?.status),
          isStatus: true,
        },
        modify: transaction?.status.toLowerCase() === 'approved' ? true : false,
      })),
      modify: true,
    };
  });
}

export function buildSingleTransactionTableData(transactions = []) {
  return transactions?.map((transaction) => {
    return {
      transactionData: transaction,
      code: transaction?.code,
      recipientName: transaction?.recipient?.name,
      accountNumber: transaction?.bank_account?.number,
      bankCode: transaction?.bank_account?.code,
      bankName: transaction?.bank_account?.bankName,
      balance: transaction?.balance?.name,
      paymentMethod: {
        ...(transaction?.card && {
          type: 'card',
          code: transaction?.card && transaction?.card?.code,
        }),
        ...(transaction?.bank_account && {
          type: 'bank',
          code: transaction?.bank_account && transaction?.bank_account.code,
        }),
        value: transaction?.card
          ? `${toTitle(transaction?.card?.type)}••${transaction?.card?.last_4}`
          : transaction?.bank_account
          ? 'Transfer'
          : '-',
        // icon: transaction.card ? <PieChartOutlined /> : null,
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(transaction?.currency)}
          value={transaction?.amount / 100}
          displayType="text"
          thousandSeparator={true}
        />
      ),
      currency: transaction?.currency,
      bank_account: {
        bankName: transaction?.bank_account?.bankName,
        bankCode: transaction?.bank_account?.code,
        accountName: transaction?.bank_account?.accountName,
        number: transaction?.bank_account?.number,
        currency: transaction?.currency,
      },
      unformatedAmount: transaction?.amount / 100,
      category: {
        value: transaction?.category?.name || 'uncategorized',
        color: asciiToHex(transaction?.category?.name || 'uncategorized'),
      },
      source: {
        value: transaction?.source?.name || '-',
        color: asciiToHex(transaction?.source?.name || '-') /*'#FFFACE'*/,
      },
      description: transaction?.description || transaction?.narration || '-',
      reason: transaction?.decline_reason,
      modify: true,
      checkBoxDisabled: ['success', 'declined', 'processing'].includes(
        transaction?.status,
      ),
      status: {
        value: transaction?.status,
        color: getColor(transaction?.status),
        isStatus: true,
      },
    };
  });
}
export function buildApprovalRulesTableData(approvals) {
  const data = approvals?.map((approval) => {
    const approvers = approval?.approved_by
      ?.map((approver) => {
        return approver?.approvers?.map((user) => {
          return {
            title: `${user?.user?.firstName} ${user?.user?.lastName}`,
            initials: `${user?.user?.firstName.charAt(0)}`,
            picture: null,
          };
        });
      })
      .flat(1);

    const newTriggers = approval?.conditions.map((condition) => {
      return condition?.trigger;
    });
    let triggers = '';
    if (newTriggers?.length == 2) {
      triggers = `${capitalizeFirstLetter(newTriggers[0])}, ${capitalizeFirstLetter(
        newTriggers[1],
      )}`;
    } else if (newTriggers?.length > 2) {
      triggers = `${capitalizeFirstLetter(newTriggers[0])}, ${capitalizeFirstLetter(
        newTriggers[1],
      )} +${newTriggers.length - 2}`;
    } else if (newTriggers?.length <= 1) {
      triggers = `${capitalizeFirstLetter(newTriggers[0])}`;
    }

    return {
      name: approval?.name,
      approvalLevel: approval?.approver_levels,
      approvers,
      triggers,
      status: {
        value: approval?.status,
        color: getColor(approval?.status),
        isStatus: true,
      },
      date: approval?.created_at ? getFormattedDate(approval?.created_at) : 'Null',
      modify: true,
      approvalData: { ...approval },
    };
  });

  return data;
}

export function getFormattedDate(date) {
  const jsDate = new Date(date);
  const formatTemplate = isToday(jsDate) ? 'hh:mm aa' : 'MMM d, yyyy';
  return date && isNaN(date) ? format(jsDate, formatTemplate) : null;
}

function getBeneficiaryAllocatedAmount(budgets, currency) {
  const amount = budgets.reduce((total, item) => total + item.amount / 100, 0);

  return (
    (amount && (
      <CurrencyFormat
        prefix={getCurrency(currency)}
        value={amount}
        displayType="text"
        thousandSeparator={true}
      />
    )) ||
    'Unlimited'
  );
}

export function buildBeneficiaryTableData(beneficiaries, budget = null) {
  return beneficiaries?.map((beneficiary) => {
    const {
      user: { manager },
    } = beneficiary;
    const managerName =
      (manager && `${manager.firstName} ${manager.lastName}`) || 'No one';
    return {
      ...beneficiary,
      beneficiaryData: beneficiary,
      role: [
        {
          value: beneficiary?.budgets[0]?.isBudgetOwner ? 'Owner' : 'Member',
          color: asciiToHex(beneficiary?.budgets[0]?.isBudgetOwner ? 'Owner' : 'Member'),
        },
      ],
      name: {
        type: 'name',
        code: beneficiary?.user?.code,
        value: `${beneficiary.user.firstName} ${beneficiary.user.lastName}`,
        component: (
          <NameBadge
            name={`${beneficiary.user.firstName} ${beneficiary.user.lastName}`}
          />
        ),
        subValue: beneficiary.user.email,
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(beneficiary.user.firstName, beneficiary.user.lastName)}
          />
        ),
      },
      amount: getBeneficiaryAllocatedAmount(beneficiary.budgets, budget?.currency),
      totalSpent: `${getCurrency(budget?.currency)}${beneficiary.spent}`,
      manager: { value: managerName, code: manager && manager.code },
      dateAdded: getFormattedDate(beneficiary?.created_at),
      status: { value: beneficiary.status, color: getColor(beneficiary.status) },
      modify: true,
    };
  });
}

export function buildSubAccountMembersTableData(members = []) {
  return members?.map((member) => {
    const jsDate = new Date(member?.updated_at);
    const formatTemplate = isToday(jsDate)
      ? "'Today,' hh:mm a"
      : isYesterday(jsDate)
      ? "'Yesterday,' hh:mm a"
      : "MMM dd, yyyy 'at' hh:mm a";
    const formattedDate =
      member?.updated_at && isNaN(member?.updated_at)
        ? format(jsDate, formatTemplate)
        : null;
    return {
      memberData: member,
      role: member?.designation,
      name: {
        value: `${member.user.firstName} ${member.user.lastName}`,
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(member.user.firstName, member.user.lastName, null)}
          />
        ),
      },
      dateAdded: formattedDate,
      modify: true,
    };
  });
}

export function buildCategoriesTableData(categories = []) {
  return categories?.map((category) => {
    const subRows =
      category?.children?.map((subcategory) => ({
        data: {
          code: subcategory?.code,
          name: subcategory?.name,
          description: subcategory?.description,
          parent: {
            code: category?.code,
            name: category?.name,
            limit: category?.limit,
            limitLeft: category?.limitLeft,
          },
        },
        code: subcategory?.code,
        type: 'subcategory',
        name: subcategory?.name,
        description: subcategory?.description ?? '-',
        isSubCategory: subcategory?.isSubCategory,
        hasSubrows: true,
        spent: subcategory?.spent ? (
          <CurrencyFormat
            prefix={getCurrency('NGN')}
            value={numFormatter(subcategory?.spent / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ) : (
          '-'
        ),
        available: subcategory?.available ? (
          <CurrencyFormat
            prefix={getCurrency('NGN')}
            value={numFormatter(subcategory?.available / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ) : (
          '-'
        ),
        limit: subcategory?.limit ? (
          <CurrencyFormat
            prefix={getCurrency('NGN')}
            value={numFormatter(subcategory?.limit / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ) : (
          '-'
        ),
        bufferAmount: subcategory?.bufferAmount,
        modify: true,
      })) || [];

    return {
      data: {
        code: category?.code,
        name: category?.name,
        description: category?.description,
        limit: category?.limit,
        limitLeft: category?.limitLeft,
      },
      code: category?.code,
      type: 'category',
      name: category?.name,
      description: category?.description ?? '-',
      isSubCategory: category?.isSubCategory,
      spent: category?.spent ? (
        <CurrencyFormat
          prefix={getCurrency('NGN')}
          value={numFormatter(category?.spent / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ) : (
        '-'
      ),
      available: category?.available ? (
        <CurrencyFormat
          prefix={getCurrency('NGN')}
          value={numFormatter(category?.available / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ) : (
        '-'
      ),
      limit: category?.limit ? (
        <CurrencyFormat
          prefix={getCurrency('NGN')}
          value={numFormatter(category?.limit / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ) : (
        '-'
      ),
      bufferAmount: category?.bufferAmount,
      modify: true,
      subRows: [
        ...subRows,
        {
          name: (
            <span>
              <PlusIcon height="12" width="12" />{' '}
              <span className="ms-1" style={{ color: '#D28B28' }}>
                Add a subcategory
              </span>
            </span>
          ),
          createButton: true,
          data: {
            parent: {
              code: category?.code,
              name: category?.name,
              limit: category?.limit,
              limitLeft: category?.limitLeft,
            },
          },
        },
      ],
    };
  });
}

export function buildExportCategoriesData(categories = [], type = 'categories') {
  return categories?.map((category) => {
    return [
      category?.name,
      category?.description ?? '-',
      `${'NGN'}${numeral(category?.limit / 100).format('0,0')}`,
      `${'NGN'}${numeral(category?.bufferAmount / 100).format('0,0')}`,
      `${'NGN'}${numeral(category?.spent / 100).format('0,0')}`,
      `${'NGN'}${numeral(category?.available / 100).format('0,0')}`,
    ];
  });
}

export function buildReimbursementTableData(reimbursements = []) {
  return reimbursements.map((reimbursment) => {
    const name = reimbursment.user
      ? `${reimbursment?.user?.firstName} ${reimbursment?.user?.lastName}`
      : '-';
    return {
      reimbursementsData: reimbursment,
      creationDate: <NameBadge name={getFormattedDate(reimbursment?.created_at)} />,
      deadLine: reimbursment?.deadLine && (
        <NameBadge name={getFormattedDate(reimbursment?.deadLine)} />
      ),

      by: {
        value: <NameBadge name={name} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },
      code: reimbursment?.code,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(reimbursment.currency)}
          value={numFormatter(reimbursment.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      budget: {
        type: 'budget',
        code: reimbursment.budget ? reimbursment.budget.code : null,
        value: reimbursment.budget ? reimbursment.budget.name : '-',
        icon:
          reimbursment.budget && !['deleted'].includes(reimbursment.budget?.status) ? (
            <PieChartOutlined />
          ) : null,
        link:
          reimbursment.budget && !['deleted'].includes(reimbursment.budget?.status)
            ? `expenses/budgets/${reimbursment.budget.code}/overview`
            : null,
      },
      vendor: reimbursment.vendor ? reimbursment.vendor.name : name,
      description: reimbursment?.description ? reimbursment?.description : '-',
      evidence:
        ICONS[reimbursment?.receipts?.length && reimbursment?.receipts[0]?.type] || '-',
      reviewer: reimbursment.reviewer
        ? `${reimbursment.reviewer.firstName} ${reimbursment.reviewer.lastName}`
        : 'No one',
      modify: true,
      status: {
        value: reimbursment.status,
        color: getColor(reimbursment.status),
        isStatus: true,
      },
      clickable: reimbursment?.approvalRequest?.status === 'pending' ? false : true,
    };
  });
}

export function buildRequestTableData(requests = []) {
  return requests.map((request) => {
    const name = request?.user
      ? `${request?.user?.firstName} ${request?.user?.lastName}`
      : '-';
    return {
      requestData: request,
      creationDate: <NameBadge name={getFormattedDate(request?.created_at)} />,
      deadLine: request?.deadLine && (
        <NameBadge name={getFormattedDate(request?.deadLine)} />
      ),
      by: {
        value: <NameBadge name={name} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(request.currency)}
          value={numFormatter(request.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      code: request?.code,
      description: request?.description ? request?.description : '-',
      evidence: ICONS[request?.receipts?.length && request?.receipts[0]?.type] || '-',
      reviewer: request.reviewer
        ? `${request.reviewer.firstName} ${request.reviewer.lastName}`
        : 'No one',
      type: {
        value: toTitle(request.type.replace(/_/g, ' ').toLowerCase()),
        color: asciiToHex(request.type),
      },
      modify: true,
      status: { value: request.status, color: getColor(request.status), isStatus: true },
      checkBoxDisabled: request.type !== 'PAYMENT' && request.status === 'approved',
      notClickable: request?.status === 'deleted',
    };
  });
}
export function buildEmployeeRequestTableData(requests = []) {
  return requests.map((request) => {
    const name = request.user
      ? `${request.user.firstName} ${request.user.lastName}`
      : '-';
    return {
      requestData: request,
      creationDate: <NameBadge name={getFormattedDate(request?.created_at)} />,
      deadLine: request?.deadLine && (
        <NameBadge name={getFormattedDate(request?.deadLine)} />
      ),
      destination: (
        <NameBadge
          name={
            request.budget?.name ||
            request.vendor?.name ||
            request.card?.name ||
            request.bankAccount?.name
          }
        />
      ),
      by: {
        type: 'beneficiary',
        code: request.user ? request.user.code : null,
        value: name,
        component: <NameBadge name={name} />,
        // icon: request.user ? <PieChartOutlined /> : null,
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(request.currency)}
          value={numFormatter(request.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      description: request?.description ? request?.description : '-',
      evidence: ICONS[request?.receipts?.length && request?.receipts[0]?.type] || '-',
      reviewer: request.reviewer
        ? `${request.reviewer.firstName} ${request.reviewer.lastName}`
        : 'No one',
      type: {
        value: toTitle(request.type.replace(/_/g, ' ').toLowerCase()),
        color: asciiToHex(request.type),
      },
      status: { value: request.status, color: getColor(request.status) },
      notClickable: !!(request?.status === 'deleted'),
      modify: true,
    };
  });
}

export function buildStatementTableData(statement) {
  return statement.map((item) => {
    const newData = {
      ...item,
      amount: {
        sign: '',
        value: (
          <CurrencyFormat
            prefix={getCurrency(item?.currency || 'NGN')}
            value={numFormatter(item?.amount / 100)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        ),
        color: getColor(item?.type),
      },
      balance: item?.balanceAfter ? (
        <CurrencyFormat
          prefix={getCurrency(item?.currency || 'NGN')}
          value={item?.balanceAfter / 100}
          displayType="text"
          thousandSeparator={true}
        />
      ) : (
        ''
      ),
      categoryName: {
        value: (item?.categoryName ?? item?.category) || 'uncategorized',
        color: asciiToHex((item?.categoryName ?? item?.category) || 'uncategorized'),
      },

      source: capitalizeFirstLetter(item?.source),
      type: capitalizeFirstLetter(item?.type),
      description: item?.description,
      created_at: getFormattedDate(item?.created_at),
    };
    return newData;
  });
}

export function buildExportStatementData(data) {
  return data.map((item) => [
    format(new Date(item?.created_at), 'd/MM/yyyy'),
    item?.description,
    `${getCurrency(item?.currency || 'NGN')} ${numFormatter(item?.amount / 100)}`,
    capitalizeFirstLetter(item?.type),
    item?.source,
    item?.from,
    item?.to,
    `${getCurrency(item?.currency || 'NGN')} ${numFormatter(
      item?.balanceAfter / 100 / 100,
    )}`,
    item?.categoryName ?? item?.category ?? 'uncategorized',
  ]);
}

export function buildExportStatementXero(data) {
  return data.map((item) => [
    format(new Date(item?.created_at), 'd/MM/yyyy'),
    item?.description,
    `${numFormatter(item?.amount / 100)}`,
    item?.to,
    (item?.categoryName ?? item?.category ?? 'Uncategorized').toLowerCase(),
  ]);
}

export function buildExportStatementQuickBooks(data) {
  return data.map((item) => [
    format(new Date(item?.created_at), 'd/MM/yyyy'),
    item?.description,
    `${numFormatter(item?.amount / 100)}`,
  ]);
}

export function buildBeneficiariesTableData(beneficiaries) {
  return beneficiaries.map((item) => {
    const { user = {}, budgets = [] } = item;
    const { manager } = user;
    const managerName =
      (manager && `${manager.firstName} ${manager.lastName}`) || 'No one';

    const name = `${user.firstName} ${user.lastName || '-'}`;

    const newData = {
      ...item,
      beneficiaryData: item,
      role: {
        value: capitalizeFirstLetter(user?.role?.name),
        color: asciiToHex(user?.role?.name),
      },

      name: {
        type: 'name',
        code: item?.code,
        component: <NameBadge name={`${user.firstName} ${user.lastName || '-'}`} />,
        value: name,
        subValue: `${user?.email}` || 'No email',
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },
      // contact: user.email,
      manager: { value: managerName, code: manager && manager.code },
      assignedBudget: {
        type: 'assignedBudget',
        code: null,
        value: '(Add to a budget)',
        number: budgets.length > 1 ? `+${budgets.length - 1}` : null,
        icon: <PlusCircleOutlined />,
      },
      dateAdded: getFormattedDate(item.created_at),
      status: {
        value: capitalizeFirstLetter(item.status),
        color: getColor(item.status),
        isStatus: true,
      },
      modify: true,
    };
    if (budgets && budgets.length) {
      newData['assignedBudget'] = {
        type: 'assignedBudget',
        link: !['deleted'].includes(budgets[0]?.status)
          ? `/expenses/budgets/${budgets[0].code}/overview`
          : null,
        code: budgets[0].code,
        value: budgets[0].name,
        number: budgets.length > 1 ? `+${budgets.length - 1}` : null,
        icon: <UpCircleOutlined />,
        downIcon: <DownCircleOutlined />,
        numberList: budgets.map((budget) => {
          return {
            ...budget,
            link: !['deleted'].includes(budget.status)
              ? `/expenses/budgets/${budget.code}/overview`
              : null,
          };
        }),
      };
    }
    return newData;
  });
}

export function buildTemporaryBeneficiariesTableData(beneficiaries) {
  return beneficiaries.map((item) => {
    const { manager, team, role } = item;
    const roleName = (role && role.label) || 'None';
    const managerName = (manager && manager.label) || 'No one';
    const teamName = (team && team.label) || 'None';
    const newData = {
      ...item,
      beneficiaryData: item,
      role: {
        value: capitalizeFirstLetter(roleName),
        color: asciiToHex(roleName),
      },
      name: {
        type: 'name',
        code: null,
        component: <NameBadge name={`${item.firstName} ${item.lastName || '-'}`} />,
        value: `${item.firstName} ${item.lastName || '-'}`,
        subValue: `${item?.email}` || 'No email',
      },
      manager: { value: managerName, code: manager && manager.code },
      team: { value: teamName, code: team && team.code },
      modify: true,
    };
    return newData;
  });
}

export function buildEmployeeReimbursementTableData(
  reimbursment = [],
  isOverView = false,
) {
  const updatedData = reimbursment.slice(0, isOverView ? 5 : reimbursment.length);
  return updatedData.map((reimbursment) => ({
    reimbursementsData: reimbursment,
    creationDate: <NameBadge name={getFormattedDate(reimbursment?.created_at)} />,
    deadLine: reimbursment?.deadLine && (
      <NameBadge name={getFormattedDate(reimbursment?.deadLine)} />
    ),

    amount: (
      <CurrencyFormat
        prefix={getCurrency(reimbursment?.currency)}
        value={numFormatter(reimbursment?.amount / 100)}
        displayType="text"
        thousandSeparator={true}
        isNumericString
      />
    ),
    vendor: reimbursment?.vendor ? reimbursment?.vendor?.name : '-',
    description: reimbursment?.description ? reimbursment?.description : '-',
    reviewer: reimbursment.reviewer
      ? `${reimbursment.reviewer.firstName} ${reimbursment.reviewer.lastName}`
      : 'No one',
    evidence:
      ICONS[reimbursment?.receipts?.length && reimbursment?.receipts[0]?.type] || '-',
    status: {
      value: reimbursment?.status,
      color: getColor(reimbursment?.status),
      isStatus: true,
    },
  }));
}

export function buildCardsTableData(cards) {
  return cards.map((card) => {
    const name = `${card?.user?.firstName} ${card?.user?.lastName}`;
    return {
      ...card,

      user: {
        value: name || '-',
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },
      name: {},
      card: {
        type: 'card',
        code: card?.name || '-',
        subValue: `${card?.name.substring(0, 22)} ••• ${card?.last_4 ?? ''}`,
        value: `${card?.name.substring(0, 22)} ••• ${card?.last_4 ?? ''}`,
        img: getCard(card?.brand, 'svg'),
        textColor: '#79716B',
      },
      limit: { amount: card?.amount, spent: card?.spent, currency: card?.currency },
      budget: {
        value: card?.budget?.name ?? '-',
        link:
          card?.budget?.status !== 'deleted' &&
          `/expenses/budgets/${card?.budget?.code}/overview`,
      },
      dateCreated: getFormattedDate(card?.created_at),
      status: { value: card?.status, color: getColor(card?.status), isStatus: true },
      modify: true,
    };
  });
}

export function buildSpendsTableData(spends = []) {
  if (!Array.isArray(spends)) return [];
  return spends.map((spend) => {
    return {
      ...spend,
      vendor: {
        type: 'plan',
        subValue: spend?.vendor?.name,
        code: spend?.vendor?.code,
        value: spend?.plan?.name,
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(null, null, (spend?.plan || spend?.vendor).name)}
          />
        ),
      },
      amount: (
        <CurrencyFormat
          prefix={getCurrency(spend?.currency)}
          value={numFormatter(spend?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      frequency: toTitle(spend.frequency),
      card: {
        type: 'card',
        code: spend?.card?.name || null,
        subValue: `•••• ${spend?.card?.last_4 ?? ''}`,
        value: `${(spend?.card?.name || 'Unknown').substr(0, 20)} ••• ${
          spend?.card?.last_4 ?? '••••'
        }`,
        img: getCard(spend?.card?.brand || 'unknown', 'svg'),
        textColor: '#79716B',
      },
      renewalDate: getFormattedDate(spend?.renewalDate),
      status: { value: spend?.status, color: getColor(spend?.status), isStatus: true },
      modify: true,
    };
  });
}

export const buildRequestedCardsTable = (cards) => {
  return cards.map((card) => {
    const name = `${card?.cardRequester?.firstName} ${card?.cardRequester?.lastName}`;
    return {
      ...card,
      requester: {
        value: name || '-',
        img: <ImgCard gap={false} initials={getInitials(null, null, name)} />,
      },

      card: {
        value: `${capitalizeFirstLetter(card?.type)} card`,
        img: getCard(card?.brand, 'svg'),
      },

      date: getFormattedDate(card?.requestedOn),
      budget: {
        value: card?.budget?.name ?? '-',
        link:
          card?.budget?.status !== 'deleted' &&
          `/expenses/budgets/${card?.budget?.code}/overview`,
      },

      status: { value: card?.status, color: getColor(card?.status), isStatus: true },
      modify: true,
    };
  });
};

export const invoiceStatusAliaser = (status, type = '') => {
  if (status === 'verifying') {
    return 'In review';
  }

  if (status === 'partial') {
    return 'Partially paid';
  }

  if (status === 'pause') {
    return 'Paused';
  }

  if (type === 'scheduled' && status === 'active') {
    return 'Scheduled';
  }

  return status;
};

export function buildInvoiceTableData(invoices) {
  const data = invoices?.map((invoice) => {
    return {
      details: invoice,
      name: {
        value: invoice?.customer?.name,
        subValue: invoice?.customer?.email || '-',
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(null, null, invoice?.customer?.name)}
          />
        ),
      },
      invoice_id: `${invoice?.code?.substr(0, 9)}...${invoice?.code?.substr(17)}`,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(invoice?.currency)}
          value={numFormatter(invoice?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      issued_date: <NameBadge name={getFormattedDate(invoice.created_at)} />,
      due_date: (
        <NameBadge
          name={!invoice.due_date ? 'Anytime' : getFormattedDate(invoice.due_date)}
        />
      ),
      date_paid: (
        <NameBadge name={invoice.paidOn ? getFormattedDate(invoice?.paidOn) : '-'} />
      ),

      next_due: (
        <NameBadge
          name={invoice.schedule ? getFormattedDate(invoice?.schedule?.nextDate) : '-'}
        />
      ),
      status: {
        value: invoiceStatusAliaser(invoice?.status, invoice?.type),
        color: getColor(invoiceStatusAliaser(invoice?.status)),
        subText:
          invoiceStatusAliaser(invoice?.status, invoice?.type) === 'Scheduled'
            ? dayjs(invoice?.schedule?.nextDate).format('DD MMM YYYY h:mm A')
            : '',
        isStatus: true,
      },
      modify: true,
    };
  });

  return data;
}

export function buildBillsTableData(bills = [], hasPermission = false) {
  const data = bills?.map((bill) => {
    return {
      name: {
        value: bill?.vendor?.name,
        img: (
          <span className="d-block my-1">
            <ImgCard gap={false} initials={getInitials(null, null, bill?.vendor?.name)} />
          </span>
        ),
      },

      category: {
        value: bill?.category?.name || 'uncategorized',
        color: asciiToHex(bill?.category?.name),
      },

      balanceDue: (
        <CurrencyFormat
          prefix={getCurrency(bill?.currency)}
          value={numFormatter(bill?.balanceDue / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      created_at: (
        <NameBadge name={bill.created_at ? getFormattedDate(bill?.created_at) : '-'} />
      ),
      code: bill?.code,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(bill?.currency)}
          value={numFormatter(bill?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),

      evidence: bill?.hasFile ? (
        <>{hasPermission ? <FileAttachmentComponent /> : '-'}</>
      ) : (
        <>
          <FileUpload
            target="bill"
            hasExistingFiles={false}
            showUploadedFiles={true}
            acceptedFile={{
              'application/pdf': ['.pdf'],
            }}
            callbackAction={bill?.type === 'scheduled' ? updateScheduledBill : updateBill}
            callbackPayload={bill?.code}
          />
        </>
      ),

      dueDate: (
        <NameBadge name={!bill.dueDate ? 'Anytime' : getFormattedDate(bill.dueDate)} />
      ),
      status: {
        value: invoiceStatusAliaser(bill?.status, bill?.type),
        color: getColor(invoiceStatusAliaser(bill?.status)),
        subText:
          invoiceStatusAliaser(bill?.status, bill?.type) === 'Scheduled'
            ? dayjs(bill?.schedule?.nextDate).format('DD MMM YYYY h:mm A')
            : '',
        isStatus: true,
      },
      modify: true,
    };
  });

  return data;
}

export function buildScheduledBillsTable(bills = [], hasPermission = false) {
  const data = bills?.map((bill) => {
    return {
      name: {
        value: bill?.vendor?.name,
        img: (
          <span className="d-block my-1">
            <ImgCard gap={false} initials={getInitials(null, null, bill?.vendor?.name)} />
          </span>
        ),
      },

      created_at: (
        <NameBadge name={bill.created_at ? getFormattedDate(bill?.created_at) : '-'} />
      ),

      frequency: (
        <NameBadge name={capitalizeFirstLetter(bill?.schedule?.occurrence ?? '')} />
      ),

      amount: (
        <CurrencyFormat
          prefix={getCurrency(bill?.currency)}
          value={numFormatter(bill?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),

      lastBillDate: (
        <NameBadge
          name={bill.schedule ? getFormattedDate(bill?.schedule?.prevDate) : '-'}
        />
      ),

      nextBillDate: (
        <NameBadge
          name={bill.schedule ? getFormattedDate(bill?.schedule?.nextDate) : '-'}
        />
      ),

      code: bill?.code,

      evidence: bill?.hasFile ? (
        <>{hasPermission ? <FileAttachmentComponent /> : '-'}</>
      ) : (
        <FileUpload
          target="bill"
          hasExistingFiles={false}
          showUploadedFiles={true}
          acceptedFile={{ 'application/pdf': ['.pdf'] }}
          callbackAction={updateScheduledBill}
          callbackPayload={bill?.code}
        />
      ),

      status: {
        value: invoiceStatusAliaser(bill?.status, bill?.type),
        color: getColor(invoiceStatusAliaser(bill?.status)),
        subText:
          invoiceStatusAliaser(bill?.status, bill?.type) === 'Scheduled'
            ? dayjs(bill?.schedule?.nextDate).format('DD MMM YYYY h:mm A')
            : '',
      },
      modify: true,
    };
  });

  return data;
}

export function buildCustomerInvoiceTableData(invoices) {
  const data = invoices?.map((invoice) => {
    return {
      details: invoice,
      name: {
        value: invoice?.customer?.name,
        subValue: invoice?.customer?.email || '-',
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(null, null, invoice?.customer?.name)}
          />
        ),
      },
      invoice_id: `${invoice?.code?.substr(0, 9)}...${invoice?.code?.substr(17)}`,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(invoice?.currency)}
          value={numFormatter(invoice?.amount / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      issued_date: <NameBadge name={getFormattedDate(invoice.created_at)} />,
      due_date: (
        <NameBadge
          name={!invoice.due_date ? 'Anytime' : getFormattedDate(invoice.due_date)}
        />
      ),
      date_paid: (
        <NameBadge name={invoice.paidOn ? getFormattedDate(invoice?.paidOn) : '-'} />
      ),

      next_due: (
        <NameBadge
          name={invoice.schedule ? getFormattedDate(invoice?.schedule?.nextDate) : '-'}
        />
      ),
      status: {
        value: invoiceStatusAliaser(invoice?.status, invoice?.type),
        color: getColor(invoiceStatusAliaser(invoice?.status)),
        subText:
          invoiceStatusAliaser(invoice?.status, invoice?.type) === 'Scheduled'
            ? dayjs(invoice?.schedule?.nextDate).format('DD MMM YYYY h:mm A')
            : '',
      },
      // modify: true,
    };
  });

  return data;
}

export function buildInvoiceHistoryTable(invoiceHistory = []) {
  return invoiceHistory.map((invoice, index) => {
    const status = invoice?.status;

    const currency = invoice?.subscription?.currency.trim()
      ? invoice?.subscription?.currency
      : 'NGN';
    const newData = {
      ...invoice,
      invoice: `Invoice #${String(index + 1).padStart(3, '0')}`,
      description: invoice?.subscription?.paymentPlan?.description,
      amount: (
        <CurrencyFormat
          prefix={getCurrency(currency)}
          value={invoice?.subscription?.amount / 100}
          displayType="text"
          thousandSeparator={true}
        />
      ),
      date: getFormattedDate(invoice?.subscription?.created_at),
      status: {
        value: status,
        color: getColor(status),
        isStatus: true,
      },
      modify: true,
    };
    return newData;
  });
}

export function buildCustomersData(customers) {
  const data = customers.map((customer) => {
    return {
      ...customer,
      code: customer?.code,
      name: {
        value: <NameBadge name={customer?.name} />,
        img: <ImgCard gap={false} initials={getInitials(null, null, customer?.name)} />,
      },
      type: capitalizeFirstLetter(customer?.type) ?? '-',
      email: {
        value: <NameBadge name={customer?.email} truncateLength={40} />,
      },
      outstanding: (
        <CurrencyFormat
          prefix={getCurrency('NGN')}
          value={numFormatter(customer?.outstanding / 100)}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      date: customer?.created_at ? getFormattedDate(customer?.created_at) : '-',
      status: customer?.status,
      modify: true,
    };
  });

  return data;
}

export function buildRolesData(roles) {
  const data = roles?.map((role) => {
    return {
      singleData: role,
      code: role?.code,
      name: {
        value: role.name,
        color: asciiToHex(role.name),
      },
      type: capitalizeFirstLetter(role?.type) ?? '-',
      description: role.description ? (
        <span className="roles-description">{role.description}</span>
      ) : (
        `${role?.permissions
          ?.map((permission) => permission.description.split('-')[0])
          .join(', ')
          .slice(0, 120)}...`
      ),
      created_by:
        role?.type === 'default'
          ? 'Bujeti'
          : capitalizeFirstLetter(role?.createdBy?.name) || '-',
      modify: true,
    };
  });
  return data;
}

export function buildReferralTableData(referrals) {
  const data = referrals?.map((referral) => {
    return {
      singleData: referral,
      code: referral?.code,
      email: referral?.email,
      validUntil: referral?.validUntil,
      status: { value: referral?.status, color: getColor(referral?.status) },
    };
  });
  return data;
}

export function buildDirectorsTableData(directors = []) {
  const data = directors?.map((director) => {
    return {
      name: {
        value: <NameBadge name={`${director?.firstName} ${director?.lastName}`} />,
        img: (
          <ImgCard
            gap={false}
            initials={getInitials(
              null,
              null,
              `${director?.firstName} ${director?.lastName}`,
            )}
          />
        ),
      },
      email: director?.email ?? '-',
      status: { value: director?.status, color: getColor(director?.status) },
    };
  });
  return data;
}

/**
 * Title a string
 * It makes the first char upperCase
 * e.g toTitle('hello') returns 'Hello'
 * @param title
 * @returns {string}
 */
export function toTitle(title) {
  return `${title?.charAt(0).toUpperCase()}${title?.slice(1).toLowerCase()}`;
}

export const states = [
  {
    value: 'FC',
    label: 'Abuja',
  },
  {
    value: 'AB',
    label: 'Abia',
  },
  {
    value: 'AD',
    label: 'Adamawa state',
  },
  {
    value: 'AK',
    label: 'AkwaIbom',
  },
  {
    value: 'AN',
    label: 'Anambra',
  },
  {
    value: 'BA',
    label: 'Bauchi',
  },
  {
    value: 'BY',
    label: 'Bayelsa',
  },
  {
    value: 'BE',
    label: 'Benue',
  },
  {
    value: 'BO',
    label: 'Borno',
  },
  {
    value: 'CR',
    label: 'CrossRiver',
  },
  {
    value: 'DE',
    label: 'Delta',
  },
  {
    value: 'EB',
    label: 'Ebonyi',
  },
  {
    value: 'ED',
    label: 'Edo',
  },
  {
    value: 'EK',
    label: 'Ekiti',
  },
  {
    value: 'EN',
    label: 'Enugu',
  },
  {
    value: 'GO',
    label: 'Gombe',
  },
  {
    value: 'IM',
    label: 'Imo',
  },
  {
    value: 'JI',
    label: 'Jigawa',
  },
  {
    value: 'KD',
    label: 'Kaduna',
  },
  {
    value: 'KN',
    label: 'Kano',
  },
  {
    value: 'KT',
    label: 'Katsina',
  },
  {
    value: 'KE',
    label: 'Kebbi',
  },
  {
    value: 'KO',
    label: 'Kogi',
  },
  {
    value: 'KW',
    label: 'Kwara',
  },
  {
    value: 'LA',
    label: 'Lagos',
  },
  {
    value: 'NA',
    label: 'Nassarawa',
  },
  {
    value: 'NI',
    label: 'Niger',
  },
  {
    value: 'OG',
    label: 'Ogun',
  },
  {
    value: 'ON',
    label: 'Ondo',
  },
  {
    value: 'OS',
    label: 'Osun',
  },
  {
    value: 'OY',
    label: 'Oyo',
  },
  {
    value: 'PL',
    label: 'Plateau',
  },
  {
    value: 'RI',
    label: 'Rivers',
  },
  {
    value: 'SO',
    label: 'Sokoto',
  },
  {
    value: 'TA',
    label: 'Taraba',
  },
  {
    value: 'YO',
    label: 'Yobe',
  },
  {
    value: 'ZA',
    label: 'Zamfara',
  },
];

export const recurrenceOption = [
  {
    value: 'one-time',
    label: 'Never',
    details: 'One-time budget',
  },
  {
    value: 'weekly',
    label: 'Weekly',
    details: 'Every Monday',
  },
  {
    value: 'monthly',
    label: 'Monthly',
    details: '1st day of each month',
  },
  {
    value: 'quarterly',
    label: 'Quarterly',
    details: '1st day of each quarter',
  },
  {
    value: 'yearly',
    label: 'Yearly',
    details: '1st day of each year',
  },
];

export const recurrencePaymentOption = (date) => {
  const day = dayjs(date).format('DD');
  const month = dayjs(date).format('MMMM');
  const currentDay = dayjs(date).format('dddd');
  return [
    {
      value: 'one-time',
      label: 'Never',
      details: `On ${month} ${day} only`,
    },
    {
      value: 'weekly',
      label: 'Weekly',
      details: `Every ${currentDay}`,
    },
    {
      value: 'monthly',
      label: 'Monthly',
      details: `Every month on day ${day}`,
    },
    {
      value: 'quarterly',
      label: 'Quarterly',
      details: `Every 3 months on day ${day}`,
    },
  ];
};

export const idType = [
  { value: 'nin', label: 'National ID Card' },
  { value: 'ip', label: 'International Passport' },
  { value: 'vi', label: 'Voter ID' },
  { value: 'dl', label: 'Driver License' },
];

export const validateRCNumber = (rcNumber) => {
  const rcNumberRegex = /^RC[0-9]{6,7}/;
  return rcNumberRegex.test(rcNumber);
};

export const checkPattern = (inputValue, regexPattern) => {
  // Create a regular expression object with the provided pattern
  var regex = new RegExp(regexPattern);

  // Use the test() method to check if the input matches the pattern

  return regex.test(inputValue);
};

export const secondsToMidnight = (n) => {
  return (
    (24 - n.getHours() - 1) * 60 * 60 +
    (60 - n.getMinutes() - 1) * 60 +
    (60 - n.getSeconds())
  );
};
const currentTime = new Date();
const minutes = 58;
export const expiry = currentTime.setTime(currentTime.getTime() + minutes * 60 * 1000);

export const weekdays = [
  { value: 'Mon', label: 'M' },
  { value: 'Tue', label: 'T' },
  { value: 'Wed', label: 'W' },
  { value: 'Thu', label: 'T' },
  { value: 'Fri', label: 'F' },
  { value: 'Sat', label: 'S' },
  { value: 'Sun', label: 'S' },
];

export const sortDays = (unsortedDays) => {
  let weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  let sortedDays = [];
  weekdays.forEach((value) => {
    if (unsortedDays.includes(value)) {
      sortedDays.push(value);
    }
  });
  return sortedDays;
};

export const policiesType = {
  INDIVIDUAL_ALLOCATION: 'pty_f0rj154iij4hz2fs1',
  SPENDING_LIMITS: 'pty_oog1v691zw0vxksmv',
  CALENDAR_LIMITS: 'pty_rkrugpgqqbh90oyuw',
  RECEIPT_POLICY: 'pty_zLTFw0Ze0FktoJDLr',
};

export const businessTypes = [
  { value: 'sole proprietorship', label: 'Sole Proprietorship', key: 'bnNumber' },
  {
    value: 'private limited liability',
    label: 'Private Limited Liability',
    key: 'rcNumber',
  },
  {
    value: 'public limited liability',
    label: 'Public Limited Liability',
    key: 'rcNumber',
  },
  { value: 'ngo', label: 'NGO', key: 'rcNumber' },
  { value: 'incorporated trustees', label: 'Incorporated Trustees', key: 'rcNumber' },
  { value: 'business name', label: 'Business Name', key: 'bnNumber' },
  { value: 'enterprise', label: 'Enterprise', key: 'bnNumber' },
  { value: 'partnership', label: 'Partnership', key: 'bnNumber' },
];

export function isObjectEmpty(obj) {
  const label = obj.label.toLowerCase();
  const keys = Object.keys(obj);
  if (!keys.includes('data')) return true;
  const dataKeys = Object.keys(obj.data);
  let type = null;
  if (label === 'receipt policy') {
    type = obj.data.type.value;
  }
  if (label === 'spending limits' && !dataKeys.includes('frequency')) return true;
  if (label === 'receipt policy' && !dataKeys.includes('amount') && type !== 'all')
    return true;
}

export const calculateProgressAndDaysLeft = (startDate, expiryDate) => {
  const targetDate = new Date(expiryDate);
  const start = new Date(startDate);

  const currentDate = new Date();

  const daysLeft = differenceInDays(targetDate, currentDate);

  const totalTime = differenceInDays(start, targetDate);
  const elapsedTime = differenceInDays(currentDate, targetDate);
  const percentageRemaining = ((totalTime - elapsedTime) / totalTime) * 100;

  return { daysLeft, percentageRemaining };
};

export const truncateMiddle = (text, maxLength) => {
  if (text?.length <= maxLength) return text;

  const ellipsisLength = 3;
  const portionLength = Math.floor((maxLength - ellipsisLength) / 2);

  const startPortion = text?.substring(0, portionLength);
  const endPortion = text?.substring(text.length - portionLength);

  return `${startPortion}......${endPortion}`;
};

export function sanitizeInput(data) {
  if (typeof data === 'string') {
    return data?.replace(/javascript:/gi, '')?.replace(/[<>]/g, '');
  } else {
    return data;
  }
}

export const toCamelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const camelCaseToNormalCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (char) => char.toUpperCase());
};

export const remapKeys = (data, keyMapping, duplicateKeys = []) => {
  // Step 1: Remap keys
  const remappedData = data.map((item) => {
    return Object.keys(item).reduce((acc, key) => {
      if (key in keyMapping) {
        const newKey = keyMapping[key];
        acc[newKey] = item[key];
      }
      return acc;
    }, {});
  });

  // If duplicateKeys is empty, return remappedData
  if (duplicateKeys.length === 0) {
    return remappedData;
  }

  // Step 2: Remove duplicates
  const uniqueSet = new Set();
  const uniqueData = remappedData.filter((item) => {
    const keyValues = duplicateKeys.map((key) => item[key]).join('|');
    if (uniqueSet.has(keyValues)) {
      return false;
    } else {
      uniqueSet.add(keyValues);
      return true;
    }
  });

  return uniqueData;
};

export const flattenCategories = (categories = []) => {
  const result = [];
  categories?.forEach((category) => {
    result.push({ ...category, children: [] }); // Push the main category without children
    category?.children?.forEach((subCategory) => {
      result.push(subCategory); // Push each subcategory
    });
  });
  return result;
};

export const removeEmptyString = (obj) => {
  if (obj === null || obj === undefined) return undefined;

  if (Array.isArray(obj)) {
    // Return the array if it is an array
    return obj
      .filter(
        (item) =>
          item !== '' &&
          item !== undefined &&
          item !== null &&
          (typeof item !== 'object' || Object.keys(item).length),
      )
      .map((item) => (typeof item === 'object' ? removeEmptyString(item) : item));
  }

  const cleanedObj = Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (value && typeof value === 'object') {
          value = removeEmptyString(value);
        }
        return [key, value];
      })
      .filter(
        ([_, value]) =>
          value !== '' &&
          value !== undefined &&
          value !== null &&
          (typeof value !== 'object' || Object.keys(value).length),
      ),
  );

  return Object.keys(cleanedObj).length ? cleanedObj : undefined;
};

export const getCompanySize = (value) => {
  switch (value) {
    case 'micro':
      return { value: 'micro', label: 'Micro (1 to 10 employees)' };
    case 'small':
      return { value: 'small', label: 'Small (11 to 50 employees)' };
    case 'medium':
      return { value: 'medium', label: 'Medium (51 to 200 employees)' };
    case 'large':
      return { value: 'large', label: 'Large (more than 200 employees)' };
    default:
      return { value: '', label: 'Select number of employees' };
  }
};

export const industries = [
  {
    label: 'Agriculture',
    value: 'idt_gnxq4u7ccooy8m9e3',
  },
  {
    label: 'Cryptocurrency',
    value: 'idt_k2nuc6sg5c5owf0b4',
  },
  {
    label: 'Digital Services',
    value: 'idt_h1e1cjaz3go3iriwg',
  },
  {
    label: 'E-Commerce',
    value: 'idt_sfw1e9kb7qdgeih11',
  },
  {
    label: 'Entertainment',
    value: 'idt_uqfwghq792l7vcjbq',
  },
  {
    label: 'Financial Services',
    value: 'idt_j0aw4kgpp892bdni6',
  },
  {
    label: 'FinTech',
    value: 'idt_ijzrxjc79buq7m1y8',
  },
  {
    label: 'General Services',
    value: 'idt_c9tsgmqlk1jmzg7fd',
  },
  {
    label: 'Hospitals and Health',
    value: 'idt_ulgokj2792daxoyq5',
  },
  {
    label: 'Software',
    value: 'idt_yrrm6eegi79zydea0',
  },
  {
    label: 'Health Tech',
    value: 'idt_720l8ku3o3hl5j8pn',
  },
  {
    label: 'Leisure and Entertainment',
    value: 'idt_8d2296vhdwsr95vmx',
  },
  {
    label: 'Membership Groups',
    value: 'idt_it2kqy662vtk0hku2',
  },
  {
    label: 'NGOs',
    value: 'idt_hfjs6gn144afoszx8',
  },
  {
    label: 'Press and Media',
    value: 'idt_2sh70zvi4znmxz41c',
  },
  {
    label: 'Religious Organizations',
    value: 'idt_ibtnhcfi9ckbmmbss',
  },
  {
    label: 'Restaurant and Food',
    value: 'idt_v6wkolf7w7gdukh43',
  },
  {
    label: 'Schools',
    value: 'idt_rv0v1e0yoosntnqmg',
  },
  {
    label: 'Travel & Hospitality',
    value: 'idt_jssjr619lqvijhrol',
  },
  {
    label: 'Utilities',
    value: 'idt_ov4qia857qkjvtax1',
  },
  {
    label: 'Others',
    value: 'idt_dcs19euw61k4hdsye',
  },
];

export const companySize = [
  { value: 'micro', label: 'Micro (1 to 10 employees)' },
  { value: 'small', label: 'Small (11 to 50 employees)' },
  { value: 'medium', label: 'Medium (51 to 200 employees)' },
  { value: 'large', label: 'Large (more than 200 employees)' },
];

export const transactionAcceptedFiles = {
  'text/html': ['.xlsx'],
  'image/jpeg': ['.jpeg', '.jpg', '.png'],
  'application/pdf': ['.pdf'],
  'audio/*': ['.mp3'],
  'video/*': ['.mp4'],
};

export const transactionFileSupported =
  'Supported file types: jpeg, png, pdf, xlsx, mp4, mp3. Max file size: 5mb';

export const STATUS = {
  SUCCESS: 'success',
  DECLINED: 'declined',
  PROCESSING: 'processing',
  PENDING: 'pending',
  FAILED: 'failed',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
  CANCELLED: 'cancelled',
  SCHEDULED: 'scheduled',
  PARTIAL: 'partial',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  VERIFIED: 'verified',
  PROCESSED: 'processed',
  DRAFT: 'draft',
};
