import { ArrowUpRightIcon, CopyIcon, PencilIcon, WalletIconO1 } from 'assets/icons';
import bankIcon from 'assets/icons/bank-icon2.svg';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import DetailsTimeline from 'components/Timeline/DetailsTimeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import ImgCard from 'components/UI/ImgCard';
import Loading from 'components/UI/Loading';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect, useMemo, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { deleteCategories } from 'redux/actions/CategoryAction';
import {
  deleteRequests,
  getRequestAssets,
  updateRequests,
} from 'redux/actions/RequestsAction';
import { getVendors } from 'redux/actions/VendorsAction';
import {
  asciiToHex,
  getAvailableBalance,
  getColor,
  getFormattedDate,
  groupSourceOptions,
  transactionAcceptedFiles,
} from 'utils/helper';

const ViewRequestDetails = ({
  setStep,
  setIsLoading,
  selectRequest,
  setAssetViewer,
  getReceiptList,
  hideHeader,
  parentRef,
}) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);

  const [receiptCode, setReceiptCode] = useState('');

  const {
    deleteRequest: { loading: isDeleting, success: successDelete },
    getSingleRequest: { data: singleData = {} },

    requestReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ requests }) => requests);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    getVendor: { loading: vendorLoadingData, data: vendorData, success: vendorSuccess },
  } = useSelector(({ vendors }) => vendors);

  const {
    requestData: { code, receipt, created_at: createdAt, user: requestUser } = {},
  } = selectRequest || {};

  const {
    status,
    description: requestDescription,
    approvalRequest = {},
  } = singleData?.data?.fundRequest ?? {};
  const { approvals: completedApprovals = [] } = approvalRequest || {};

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [budgetVisible, setBudgetVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [AccountVisible, setAccountVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [vendorVisible, setVendorVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [cardVisible, setCardVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [ruleCode, setRuleCode] = useState(null);
  const [newBudget, setNewBudget] = useState({
    visible: false,
    data: '',
    isEditingBudget: false,
  });
  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [saveButton, setSaveButton] = useState(false);

  const createdByMe = user?.code === requestUser?.code;

  useEffect(() => {
    if (!vendorData?.vendors?.length) dispatch(getVendors());
  }, []);

  const generateVendor = useMemo(() => {
    return vendorData?.vendors?.map(({ code: value, name: label }) => ({
      label,
      value,
    }));
  }, [vendorSuccess]);

  const canFetchAsset = !!singleData?.data?.fundRequest?.receipts?.length;

  useEffect(() => {
    if (!!canFetchAsset) {
      dispatch(getRequestAssets(singleData?.data?.fundRequest?.receipts));
    }
  }, [canFetchAsset]);

  const onHandleGetSelected = (value) => {
    editRequest(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editRequest = (code, categoryCode) => {
    dispatch(updateRequests({ code, category: categoryCode }));
    setIsEditingCategory(true);
  };

  useEffect(() => {
    if (requestDescription && !description?.data.length) {
      setDescription({
        ...description,
        data: requestDescription,
      });
    }
  }, [requestDescription]);

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setReceiptCode('');
    setSaveButton(false);
  };

  const attemptDelete = () => {
    setDeleting(true);
  };

  const deleteRequest = () => {
    dispatch(deleteRequests(code));
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(true);
  };

  useEffect(() => {
    if (successDelete) {
      setDeleting(false);
    }
  }, [successDelete]);

  useEffect(() => {
    if (receipt?.code) dispatch(getAssets(receipt?.code));
  }, [selectRequest]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(receiptAssets);
  };

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(true);
    }
  }, [receiptCode?.receipt]);

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory?.code));
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const openRule = (code) => {
    setRuleCode(code);
  };

  const requestMoreDetail = () => {
    hideHeader(true);
    setStep(2);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  useEffect(() => {
    if (singleData?.data?.fundRequest?.meta?.budget) {
      setNewBudget({
        ...newBudget,
        data: singleData?.data?.fundRequest?.meta?.budget,
      });
    }
  }, [singleData?.data?.fundRequest?.meta?.budget]);

  const handleBudgetChange = (val) => {
    setNewBudget({
      ...newBudget,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const handleDescription = () => {
    if (description.visible) {
      if (description?.data !== singleData?.data?.fundRequest?.description) {
        setDescription({
          ...description,
          isEditingDescription: true,
        });
        return dispatch(updateRequests({ code, description: description?.data }));
      }
      return toastError('Make a change to description');
    } else if (newBudget.visible) {
      if (newBudget?.data !== singleData?.data?.fundRequest?.meta?.budget) {
        setNewBudget({
          ...newBudget,
          isEditingBudget: true,
        });
        return dispatch(
          updateRequests({
            code,
            meta: {
              budget: newBudget.data,
            },
          }),
        );
      }
      return toastError('Make a change to the budget name');
    } else if (!!receiptCode?.receipt?.length) {
      dispatch(updateRequests({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const updateRequest = (value, type) => {
    if (type === 'card') {
      setCardVisible({ isLoading: true });
      return dispatch(updateRequests({ code, card: value }));
    }
    if (type === 'budget') {
      setBudgetVisible({ isLoading: true });
      return dispatch(updateRequests({ code, budget: value }));
    }
    if (type === 'vendor') {
      setVendorVisible({ isLoading: true });
      return dispatch(updateRequests({ code, vendor: value }));
    }
    if (type === 'account') {
      setAccountVisible({ isLoading: true });
      return dispatch(updateRequests({ code, bankAccount: value }));
    }
  };

  //
  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balances');
      setBudgetList((prevOptions) => [...budget]);
      setAccountList((prevOptions) => [...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAccountOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = accountList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  const approvers = [];

  approvalRequest?.approvalStages?.forEach((item) => {
    item.approvers.forEach((approver) => {
      const user = approver.user;
      const approver_code = approver.code;
      approvers.push({ user, approver_code, status: 'pending', message: '' });
    });
  });

  completedApprovals?.forEach((item) => {
    approvers.push({
      user: item?.approver?.user,
      date: getFormattedDate(item?.updated_at),
      status: item?.status,
      message: item?.reason ?? '',
    });
  });

  const requesterName = `${singleData?.data?.fundRequest?.user?.firstName} ${singleData?.data?.fundRequest?.user?.lastName}`;
  const bodyData = [
    {
      title: 'Requested by',
      value: (
        <div className="d-flex align-items-center gap-2">
          <ImgCard
            size="small"
            fullWidth={false}
            initials={singleData?.data?.fundRequest?.user?.firstName?.charAt(0)}
          />
          <span>{requesterName}</span>
        </div>
      ),
    },
    {
      title: 'Date',
      value: getFormattedDate(createdAt),
    },
    {
      title: 'Request ID',
      value: singleData?.data?.fundRequest?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [tabKey, setTabKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setTabKey(tabKey);
    setScrollSize(0);
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };

  const recipientName = selectRequest?.by?.value;

  return (
    <>
      <>
        <div
          className={classNames(
            'px-4 bg-white fixed-header',
            scrollSize > 80 ? 'pb-0' : 'pb-2',
          )}
        >
          <PaymentHeader
            fontSize={fontSize}
            iconSize={iconSize}
            scrollSize={scrollSize}
            status={singleData?.data?.fundRequest?.status}
            receipient={recipientName}
            moneyData={{
              currency: selectRequest?.requestData?.currency,
              amount: selectRequest?.requestData?.amount,
            }}
            imageIcon={WalletIconO1}
            isRequest
            createdByMe={createdByMe}
          />
        </div>

        <Tabs
          id="contolled-tab-example"
          activeKey={tabKey}
          onSelect={handleSelectKey}
          className="bg-white position-fixed w-100 pt-0 mt-0"
          style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
        >
          <Tab
            eventKey="details"
            title={<div className="d-flex">Details</div>}
            tabClassName="new-tab ms-4"
            style={{ paddingTop: 165 }}
          >
            <div className="slide-in-right pb-5">
              <div className="details-tab p-4">
                <div className="details-view mb-4">
                  <p>Overview</p>
                  <div className="container">
                    <DrawerBody data={bodyData} />
                  </div>
                </div>

                <div className="details-view mb-4">
                  <p>Payment details</p>
                  <div className="container">
                    {singleData?.data?.fundRequest?.category && (
                      <div>
                        <ItemInfo
                          title="Category"
                          value={
                            <BadgeType
                              value={{
                                value:
                                  expenseCategories.value.label ??
                                  singleData?.data?.fundRequest?.category?.name ??
                                  'None',
                                rightIcon: singleData?.data?.fundRequest?.category
                                  ?.code && (
                                  <ArrowUpRightIcon
                                    stroke={asciiToHex(
                                      expenseCategories.value.label ??
                                        singleData?.data?.fundRequest?.category?.name ??
                                        'None',
                                    )}
                                    width="16"
                                    height="16"
                                  />
                                ),
                                color: getColor(
                                  expenseCategories.value.label ??
                                    singleData?.data?.fundRequest?.category?.name ??
                                    'None',
                                ),
                              }}
                            />
                          }
                          withAction={status !== 'declined' && createdByMe}
                          action={() => setCategoryVisible(!categoryVisible)}
                          isLoading={isEditingCategory}
                          icon={
                            status !== 'declined' &&
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}

                    {categoryVisible && (
                      <div className="padding-top-xxs">
                        <CategoryDropdown
                          placeholder="Select a category"
                          onChange={(val) => onHandleGetSelected(val)}
                          value={expenseCategories.value}
                          defaultValue={{
                            value: singleData?.data?.fundRequest?.category?.code,
                            label: singleData?.data?.fundRequest?.category?.name,
                          }}
                          name="category"
                        />
                      </div>
                    )}
                    {singleData?.data?.fundRequest?.vendor && (
                      <div className="pt-3">
                        <ItemInfo
                          title="Vendor"
                          isLink={
                            singleData?.data?.fundRequest?.vendor?.code
                              ? `/expenses/vendors/${singleData?.data?.fundRequest?.vendor.code}`
                              : false
                          }
                          withAction={['pending'].includes(status)}
                          action={() =>
                            setVendorVisible({ isVisible: !vendorVisible.isVisible })
                          }
                          value={
                            <div className="d-flex align-items-center gap-2">
                              <ImgCard
                                size="small"
                                fullWidth={false}
                                initials={(
                                  singleData?.data?.fundRequest?.vendor?.name ?? '-'
                                )?.charAt(0)}
                              />
                              <span>
                                {singleData?.data?.fundRequest?.vendor?.name ?? '-'}
                              </span>
                            </div>
                          }
                          isLoading={vendorVisible.isLoading}
                          icon={
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}
                    {vendorVisible.isVisible && (
                      <div className="padding-top-xxs">
                        <CustomSelect
                          placeholder="Select a vendor"
                          name="vendor"
                          isClearable
                          options={generateVendor}
                          onChange={(val) => updateRequest(val.value, 'vendor')}
                          defaultValue={{
                            value: singleData?.data?.fundRequest?.vendor?.code,
                            label: singleData?.data?.fundRequest?.vendor?.name,
                          }}
                        />
                      </div>
                    )}

                    {singleData?.data?.fundRequest?.card && (
                      <div className="pt-3">
                        <ItemInfo
                          title="Card"
                          isLink={
                            singleData?.data?.fundRequest?.card?.code
                              ? `/cards/${singleData?.data?.fundRequest?.card.code}`
                              : false
                          }
                          withAction={['pending'].includes(status)}
                          action={() =>
                            setCardVisible({ isVisible: !cardVisible.isVisible })
                          }
                          value={singleData?.data?.fundRequest?.card?.name ?? '-'}
                          isLoading={cardVisible.isLoading}
                          icon={
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}

                    {cardVisible.isVisible && (
                      <div className="padding-top-xxs">
                        <CustomSelect
                          label="Card"
                          name="card"
                          placeholder={`Select a card`}
                          defaultValue={
                            singleData?.data?.fundRequest?.card?.code && {
                              value: singleData?.data?.fundRequest?.card?.code,
                              label: singleData?.data?.fundRequest?.card?.name,
                            }
                          }
                          onChange={(val) => updateRequest(val.value, 'card')}
                          options={generateVendor}
                        />
                      </div>
                    )}

                    {singleData?.data?.fundRequest?.bankAccount?.balance && (
                      <div className="pt-3">
                        <ItemInfo
                          title="Account"
                          isLink={
                            singleData?.data?.fundRequest?.bankAccount?.balance?.code
                              ? `/accounts/${singleData?.data?.fundRequest?.bankAccount?.balance?.code}`
                              : false
                          }
                          withAction={['pending'].includes(status)}
                          action={() =>
                            setAccountVisible({ isVisible: !AccountVisible.isVisible })
                          }
                          value={
                            <div className="d-flex align-items-center gap-2">
                              {singleData?.data?.fundRequest?.bankAccount?.balance
                                ?.name && (
                                <ImgCard
                                  size="small"
                                  fullWidth={false}
                                  initials={(
                                    singleData?.data?.fundRequest?.bankAccount?.balance
                                      ?.name ?? '-'
                                  )?.charAt(0)}
                                >
                                  <img
                                    onError={handleImgError}
                                    className="account-logo"
                                    src={bankIcon}
                                  />
                                </ImgCard>
                              )}

                              <span>
                                {singleData?.data?.fundRequest?.bankAccount?.balance
                                  ?.name ?? '-'}
                              </span>
                            </div>
                          }
                          isLoading={AccountVisible.isLoading}
                          icon={
                            ['pending'].includes(status) && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}

                    {AccountVisible.isVisible && (
                      <div className="padding-top-xxs">
                        <CustomSelectRadio
                          placeholder="Select an account"
                          name="account"
                          onChange={(val) => updateRequest(val.value, 'account')}
                          defaultValue={
                            singleData?.data?.fundRequest?.bankAccount?.balance?.code && {
                              value:
                                singleData?.data?.fundRequest?.bankAccount?.balance?.code,
                              label:
                                singleData?.data?.fundRequest?.bankAccount?.balance?.name,
                            }
                          }
                          isLoading={loadingBalances}
                          isDisabled={loadingBalances}
                          loadOptions={loadAccountOptions}
                        />
                      </div>
                    )}

                    {singleData?.data?.fundRequest?.meta?.budget && (
                      <div className="pt-3">
                        <ItemInfo
                          title="New Budget Name"
                          withAction={['pending'].includes(status)}
                          value={
                            <div className="d-flex align-items-center gap-2">
                              {singleData?.data?.fundRequest?.meta?.budget && (
                                <ImgCard
                                  size="small"
                                  fullWidth={false}
                                  initials={(
                                    singleData?.data?.fundRequest?.meta?.budget ?? '-'
                                  )?.charAt(0)}
                                >
                                  <img
                                    onError={handleImgError}
                                    className="account-logo"
                                    src={bankIcon}
                                  />
                                </ImgCard>
                              )}

                              <span>
                                {singleData?.data?.fundRequest?.meta?.budget ?? '-'}
                              </span>
                            </div>
                          }
                          isLoading={newBudget.isEditingBudget}
                          action={() =>
                            setNewBudget({
                              ...newBudget,
                              visible: !newBudget.visible,
                            })
                          }
                          icon={
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}

                    {newBudget.visible && (
                      <div className="padding-top-xxs">
                        <CustomInput
                          label="Budget name"
                          placeholder="Enter name"
                          type="text"
                          name="budgetName"
                          onChange={handleBudgetChange}
                          value={newBudget.data}
                          maxLength="100"
                        />
                      </div>
                    )}

                    {singleData?.data?.fundRequest?.budget && (
                      <div className="pt-3">
                        <ItemInfo
                          title="Budget"
                          isLink={
                            singleData?.data?.fundRequest?.budget?.code
                              ? `/expenses/budgets/${singleData?.data?.fundRequest?.budget.code}/overview`
                              : false
                          }
                          withAction={['pending'].includes(status)}
                          action={() =>
                            setBudgetVisible({ isVisible: !budgetVisible.isVisible })
                          }
                          value={
                            <div className="d-flex align-items-center gap-2">
                              {singleData?.data?.fundRequest?.budget?.name && (
                                <ImgCard
                                  size="small"
                                  fullWidth={false}
                                  initials={(
                                    singleData?.data?.fundRequest?.budget?.name ?? '-'
                                  )?.charAt(0)}
                                >
                                  <img
                                    onError={handleImgError}
                                    className="account-logo"
                                    src={bankIcon}
                                  />
                                </ImgCard>
                              )}

                              <span>
                                {singleData?.data?.fundRequest?.budget?.name ?? '-'}
                              </span>
                            </div>
                          }
                          isLoading={budgetVisible.isLoading}
                          icon={
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}
                    {budgetVisible.isVisible && (
                      <div className="padding-top-xxs">
                        <CustomSelectRadio
                          placeholder="Select a budget"
                          name="budget"
                          onChange={(val) => updateRequest(val.value, 'budget')}
                          defaultValue={
                            singleData?.data?.fundRequest?.budget?.code && {
                              value: singleData?.data?.fundRequest?.budget?.code,
                              label: singleData?.data?.fundRequest?.budget?.name,
                            }
                          }
                          isLoading={loadingBalances}
                          isDisabled={loadingBalances}
                          loadOptions={loadOptions}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="padding-top-2">
                  <div className="padding-top-xxs">
                    <CustomTextarea
                      label="Description"
                      action={() => {
                        setDescription({ ...description, visible: !description.visible });
                        setSaveButton(!saveButton);
                      }}
                      isLoading={description.isEditingDescription}
                      icon={
                        ['pending'].includes(status) &&
                        createdByMe && (
                          <PencilIcon stroke="#D28B28" width="18" height="18" />
                        )
                      }
                      name="description"
                      placeholder="Description"
                      value={description?.data}
                      disabled={
                        !description?.visible ||
                        (!['pending'].includes(status) && !createdByMe)
                      }
                      onChange={handleDescriptionChange}
                      rowSize={4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="attachements"
            title={<div className="d-flex">Attachments</div>}
            tabClassName="new-tab"
            style={{ paddingTop: 165 }}
          >
            <div className="slide-in-right p-4 pb-5">
              <div className="details-tab">
                <div className="details-view mb-4">
                  <p>Receipts</p>
                  <div className="container d-flex gap-2 overflow-x-scroll">
                    {loadingReceipt && !receiptAssets?.length && <LoadingReciept />}
                    <NewReceiptUpload
                      receiptAssets={receiptAssets}
                      viewImgUrl={viewImgUrl}
                      addMore={true}
                      removeFile={!receiptCode.receipt?.length}
                      acceptedFile={transactionAcceptedFiles}
                      onChange={(value) =>
                        setReceiptCode({
                          receipt: value?.map((item) => item.assetCode),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="updates"
            title={<div className="d-flex">Updates</div>}
            tabClassName="new-tab"
            style={{ paddingTop: 165 }}
          >
            <div className="slide-in-right p-4 pb-5">
              <div className="details-tab">
                <div className="details-view mb-4">
                  <Timeline data={approvers} goToRule={openRule} />
                  {['pending'].includes(singleData?.data?.fundRequest?.status) &&
                    createdByMe &&
                    !!singleData?.data?.fundRequest?.moreInfoLogs?.length && (
                      <>
                        <div className="divider"></div>
                        <DetailsTimeline
                          data={singleData?.data?.fundRequest?.moreInfoLogs}
                          action={requestMoreDetail}
                        />
                      </>
                    )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>

        <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
          {saveButton && (
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <CustomButton
                onClick={cancelDescriptionEdit}
                className="base-button text-sm font-medium  black-transparent"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleDescription}
                className="base-button text-sm font-medium dark-button"
              >
                {description?.isEditingDescription ||
                newBudget.isEditingBudget ||
                receiptCode?.isUpdating ? (
                  <Loading color="#D28B28" size={20} />
                ) : (
                  'Save changes'
                )}
              </CustomButton>
            </div>
          )}

          {status === 'pending' && createdByMe && !saveButton && (
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <CustomButton
                withoutBg
                fullWidth
                className="base-button danger-button text-sm font-medium"
                onClick={attemptDelete}
              >
                Delete request
              </CustomButton>
            </div>
          )}
        </div>
      </>
      {showCategoryDeleteModal && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete ${selectedCategory?.name} Category`}
            subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
            onCancel={() => setShowCategoryDeleteModal(false)}
            onDelete={onHandleDeleteCategory}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}

      {deleting && (
        <Modal show={deleting} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete request`}
            subTitle="Are you sure you want to delete this request? This action can not be undone."
            onCancel={() => setDeleting(false)}
            onDelete={deleteRequest}
            styles={{ width: 400 }}
            isLoading={isDeleting}
          />
        </Modal>
      )}
    </>
  );
};
export default ViewRequestDetails;
