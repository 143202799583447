import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';

import { Col, Modal, Row } from 'react-bootstrap';

import { Checkbox } from 'antd';
import { CancelICon } from 'assets/icons';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';
import CustomTextarea from 'components/UI/CustomTextarea';
import useTextCounter from 'hooks/useTextCounter';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategories } from 'redux/actions/CategoryAction';
import { getCurrency } from 'utils/helper';
import ModalContainer from 'components/ModalContainer';

const EditCategory = ({ selectedData, setShowEditModal }) => {
  const initial = {
    description: undefined,
    name: undefined,
    limit: undefined,
    bufferAmount: undefined,
    bufferPercentage: undefined,
  };

  const dispatch = useDispatch();

  const {
    updateCategories: { loading: updateLoading },
  } = useSelector(({ categories }) => categories);

  const [categoryData, setCategoryData] = useState({ ...initial });
  const [hasBufferAmount, setHasBufferAmount] = useState(false);
  const [bufferAmount, setBufferAmount] = useState(0);

  useEffect(() => {
    if (categoryData.limit && categoryData.bufferPercentage) {
      if (categoryData.limit === '0' || categoryData.bufferPercentage === '0')
        return setBufferAmount(0);
      setBufferAmount(
        (
          (Number(categoryData.limit) * Number(categoryData.bufferPercentage)) /
          100
        ).toFixed(2),
      );
    } else {
      setBufferAmount(0);
    }
  }, [categoryData.limit, categoryData.bufferPercentage]);

  useEffect(() => {
    if (selectedData) {
      setHasBufferAmount(!!selectedData?.bufferAmount);

      const buffer = selectedData?.bufferAmount ? selectedData?.bufferAmount / 100 : 0;

      setBufferAmount(buffer);

      setCategoryData({
        code: selectedData?.code,
        description: selectedData?.description ?? undefined,
        name: selectedData.name,
        limit: selectedData?.limit ? selectedData?.limit / 100 : undefined,
        bufferAmount: buffer,
        bufferPercentage:
          selectedData?.limit && selectedData?.bufferAmount
            ? (selectedData?.bufferAmount * 100) / selectedData?.limit
            : undefined,
      });
    }
  }, [selectedData]);

  const onHandleCancel = () => {
    setShowEditModal(false);
  };

  const handleDescriptionChange = (value) => {
    setCategoryData({
      ...categoryData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    categoryData?.description,
    250,
    handleDescriptionChange,
  );

  const handleSubmit = () => {
    const { name, description, limit, code } = categoryData;

    if (!name) return toastError('Please input name');

    const payload = {
      code,
      name,
      description: description ?? undefined,
      limit: limit ? limit * 100 : undefined,
      bufferAmount: bufferAmount * 100,
    };

    dispatch(updateCategories(payload));
  };

  return (
    <Modal show={open} centered dialogClassName="custom-dialog">
      <ModalContainer
        lg
        loading={updateLoading}
        onCancel={onHandleCancel}
        actionBtnText="Save changes"
        cancelBtn="Discard"
        onConfirm={handleSubmit}
        title="Edit"
        subTitle="Make updates to your category details."
      >
        <div className="pb-4 px-0">
          <Row>
            <CustomInput
              label="Category name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setCategoryData({ ...categoryData, name: event.target.value })
              }
              value={categoryData?.name}
            />
          </Row>

          <Row>
            <CustomTextarea
              label="Description(Optional)"
              name="description"
              placeholder="Enter description"
              value={text || categoryData?.description}
              labelClass="text-sm"
              charCount={charCount}
              onChange={handleCharChange}
              rowSize={4}
            />
          </Row>

          <Row className="mb-2 mt-3 ">
            <CustomInput
              placeholder="0.00"
              type="number"
              label="Category limit"
              isAmount
              otherCurrency={true}
              name="limit"
              onChange={(event) =>
                setCategoryData({ ...categoryData, limit: event.target.rawValue })
              }
              value={categoryData.limit}
            />
          </Row>

          <Col md={10} className="buffer px-0">
            <div className="buffer-wrapper gap-2 mt-2">
              <Checkbox
                onChange={({ target: { checked } }) => {
                  if (!checked) {
                    setBufferAmount(0);
                    setCategoryData({
                      ...categoryData,
                      bufferPercentage: undefined,
                    });
                  }
                  setHasBufferAmount(!hasBufferAmount);
                }}
                checked={hasBufferAmount}
              ></Checkbox>
              <div>
                <h6>Set Buffer</h6>
                <span>
                  An additional amount of money added to a budget as a cushion or
                  contingency to account for unexpected expenses
                </span>
              </div>
            </div>
            {hasBufferAmount && (
              <div className="w-100">
                <Row className="align-items-center d-flex mt-3 w-100">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 7,
                    }}
                    className="col-md-8"
                  >
                    <div className="text-xs buffer-limit-text">Buffer %</div>
                    <CustomPercentageInput
                      placeholder="%"
                      md={null}
                      name="bufferAmount"
                      onChange={(value) =>
                        setCategoryData({
                          ...categoryData,
                          bufferPercentage: value,
                        })
                      }
                      value={categoryData.bufferPercentage}
                    />
                  </div>

                  <div className="col-md-4 w-auto">
                    <CurrencyFormat
                      value={bufferAmount}
                      thousandSeparator={true}
                      displayType="text"
                      prefix={getCurrency('NGN')}
                    />
                  </div>
                </Row>
              </div>
            )}
          </Col>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default EditCategory;
