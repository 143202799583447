import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import TransactionDrawer from 'components/TransactionModal/TransactionDrawer';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBalances, getBudgetLedger } from 'redux/actions/BudgetsAction';
import {
  buildBudgetLedgerTableData,
  capitalizeFirstLetter,
  getAvailableBalance,
  groupSourceOptions,
} from 'utils/helper';
import { budgetLedgercolumns } from 'utils/mockData';
import { toastError, toastSuccess } from 'components/UI/toast';
import {
  CardStatusType,
  CurrencyType,
} from '../../../components/FilterModal/FilterHelper';
import MigrateOldBudgetInfo from '../MigrateOldBudgetInfo';
import PendingOnboardingNotice from '../PendingOnboardingNotice';
import CustomButton from 'components/UI/CustomButton';
import TransactionEmptyStateData from 'pages/Transactions/transactionEmptystateData';
import Loading from 'components/UI/Loading';
import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import ReceiptPdf from 'components/ReceiptPdf';
import {
  BankNoteO1Icon,
  CalenderIcon,
  CancelICon,
  CheckIcon,
  DownloadIcon,
  RetryIcon,
  SendIcon,
  SlashCircleIcon,
} from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { useDebounce } from 'hooks/useDebounce';
import useIsFiltered from 'hooks/useIsFiltered';
import {
  cancelTransaction,
  requeryTransaction,
  retryTransaction,
  getSingleTransaction,
  editTransactionById,
} from 'redux/actions/TransactionsAction';
import { ReactComponent as ReloadIcon } from '../../../assets/icons/retry-icon.svg';
import StatementModal from 'pages/Cash/Overview/StatementModal';
import { payNowAction } from 'redux/actions/PaymentAction';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { approvalState } from 'pages/Transactions/approvalState';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import DeclineRequest from 'components/FundRequest/ReviewerModal/DeclineRequest';
import CustomModal from 'components/UI/Modal';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import BujetiPayModal from 'components/bujetiPayModal';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import TransactionDetails from 'components/TransactionModal/TransactionDetails';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';

const BudgetTransaction = () => {
  const { budgetId } = useParams();
  const [selectTransaction, setSelectTransaction] = useState(null);
  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [search, setSearch] = useState('');
  const [statementModal, setStatementModal] = useState(false);
  const [source, setSource] = useState();
  const debouncedValue = useDebounce(search, 600);
  const { filtered, isFiltered, filteredQuery, setFilteredQuery } = useIsFiltered();
  const [loadActions, setLoadActions] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showSendFunds, setShowSendFunds] = useState(false);
  const [recipientCode, setRecipientCode] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetData, setAssetData] = useState(null);

  const {
    getSingleTransaction: { data: singleTransaction = {}, error },
    retryTransaction: { loading: retryLoading, success: retrySuccess },
    cancelTransaction: { loading: cancelLoading, success: cancelSuccess },
    requeryTransaction: { loading: loadingRequery, success: successRequery },
    editTransactionById: { loading: loadingUpdate },
  } = useSelector(({ transaction }) => transaction);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const {
    getBudgetLedger: {
      data: { budgetLedgers: transactions = [], meta = {} } = {},
      loading: statsLoading,
    },
    getSingleBudget: { data = {} },
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const activeTxCode = useRef(null);

  const { permissions } = allPermissions();

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const { page = 1, total = 1, hasMore, perPage = 50, nextPage } = meta;

  const dispatch = useDispatch();
  const [isFillData, setIsFillData] = useState(false);

  // buildBudgetLedgerTableData
  const renderTable = () => {
    const [filterData, setFilterData] = useState([
      { title: 'Amount', type: 'slider' },
      ...CardStatusType,
      ...CurrencyType,
    ]);

    // Handle search change after debounce
    useEffect(() => {
      if (debouncedValue) {
        filteredQuery.search = debouncedValue;
        dispatch(
          getBudgetLedger({ ...filteredQuery, budget: budgetId, search: debouncedValue }),
        );
      }
      if (!debouncedValue && filtered) {
        delete filteredQuery.search;
        dispatch(getBudgetLedger({ budget: budgetId, ...filteredQuery }));
        isFiltered.current = Object.keys(filteredQuery).length ? true : false;
      }
      if (debouncedValue) isFiltered.current = true;
    }, [debouncedValue]);

    const handleFilterApply = (query) => {
      dispatch(getBudgetLedger({ budget: budgetId, ...query }));
      setFilteredQuery({ budget: budgetId, ...query });
      isFiltered.current = !!Object.keys(query).length;
    };

    const handlePagination = (page) => {
      isFiltered.current = true;
      dispatch(getBudgetLedger({ ...filteredQuery, perPage, page, budget: budgetId }));
      setFilteredQuery({ ...filteredQuery, perPage, page, budget: budgetId });
    };

    const handleRowClick = (row) => {
      if (row.paymentMethod?.type === 'top up') return;
      setSelectTransaction(row);
      setAssets({
        ...assets,
        assetsList: row?.transactionData?.receipts,
      });
      activeTxCode.current = null;
    };

    const onHandleAssetViewer = () => {
      setAssets({
        ...assets,
        visible: true,
      });
    };

    const tableColumn = budgetLedgercolumns.filter((item) =>
      canEditTransaction ? item : item.Header !== 'Actions',
    );

    const actionHandler = (event, type, value) => {
      event?.stopPropagation();
      event?.preventDefault();
      setIsPopoverOpen(true);
      setSelectedOption(value);
      setType(type.toLowerCase());

      if (['pay_again', 'repeat_transaction'].includes(type)) {
        setShowSendFunds(true);
        setRecipientCode(value);
        return;
      }
      if (!['approve_schedule', 'decline_request'].includes(type))
        return setConfirmModal(true);
    };

    const closeModal = () => {
      setIsPopoverOpen(false);
      setConfirmModal(false);
    };

    useEffect(() => {
      if (
        retrySuccess ||
        cancelSuccess ||
        successRequery ||
        successPayNow ||
        successReview
      ) {
        closeModal();
        isFiltered.current = true;
        dispatch(getBudgetLedger({ ...filteredQuery, budget: budgetId }));
        dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getSingleTransaction' });
        activeTxCode.current = null;
        setIsLoaded(true);
      }
    }, [retrySuccess, cancelSuccess, successRequery, successPayNow, successReview]);

    useEffect(() => {
      if (error) {
        setIsPopoverOpen(false);
        activeTxCode.current = null;
      }
    }, [error]);

    const handleConfirm = ({ schedule }) => {
      const code = selectedOption?.code;
      if (type === 'retry') {
        dispatch(retryTransaction(code));
      } else if (type === 'cancel') {
        dispatch(cancelTransaction(code));
      } else if (type === 'refresh') {
        dispatch(requeryTransaction(code));
      } else if (type === 'pay') {
        if (selectedOption?.balance?.code) {
          return dispatch(payNowAction({ code }));
        } else if (!source) {
          return toastError('Select a source');
        }
        return dispatch(payNowAction({ code, source: source.value }));
      } else if (['approve and pay', 'approve', 'approve_schedule'].includes(type)) {
        handleApproveRequest({
          schedule,
          code: selectedOption?.approvalRequest?.code,
        });
      }
    };

    const handleUpdateRequest = (code, field, value) => {
      dispatch(
        editTransactionById({
          [field]: value,
          transaction_code: code,
          showSuccessToast: false,
        }),
      );
    };

    const handleApproveRequest = ({ schedule, code }) => {
      dispatch(
        reviewRequest({
          code,
          status: 'approved',
          actionLater: schedule ? undefined : !payNow,
          schedule,
        }),
      );
    };

    const generatePdfSuccess = async (event, instance) => {
      event?.stopPropagation();
      if (instance.loading || !instance.url) return;
      toastSuccess('Receipt downloaded');
    };

    useEffect(() => {
      if (singleTransaction?.data) setLoadActions(true);
    }, [singleTransaction?.data]);

    const fetchSingleData = useCallback(
      (data) => {
        const { code = '' } = data?.transactionData || {};

        activeTxCode.current = code;
        if (activeTxCode.current) dispatch(getSingleTransaction(code));
      },
      [activeTxCode.current],
    );

    const Actions = useCallback(
      ({ list: selectedData }) => {
        const status = selectedData?.status?.value.toLowerCase();

        useMemo(() => {
          if (selectedData?.transactionData?.code !== activeTxCode.current) {
            setLoadActions(false);
            if (['success', 'processed', 'pending'].includes(status))
              fetchSingleData(selectedData);
          }
        }, []);

        const { canApprovePay, yourTurnToApprove } = approvalState({
          data: singleTransaction?.data,
          user,
        });

        if (!loadActions && ['success', 'processed', 'pending'].includes(status))
          return (
            <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
              <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
            </div>
          );
        return (
          <div className="actions-dialog">
            {!yourTurnToApprove && ['scheduled', 'declined'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) => {
                  setIsPopoverOpen(false);
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                No options available
              </div>
            )}

            {loadActions && yourTurnToApprove && (
              <>
                {canApprovePay && (
                  <div
                    className="actionLink"
                    onClick={(event) => {
                      setPayNow(canApprovePay);
                      actionHandler(event, 'approve and pay', singleTransaction?.data);
                    }}
                  >
                    <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                    Approve and pay
                  </div>
                )}

                <div
                  className="actionLink"
                  onClick={(event) => {
                    setPayNow(false);
                    actionHandler(event, 'approve', singleTransaction?.data);
                  }}
                >
                  <CheckIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                  Approve only
                </div>

                {canApprovePay && (
                  <div
                    className="actionLink"
                    onClick={(event) => {
                      setIsSchedule(true);
                      actionHandler(event, 'approve_schedule', singleTransaction?.data);
                    }}
                  >
                    <CalenderIcon
                      stroke="#79716B"
                      width={16}
                      height={16}
                      className="mr-4"
                    />
                    Approve and schedule
                  </div>
                )}

                <div
                  className="actionLink"
                  onClick={(event) => {
                    setDeclineRequest(true);
                    setIsLoaded(false);
                    actionHandler(event, 'decline_request', singleTransaction?.data);
                  }}
                >
                  <SlashCircleIcon
                    stroke="#79716B"
                    width={16}
                    height={16}
                    className="mr-4"
                  />
                  Decline request
                </div>
              </>
            )}

            {['processing'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(event, 'refresh', selectedData?.ledgerData)
                }
              >
                <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
                Refresh status
              </div>
            )}

            {['failed'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(event, 'retry', selectedData?.ledgerData)
                }
              >
                <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
                Retry transaction
              </div>
            )}

            {['approved'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(event, 'pay', selectedData?.transactionData)
                }
              >
                <BankNoteO1Icon style={{ marginLeft: '-4px', marginRight: '6px' }} /> Make
                payment
              </div>
            )}

            {['pending'].includes(status) && (
              <div
                className="actionLink svg-danger text-danger"
                onClick={(event) =>
                  actionHandler(event, 'cancel', selectedData?.ledgerData)
                }
              >
                <CancelICon className="mr-4" width={10} height={10} /> Cancel transaction
              </div>
            )}

            {['success', 'processed'].includes(status) && (
              <>
                {selectedData?.paymentMethod === 'Transfer' && (
                  <>
                    <div
                      className="actionLink ms-1"
                      onClick={(event) =>
                        actionHandler(event, 'pay_again', {
                          code: selectedData?.ledgerData?.transaction?.recipient?.code,
                          email: selectedData?.ledgerData?.transaction?.recipient?.email,
                          amount: selectedData?.ledgerData?.amount,
                          source: selectedData?.ledgerData?.transaction?.source,
                          category: selectedData?.ledgerData?.transaction?.category,
                          bank_account:
                            selectedData?.ledgerData?.transaction?.bank_account,
                        })
                      }
                    >
                      <SendIcon
                        stroke="#79716B"
                        width={16}
                        height={16}
                        className="mr-4"
                      />
                      Make new payment
                    </div>
                    <div
                      className="actionLink ms-1"
                      onClick={(event) =>
                        actionHandler(event, 'repeat_transaction', {
                          code: selectedData?.ledgerData?.transaction?.recipient?.code,
                          amount: selectedData?.ledgerData?.amount,
                          source: selectedData?.ledgerData?.transaction?.source,
                          category: selectedData?.ledgerData?.transaction?.category,
                          bank_account:
                            selectedData?.ledgerData?.transaction?.bank_account,
                          repeat: true,
                        })
                      }
                    >
                      <RetryIcon
                        stroke="#79716B"
                        width={16}
                        height={16}
                        className="mr-4"
                      />
                      Repeat transaction
                    </div>
                  </>
                )}

                <PDFDownloadLink
                  document={
                    <ReceiptPdf
                      data={{
                        ...selectedData?.ledgerData,
                        paidOn: singleTransaction?.data?.paidOn,
                        senderAccount: singleTransaction?.data?.senderAccount,
                      }}
                      companyData={companyData}
                      type="budget"
                    />
                  }
                  fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
                  style={{
                    textDecoration: 'none',
                    height: 40,
                    display: 'flex',
                    width: '100%',
                    fontFamily: 'Inter var !important',
                    color: '#212529',
                    alignItems: 'center',
                  }}
                  className="actionLink"
                  onClick={(event, instance) => generatePdfSuccess(event, instance)}
                >
                  <DownloadIcon className="mr-4" width={16} height={16} />
                  Download receipt
                </PDFDownloadLink>
              </>
            )}
          </div>
        );
      },
      [singleTransaction?.data?.code, loadActions],
    );

    useEffect(() => {
      if (!balances?.budgets?.length || !balances?.balances?.length)
        dispatch(getBalances());
    }, []);

    const [budgetList, setBudgetList] = useState([]);

    useEffect(() => {
      if (balances?.budgets?.length || balances?.balances?.length) {
        const budget = getAvailableBalance(balances?.budgets, 'budgets');
        const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
        setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
      }
    }, [balances?.budgets?.length, balances?.balances?.length]);

    async function loadOptions(search, loadedOptions, { page }) {
      let filteredOption = budgetList.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()),
      );

      return {
        options: groupSourceOptions(filteredOption),
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    }

    const openStatementModal = () => {
      setStatementModal(!statementModal);
    };

    if (statsLoading && !filtered) return <Loading isPage color="#D28B28" />;

    const show = !!transactions?.length || (filtered && !transactions?.length);

    return (
      <>
        <PendingOnboardingNotice />
        <MigrateOldBudgetInfo />

        <TopBar
          inputPlaceholder="Search"
          filterData={filterData}
          setSearchVal={setSearch}
          withOutSearch
          showBarSearch={show}
          searchVal={search}
          addButton={show && (data?.isBudgetOwner || canEditTransaction)}
          buttonName="Download statement"
          toggleButton={openStatementModal}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          handleFilterApply={handleFilterApply}
          withDate
          dateTitle="Transaction Date"
          showFilter={show}
        />

        {!!transactions.length ? (
          <Row className="mt-4 selected-budget-table">
            <Col md={12} className="mb-3 spaced-table">
              <Table
                columns={tableColumn}
                data={buildBudgetLedgerTableData(transactions)}
                pagination
                onRowClick={handleRowClick}
                hasMore={hasMore}
                hasCheckBox={false}
                currentPage={page}
                nextPage={() => handlePagination(nextPage)}
                previousPage={() => handlePagination(page - 1)}
                totalPage={Math.ceil(total / perPage)}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
              />
            </Col>
          </Row>
        ) : (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no transactions for this filter"
                  bodyText="Alter filter to see transactions"
                  withButton={false}
                />
              </div>
            ) : (
              <div className="mt-5">
                <TransactionEmptyStateData handleSelect={null} />
              </div>
            )}
          </div>
        )}

        <ModalComponent
          active={!!selectTransaction}
          onClick={() => setSelectTransaction(null)}
          selectedAsset={selectedAsset}
          assetData={assetData}
          setSelectedAsset={setSelectedAsset}
          singleData={selectTransaction?.transactionData}
          scroll={false}
        >
          <TransactionDetails
            setAssetData={setAssetData}
            setSelectedAsset={setSelectedAsset}
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </ModalComponent>

        <Modal show={confirmModal} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title={`${capitalizeFirstLetter(type)} transaction`}
            subTitle={`Are you sure you want to ${type} this transaction?`}
            onConfirm={handleConfirm}
            extraChild={
              (!(selectedOption?.balance?.code || selectedOption?.budget?.code) ||
                selectedOption.status === 'failed') && (
                <div className="border-top" style={{ maxWidth: '300px' }}>
                  <Row className="align-items-center">
                    <CustomSelectRadio
                      label="Where are you paying from?"
                      name="source"
                      placeholder="Select a source"
                      onChange={(val) => {
                        setSource(val);
                        selectedOption.status === 'failed' &&
                          handleUpdateRequest(selectedOption?.code, 'source', val.value);
                      }}
                      value={source}
                      isLoading={loadingBalances}
                      isDisabled={loadingBalances}
                      loadOptions={loadOptions}
                    />
                  </Row>
                </div>
              )
            }
            onCancel={closeModal}
            isDeleteDialog={type === 'cancel'}
            actionBtnText="Confirm"
            loading={
              retryLoading ||
              cancelLoading ||
              loadingRequery ||
              loadingPayNow ||
              loadingReview
            }
            disable={loadingUpdate}
            dialogIcon={type === 'retry' ? <ReloadIcon /> : null}
          />
        </Modal>

        <StatementModal
          isAudit
          isModalOpen={statementModal}
          account={{
            code: data?.code,
            name: data?.name,
            currency: data?.currency,
          }}
          closeHandler={openStatementModal}
        />

        <Modal show={isSchedule} centered dialogClassName="custom-dialog">
          <SchedulePayment
            onClose={() => {
              setIsSchedule(false);
            }}
            loading={loadingReview}
            setSchedule={handleConfirm}
            // handleCreate={onHandleSubmit}
          />
        </Modal>

        <ModalComponent active={!!declineRequest} scroll={false}>
          <ModalContainerWrap
            isWhite
            modalName="Decline request"
            onClose={() => {
              setDeclineRequest(false);
            }}
          >
            <DeclineRequest
              onClose={() => {
                setDeclineRequest(false);
              }}
              selectRequest={selectedOption}
              userCode={user?.user?.code}
              isLoaded={isLoaded}
              kebabAction
              hideTitle
            />
          </ModalContainerWrap>
        </ModalComponent>

        {showSendFunds && (
          <BujetiPayModal recipientCode={recipientCode} setOpenModal={setShowSendFunds} />
        )}
      </>
    );
  };
  return renderTable();
};
export default memo(BudgetTransaction);
