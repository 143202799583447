import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import RequestEmptyStateData from 'pages/Reimbursement/requestEmptystateData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { AnalyticsDisplay } from 'pages/Requests';
import {
  approveReimbursements,
  getReimbursements,
  getSingleReimbursement,
  reviewMultipleReimbursements,
  updateReimbursements,
} from 'redux/actions/ReimbursementsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { columnsReimbursement } from 'utils/mockData';
import { FEES, getQueryParams, updateStatus } from 'utils/utility';
import {
  buildReimbursementTableData,
  capitalizeFirstLetter,
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
} from '../../../utils/helper';
import AddReimbursement from '../EmployeeModal/AddReimbursement';
import AddReimbursementBankDetails from '../EmployeeModal/AddReimbursementBankDetails';
import ReimbursementModal from '../ManagerModal';
import '../style.less';
import FloatingCta from 'components/BulkAction/FloatingCta';
import BatchPaymentDialog from 'pages/BatchPayment/BatchPaymentDialog';
import CurrencyFormat from 'react-currency-format';
import {
  BankIcon,
  BankNoteO1Icon,
  CalenderIcon,
  CheckIcon,
  RetryIcon,
  SendIcon,
  SlashCircleIcon,
} from 'assets/icons';
import { Skeleton } from 'antd';
import { toastError, toastSuccess } from 'components/UI/toast';
import {
  RESET_BLOCK_REIMBURSEMENTS,
  RESET_FLAGS_REIMBURSEMENTS,
} from 'redux/reducers/ReimbursementsReducer';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import BulkApprovalDialog from '../../../pages/Transactions/components/BulkApprovalModal';
import { getSingleTransaction } from 'redux/actions/TransactionsAction';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import { getBalances } from 'redux/actions/BudgetsAction';
import ConfirmDialog from 'components/ConfirmDialog';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomDrawer from 'components/UI/CustomDrawer';
import DeclineReimbursement from '../ManagerModal/DeclineReimbursement';
import { approvalState } from 'components/FundRequest/ReviewerModal/approvalState';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { createBulkPayment, payNowAction } from 'redux/actions/PaymentAction';
import ModalComponent from 'components/UI/Modal/ModalComponent';

const ManageReimbursement = ({
  setIsOtherPopoverOpen,
  isOpen,
  toggleHandler,
  reimbursementCode,
  onRowSelect,
}) => {
  const [selectReimbursement, setSelectReimbursement] = useState(null);
  const [addBankDetails, setAddBankDetails] = useState(false);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({ status: ['pending'] });
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [selectedRowInformation, setSelectedRowInformation] = useState(null);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState(null);
  const [additionalNote, setAdditionalNote] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [action, setAction] = useState('');
  const [fees, setFees] = useState(0);
  const [reFetchData, setReFetchData] = useState(false);
  const [reSelectRows, setReSelectRows] = useState(false);
  const [fetchDataSuccess, setFetchDataSuccess] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const debouncedValue = useDebounce(search, 600);
  const [loadActions, setLoadActions] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [payNow, setPayNow] = useState(false);
  const [source, setSource] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetData, setAssetData] = useState(null);
  const [activeKeys, setActiveKeys] = useState([]);

  const {
    downloadAsset: { data: { asset } = {}, success: assetSuccess },
  } = useSelector(({ assets }) => assets);

  const {
    getReimbursement: {
      data: { meta = {}, summary = {} } = {},
      data = {},
      loading,
      success,
    },
    updateReimbursement: { success: updateSuccess, loading: updateLoading },
    reviewMultipleReimbursements: {
      data: { message } = {},
      success: reviewCompleted,
      loading: isReviewing,
    },
    approveReimbursement: { loading: loadingApprove, success: successApprove },
    declineReimbursement: { success: successDecline },
    getSingleReimbursement: {
      data: singleReimbursement = {},
      error,
      success: successSingleReimbursement,
    },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
    createBulkPayment: { success: paymentCompleted, loading: isPaying },
  } = useSelector(({ payments }) => payments);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const activeTxCode = useRef(null);

  const { page, total, hasMore, perPage, nextPage } = meta;
  const { reimbursements = [] } = data;

  const {
    selectedTableRows: { selectedRows, type: rowType },
  } = useSelector(({ table }) => table);

  const { permissions } = allPermissions();
  const canViewAdmin = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-view'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-create'],
  });

  const tableColumn = useMemo(() => columnsReimbursement, [reimbursements]);

  const rows = useMemo(
    () => buildReimbursementTableData(reimbursements),
    [reimbursements],
  );

  useEffect(() => {
    if (assetSuccess) {
      window.open(asset.url, 'Download');
    }
  }, [assetSuccess]);

  useEffect(() => {
    if (reviewCompleted || paymentCompleted) {
      setIsSubmit(false);
      dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: true } });
      if (reviewCompleted) {
        dispatch({
          type: RESET_BLOCK_REIMBURSEMENTS,
          blockType: 'reviewMultipleReimbursements',
        });
      }
      setAdditionalNote('');
      dispatch(getReimbursements({ ...filteredQuery, page, perPage }));
    }
  }, [reviewCompleted, paymentCompleted]);

  // useEffect(() => {
  //   if (!reimbursements.length && canViewAdmin && !location?.search)
  //     dispatch(getReimbursements());

  //   return () => {
  //     if ((filtered || location?.search) && canViewAdmin) dispatch(getReimbursements());
  //   };
  // }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canViewAdmin) {
      filteredQuery.search = debouncedValue;
      dispatch(getReimbursements({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewAdmin) {
      delete filteredQuery.search;
      dispatch(getReimbursements({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleRowClick = (row) => {
    setSelectReimbursement(row);
    activeTxCode.current = null;
  };

  useEffect(() => {
    if (reimbursements.length > 0 && !isFillData) {
      setIsFillData(true);

      const allReimbursements = reimbursements.map((data) => {
        return {
          value: data.user ? data.user.code : null,
          label: data.user ? `${data.user.firstName} ${data.user.lastName}` : '-',
          isSelected: false,
        };
      });
      const uniqueArray = allReimbursements.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );

      setFilterData([...filterData, { title: 'Beneficiary', list: uniqueArray }]);

      if (reimbursementCode) {
        dispatch(getSingleReimbursement(reimbursementCode));
      }
    }
  }, [reimbursements]);

  useEffect(() => {
    if (successSingleReimbursement && reimbursementCode) {
      const row = buildReimbursementTableData([singleReimbursement?.data?.reimbursement]);
      const element = row.find(
        ({ reimbursementsData: { code } }) => code === reimbursementCode,
      );
      setSelectReimbursement(element);
    }
  }, [successSingleReimbursement]);

  const handleFilter = (query) => {
    if (reSelectRows) setReSelectRows(false);
    isFiltered.current = !!Object.keys(query).length;
    const { status: s, currency: c, beneficiary: b } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const user = b ? b.toString() : undefined;
    dispatch(
      getReimbursements({
        status,
        currency,
        user,
      }),
    );
    setFilteredQuery({
      status,
      currency,
      user,
    });
  };

  useEffect(() => {
    dispatch(getBeneficiaryBank());
  }, []);

  useEffect(() => {
    if (location?.search && canViewAdmin) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getReimbursements({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
    if (success) {
      setFetchDataSuccess(true);
      dispatch({ type: RESET_FLAGS_REIMBURSEMENTS, blockType: 'getReimbursement' });
    } else {
      setFetchDataSuccess(false);
    }
  }, [success]);

  const handlePreviousPage = (page) => {
    dispatch(getReimbursements({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getReimbursements({ perPage, page, ...filteredQuery }));
  };

  const calculateSum = (requestLists) => {
    let totalSum = requestLists.reduce(
      (total, { reimbursementsData: { amount = 0 } }) => amount + total,
      0,
    );

    return totalSum / 100;
  };

  useEffect(() => {
    if (rowType === 'reimbursements') {
      let totalAllSum = calculateSum(selectedRows);

      const filteredPendingRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'pending' === rowStatus,
      );
      const filteredApprovedRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'approved' === rowStatus,
      );

      setSelectedRowInformation({
        all: {
          count: selectedRows.length,
          sum: totalAllSum,
        },
        pending: {
          count: filteredPendingRows.length,
        },
        approved: {
          count: filteredApprovedRows?.length,
        },
      });

      setFilteredSelectedRows({
        pending: filteredPendingRows,
        approved: filteredApprovedRows,
      });
    }

    // return totalPendingSum / 100;
  }, [selectedRows.length]);

  const handlePay = () => {
    setReFetchData(false);

    if (['decline', 'approve'].includes(action)) {
      if (action === 'decline' && !additionalNote)
        toastError('Please input reason for decline');

      const payload = {
        reimbursements: filteredSelectedRows?.['pending']?.map(
          ({ reimbursementsData: { code = '' } }) => code,
        ),
        decision: action,
        ...(additionalNote && { note: additionalNote }),
      };
      dispatch(reviewMultipleReimbursements(payload));
    } else if (action === 'pay') {
      let hasNoSource = 0;
      const payload = {
        items: filteredSelectedRows?.['approved']?.map(
          ({ reimbursementsData: { code = '', source } }) => {
            if (!source) hasNoSource += 1;
            return {
              code,
              source: source?.code,
            };
          },
        ),
      };
      if (hasNoSource > 0)
        return toastError(`${hasNoSource} of the selected items has no source`);
      dispatch(createBulkPayment(payload));
    }
  };

  useEffect(() => {
    if (!loading && reFetchData) setReFetchData(false);
  }, [loading]);

  useEffect(() => {
    if (updateSuccess && !reFetchData) setReFetchData(true);
    if (updateSuccess) {
      setTimeout(
        () =>
          dispatch({
            type: RESET_BLOCK_REIMBURSEMENTS,
            blockType: 'updateReimbursement',
          }),
        1000,
      );
    }
  }, [updateSuccess]);

  const handleAction = (data) => {
    const statusType = ['decline', 'approve'].includes(data) ? 'pending' : 'approved';

    if (['decline', 'approve', 'pay'].includes(data)) {
      setTotalAmount(calculateSum(filteredSelectedRows[statusType]) ?? 0);
      setFees(
        data === 'decline'
          ? 0
          : (selectedRowInformation?.[statusType]?.count ?? 0) * FEES,
      );
    }
    if (data === 'approve') {
      setFetchDataSuccess(false);
      setReSelectRows(false);
    }
    setAction(data);
    setIsSubmit(!isSubmit);
  };

  const handleUpdateReimbursement = (code, field, value) => {
    dispatch(
      updateReimbursements({
        code,
        [field]: value,
        showSuccessToast: false,
      }),
    );
  };

  useEffect(() => {
    if (!balances?.budgets?.length || !balances?.balances?.length)
      dispatch(getBalances());
  }, []);

  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  const updateReimbursement = (value, code) => {
    dispatch(updateReimbursements({ code, source: value }));
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    if (!['approve_schedule', 'decline_request'].includes(type))
      return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (successPayNow || successReview || successApprove || successDecline) {
      closeModal();
      isFiltered.current = true;
      dispatch(getReimbursements({ ...filteredQuery }));
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'getSingleReimbursement' });
      activeTxCode.current = null;
      setSource('');
      setIsSchedule(false);
      if (declineRequest) setIsLoaded(true);
    }
  }, [successPayNow, successReview, successApprove, successDecline]);

  useEffect(() => {
    if (error) {
      setIsPopoverOpen(false);
      activeTxCode.current = null;
    }
  }, [error]);

  const handleConfirm = ({ schedule }) => {
    const code = selectedOption?.code;
    if (type === 'pay') {
      if (selectedOption?.source?.code || selectedOption?.budget?.code) {
        return dispatch(payNowAction({ code }));
      } else if (!source) {
        return toastError('Select a source');
      }
      return dispatch(payNowAction({ code, source: source.value }));
    } else if (['approve and pay', 'approve', 'approve_schedule'].includes(type)) {
      handleApproveRequest({
        schedule,
        code: selectedOption?.approvalCode ?? selectedOption.code,
      });
    }
  };

  const handleApproveRequest = ({ schedule, code }) => {
    if (code.startsWith('apr')) {
      return dispatch(
        reviewRequest({
          code,
          status: 'approved',
          actionLater: schedule ? undefined : !payNow,
          schedule,
        }),
      );
    }
    return dispatch(
      approveReimbursements({
        code,
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (singleReimbursement?.data?.reimbursement) setLoadActions(true);
  }, [singleReimbursement?.data?.reimbursement]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.reimbursementsData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleReimbursement(code));
    },
    [activeTxCode.current],
  );

  const clearFilters = () => {
    setActiveKeys([]);
    dispatch(getReimbursements({ status: 'pending' }));
  };

  const handleClick = (data = []) => {
    setActiveKeys(data);
    isFiltered.current = true;
    dispatch(getReimbursements({ status: data }));
  };

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      useMemo(() => {
        if (selectedData?.reimbursementsData?.code !== activeTxCode.current) {
          setLoadActions(false);
          if (['pending', 'approved'].includes(status)) fetchSingleData(selectedData);
        }
      }, []);

      const { canApprovePay, yourTurnToApprove, yourApprovalRequest } = approvalState({
        data: singleReimbursement?.data?.reimbursement,
        user,
      });

      if (!loadActions && ['pending', 'approved'].includes(status))
        return (
          <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
            <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
          </div>
        );

      return (
        <div className="actions-dialog">
          {loadActions &&
            !yourTurnToApprove &&
            singleReimbursement?.data?.reimbursement?.status !== 'approved' && (
              <div
                className="actionLink"
                onClick={(event) => {
                  setIsPopoverOpen(false);
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                No options available
              </div>
            )}
          {singleReimbursement?.data?.reimbursement?.status === 'approved' && (
            <div
              className="actionLink"
              onClick={(event) => {
                actionHandler(event, 'pay', {
                  ...singleReimbursement?.data?.reimbursement?.transaction,
                  source: singleReimbursement?.data?.reimbursement?.source,
                });
              }}
            >
              <BankNoteO1Icon stroke="#79716B" width={16} height={16} className="mr-4" />
              Make payment
            </div>
          )}

          {loadActions && yourTurnToApprove && (
            <>
              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setPayNow(canApprovePay);
                    actionHandler(event, 'approve and pay', {
                      ...singleReimbursement?.data?.reimbursement,
                      approvalCode: yourApprovalRequest?.code,
                    });
                  }}
                >
                  <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                  Approve and pay
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setPayNow(false);
                  actionHandler(event, 'approve', {
                    ...singleReimbursement?.data?.reimbursement,
                    approvalCode: yourApprovalRequest?.code,
                  });
                }}
              >
                <CheckIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                Approve only
              </div>

              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setIsSchedule(true);
                    actionHandler(event, 'approve_schedule', {
                      ...singleReimbursement?.data?.reimbursement,
                      approvalCode: yourApprovalRequest?.code,
                    });
                  }}
                >
                  <CalenderIcon
                    stroke="#79716B"
                    width={16}
                    height={16}
                    className="mr-4"
                  />
                  Approve and schedule
                </div>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setDeclineRequest(true);
                  actionHandler(event, 'decline_request', selectedData);
                }}
              >
                <SlashCircleIcon
                  stroke="#79716B"
                  width={16}
                  height={16}
                  className="mr-4"
                />
                Decline request
              </div>
            </>
          )}
        </div>
      );
    },
    [singleReimbursement?.data?.reimbursement?.code, loadActions],
  );

  const assetSingleData = {
    ...selectReimbursement?.reimbursementsData,
    type: 'Reimbursement',
  };

  const loadingState = loading && !filtered && !reFetchData;

  if (loadingState)
    return (
      <section>
        <div className="mb-3">
          <AnalyticsDisplay loading />
        </div>

        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />
          <div className="ms-auto">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '7rem' }}
            />
          </div>
        </div>

        <div className="mt-4">
          <Table
            columns={tableColumn}
            data={rows}
            pagination
            hasCheckBox={false}
            loading
          />
        </div>
      </section>
    );

  return (
    <div className="reimbursements-wrapper position-relative">
      {!!summary?.['NGN']?.total && (
        <AnalyticsDisplay
          data={summary}
          activeKeys={activeKeys}
          handleClick={handleClick}
        />
      )}
      <TopBar
        showBarSearch={!!summary?.['NGN']?.total}
        searchVal={search}
        setSearchVal={setSearch}
        showFilter={!!summary?.['NGN']?.total}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        withOutSearch
        handleFilterApply={handleFilter}
      />

      {!reimbursements.length ? (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no reimbursements for this filter"
                bodyText="Alter the filter to see your reimbursement"
                buttonLabel="Clear filter"
                onClickHandler={clearFilters}
                withButton={true}
              />
            </div>
          ) : (
            <RequestEmptyStateData
              openReimbursementModal={toggleHandler}
              showAction={canCreate}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4 spaced-table">
              <Col xs={12}>
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasCheckBox={loadingState ? false : true}
                  hasMore={hasMore}
                  currentPage={page}
                  onRowSelect={onRowSelect}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  reSelectRows={reSelectRows}
                  fetchDataSuccess={fetchDataSuccess}
                  setReSelectRows={setReSelectRows}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loadingState}
                />
              </Col>
            </Row>
            {selectedRows.length > 0 && rowType === 'reimbursements' && (
              <FloatingCta
                selectedRowInformation={selectedRowInformation}
                selectedRows={selectedRows}
                handleAction={handleAction}
                hasPayment={false}
              />
            )}
          </Container>
        </>
      )}

      {selectedRows.length > 0 && rowType === 'reimbursements' && (
        <>
          {action === 'approve' ? (
            <BulkApprovalDialog
              openModal={isSubmit}
              columns={['Requested by', 'Amount', 'Vendor', 'Category', 'Source']}
              rows={
                filteredSelectedRows?.['pending']?.map(
                  (item) => item?.reimbursementsData,
                ) ?? []
              }
              tab={'reimbursement'}
              isLoading={isReviewing}
              onCancel={() => {
                if (reFetchData) {
                  setReSelectRows(true);
                  dispatch(getReimbursements({ ...filteredQuery, page, perPage }));
                }
                setIsSubmit(!isSubmit);
              }}
              handleUpdate={handleUpdateReimbursement}
              onConfirm={handlePay}
              isSuccess={updateSuccess}
              amount={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={totalAmount.toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
              description={
                <>
                  You are about to {action}{' '}
                  <span className="fw-bolder">
                    {['decline', 'approve'].includes(action)
                      ? selectedRowInformation?.['pending']?.count ?? '0'
                      : '0'}{' '}
                    requests.
                  </span>
                </>
              }
              fee={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={fees.toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
              icon={<BankIcon width="24" height="24" />}
              title={'approval'}
              total={
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={(totalAmount + fees).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                />
              }
              addNote={action === 'decline'}
            />
          ) : (
            <Modal
              show={isSubmit}
              centered
              dialogClassName="custom-dialog batch-payment-confirm"
            >
              <BatchPaymentDialog
                disabled={isReviewing}
                onClick={handlePay}
                onCancel={() => {
                  setIsSubmit(!isSubmit);
                  setAdditionalNote('');
                }}
                onNoteChanged={setAdditionalNote}
                note={additionalNote}
                amount={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={totalAmount.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                description={
                  <>
                    You are about to {action}{' '}
                    <span className="fw-bolder">
                      {action === 'decline'
                        ? selectedRowInformation?.['pending']?.count
                        : action === 'pay'
                        ? selectedRowInformation?.['approved']?.count
                        : '0'}{' '}
                      requests.
                    </span>
                  </>
                }
                fee={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={fees.toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                icon={<BankIcon width="24" height="24" />}
                title={`Please confirm your ${action === 'pay' ? 'payment' : 'decline'}.`}
                total={
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={(totalAmount + fees).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                loading={isReviewing || isPaying}
                addNote={action === 'decline'}
              />
            </Modal>
          )}
        </>
      )}

      <ModalComponent
        active={!!selectReimbursement}
        selectedAsset={selectedAsset}
        assetData={assetData}
        setSelectedAsset={setSelectedAsset}
        singleData={assetSingleData}
        scroll={false}
      >
        <ReimbursementModal
          setAssetData={setAssetData}
          setSelectedAsset={setSelectedAsset}
          selectReimbursement={selectReimbursement}
          setSelectReimbursement={setSelectReimbursement}
        />
      </ModalComponent>

      {/* {selectReimbursement && (
        <ReimbursementModal
          selectReimbursement={selectReimbursement}
          setSelectReimbursement={setSelectReimbursement}
        />
      )} */}

      {!addBankDetails ? (
        <AddReimbursement
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          setAddBankDetails={setAddBankDetails}
        />
      ) : (
        <AddReimbursementBankDetails
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          setAddBankDetails={setAddBankDetails}
        />
      )}

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} reimbursement`}
          subTitle={`Are you sure you want to ${type} this reimbursement?`}
          onConfirm={handleConfirm}
          extraChild={
            !(selectedOption?.source?.code || selectedOption?.budget?.code) &&
            type !== 'approve' && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      if (type !== 'pay')
                        updateReimbursement(val.value, selectedOption?.code);
                      else setSource(val);
                    }}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
          onCancel={closeModal}
          isDeleteDialog={type === 'cancel'}
          actionBtnText="Confirm"
          loading={loadingPayNow || loadingReview || loadingApprove}
        />
      </Modal>

      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => {
            setIsSchedule(false);
          }}
          loading={loadingReview}
          setSchedule={handleConfirm}
          extraChild={
            !(selectedOption?.source?.code || selectedOption?.budget?.code) &&
            type !== 'approve' && (
              <div className="mt-3">
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      if (type !== 'pay')
                        updateReimbursement(val.value, selectedOption?.code);
                      else setSource(val);
                    }}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
        />
      </Modal>
      <CustomDrawer show={declineRequest}>
        <div className="content">
          <DeclineReimbursement
            onClose={() => {
              setDeclineRequest(false);
              setIsLoaded(false);
            }}
            selectReimbursement={selectedOption}
            isLoaded={isLoaded}
            kebabAction
          />
        </div>
      </CustomDrawer>
    </div>
  );
};

export default ManageReimbursement;
