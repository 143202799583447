import {
  ArrowUpRightIcon,
  ChevronDown,
  CopyIcon,
  PencilIcon,
  WalletIconO1,
  WarningIcon,
} from 'assets/icons';
import bankIcon from 'assets/icons/bank-icon2.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { getAssets } from 'redux/actions/AssetsAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import {
  approveReimbursements,
  getAllReimbursementAsset,
  getReimbursements,
  getSingleReimbursement,
  updateReimbursements,
} from 'redux/actions/ReimbursementsAction';
import {
  asciiToHex,
  getAvailableBalance,
  getColor,
  getFormattedDate,
  groupSourceOptions,
  STATUS,
} from 'utils/helper';

import ImgCard from 'components/UI/ImgCard';

import BanksIcons from 'assets/icons/banks';
import classNames from 'classnames';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import CustomPopover from 'components/UI/Popover';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { getBudgets } from 'redux/actions/BudgetsAction';
import { payNowAction } from 'redux/actions/PaymentAction';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import BadgeType from 'components/UI/Table/BadgeType';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';
import DeadlinePicker from 'components/TransactionModal/DeadlinePicker';
import InputDialog from 'components/TransactionModal/InputDialog';

const STATUSES = {
  paid: 'approved',
  declined: 'declined',
};
const ReimbursementDetails = ({
  setStep,
  setIsLoading,
  selectReimbursement,
  setAssetViewer,
  singleData,
  getReceiptList,
  canMakePayment,
  setCanMakePayment,
  parentRef,
}) => {
  const [approve, setIsApproving] = useState(false);
  const [assignBudget, setAssignBudget] = useState(false);
  const [budgetValue, setBudgetValue] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const locationArray = location.pathname.split('/');
  const [key] = useState(locationArray[locationArray.length - 1]);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [addDeadLine, setAddDeadLine] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [ruleCode, setRuleCode] = useState(null);

  const [payNow, setPayNow] = useState(true);

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [budgetVisible, setBudgetVisible] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);

  const [receiptCode, setReceiptCode] = useState([]);

  const [saveButton, setSaveButton] = useState(false);

  const {
    approveReimbursement: { loading, success: approveSuccess, error: approveError },
    updateReimbursement: { loading: updateLoading, success, error },
    reimbursementReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    deleteCategories: { loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    reviewRequest: {
      loading: loadingApproval,
      success: approvalSuccess,
      error: approvalReviewError,
    },
  } = useSelector(({ approval }) => approval);

  const { status = '' } = singleData?.reimbursement || {};
  const singleReimbursementData = singleData?.reimbursement || {};

  const {
    reimbursementsData: {
      code,
      reviewer = {},
      reviewed_on = null,
      amount,
      receipt,
      category,
      description: reimbursementDescription,
      budget: budgetObject,

      user: reimbursementUser,
    },
  } = selectReimbursement;

  const {
    approvals: completedApprovals = [],
    approvalStages: stages = [],
    code: requestCode,
  } = singleReimbursementData?.approvalRequest || {};

  if (reviewer && reviewer.firstName && !singleReimbursementData?.approvalRequest) {
    completedApprovals.push({
      approver: { user: reviewer },
      status: STATUSES[singleReimbursementData?.status],
      created_at: singleReimbursementData?.reviewedOn,
    });
  }
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
    getTransferableBalance: { data: budgetBalance = {} },
  } = useSelector(({ budgets }) => budgets);

  const { budgets = [] } = budgetData;

  //
  const budget = singleReimbursementData?.budget;

  const attemptAssignReimbursement = (value) => {
    setBudgetValue(value);
    setIsApproving(!approve);
  };

  const handleAssignBudget = () => {
    setAssignBudget(!assignBudget);
  };

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  useEffect(() => {
    if (singleData?.reimbursement?.description && !description?.data.length) {
      setDescription({
        ...description,
        data: singleData?.reimbursement?.description,
      });
    }
  }, [singleData?.reimbursement?.description]);

  useEffect(() => {
    if (singleData?.reimbursement?.code) {
      dispatch(getAllReimbursementAsset(singleData?.reimbursement?.receipts));
    }
  }, [singleData]);

  //Everything that has to do with categories

  const onHandleGetSelected = (value) => {
    editReimbursement(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editReimbursement = (code, categoryCode) => {
    dispatch(updateReimbursements({ code, category: categoryCode }));
    setIsLoading('category');
    setIsEditingCategory(true);
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(true);
  };

  useEffect(() => {
    if (approvalSuccess || approveSuccess) {
      setBudgetLoading(false);
      setIsApproving(false);
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
  }, [approvalSuccess, approveSuccess]);

  useEffect(() => {
    if (!loadingApproval && approvalReviewError) {
      setIsEditingCategory(false);
      setBudgetLoading(false);
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
  }, [loadingApproval, approvalReviewError]);

  const attemptApprove = (isSchedule, pay) => {
    if (!singleData?.reimbursement?.source?.code && pay)
      return toastError('Please select a source');
    setCanMakePayment(!isSchedule && payNow && !pay);
    if (isSchedule) return setIsSchedule(true);
    return setIsApproving(!approve);
  };

  const approveReimbursement = ({ schedule }) => {
    if (assignBudget) {
      return dispatch(updateReimbursements({ code, budget: budgetValue.value }));
    }
    if (singleReimbursementData?.approvalRequest?.code && requestCode) {
      return dispatch(
        reviewRequest({
          code: requestCode,
          status: 'approved',
          actionLater: schedule ? undefined : !payNow,
          schedule,
        }),
      );
    }
    return dispatch(
      approveReimbursements({
        code,
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory.code));
  };

  const handleDispatch = () => {
    dispatch(getSingleReimbursement(code));
  };

  const deletedBudget = budgetObject?.status === 'deleted';

  const handleClick = () => {
    if (!deletedBudget)
      history.push(`/expenses/budgets/${budget?.code ?? budgetObject?.code}/overview`);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const handleDescription = () => {
    setDescription({
      ...description,
      isEditingDescription: true,
    });

    if (!!receiptCode?.receipt?.length) {
      dispatch(updateReimbursements({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }

    if (description?.data !== singleData?.reimbursement?.description) {
      return dispatch(updateReimbursements({ code, description: description?.data }));
    }
  };

  const saveBudget = (budget) => {
    setBudgetLoading(true);
    const balance =
      budget?.value?.startsWith('blc_') || budget?.value?.startsWith('bnk_')
        ? budget?.value
        : undefined;
    return dispatch(
      updateReimbursements({
        code,
        source: budget?.value,
        // balance,
        // budget: !balance ? budget?.value : undefined,
      }),
    );
  };

  useEffect(() => {
    if (!loadingApproval && approvalSuccess) {
      handleDispatch();
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'reviewRequest' });
    }
    if (!updateLoading && success) {
      setDescription({ visible: false, data: '', isEditingDescription: false });
      handleDispatch();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'updateReimbursement' });
    }
    if (!updateLoading && error) {
      handleDispatch();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'updateReimbursement' });
    }

    if (!loading && approveSuccess) {
      handleDispatch();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'approveReimbursement' });
    }
    if (!loading && approveError) {
      handleDispatch();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'approveReimbursement' });
    }
  }, [
    loadingApproval,
    approvalSuccess,
    approvalReviewError,
    updateLoading,
    success,
    error,
    loading,
    approveSuccess,
    approveError,
  ]);

  //
  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  useEffect(() => {
    if (receipt?.code) dispatch(getAssets(receipt?.code));
  }, [selectReimbursement]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(receiptAssets);
  };

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(true);
    }
  }, [receiptCode?.receipt]);

  const alreadyApprovedUser = completedApprovals.find(
    (item) => item.approver?.user?.code === user?.code,
  );

  const isLoading = loading || updateLoading || loadingApproval;

  const hasManager = user?.code === reimbursementUser.code && user?.manager?.code;

  const yourTurnToApprove =
    (stages.length &&
      stages.some((stage) => {
        return (
          stage.status === 'pending' &&
          stage.approvers.some(
            (approver) => approver.user.code === user.code && !alreadyApprovedUser,
          )
        );
      })) ||
    (status === 'pending' && !requestCode && !hasManager);

  const pendingApprovers = stages
    .map((stage) => {
      if (stage.status === 'pending') {
        return stage.approvers.map(({ user }) => {
          if (user.code !== alreadyApprovedUser?.approver?.user?.code) {
            return user;
          }
        });
      }
    })
    .flat()
    .filter((element) => !!element);

  const approvers = [];

  singleReimbursementData?.relatedApprovalRequests?.forEach((approvalRule) => {
    approvalRule.approvalStages.forEach((item) => {
      if ([1, 'all'].includes(item.threshold)) {
        approvers.push({
          rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
          status: 'pending',
          message: '',
          type: item.threshold,
          approvers: item?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });
  });

  singleReimbursementData?.relatedApprovalRequests?.forEach((item) => {
    item.approvals?.forEach((item) => {
      approvers.push({
        user: item?.approver?.user,
        date: getFormattedDate(item?.updated_at),
        status: item?.status,
        message: item?.reason ?? '',
      });
    });
  });

  if (
    singleReimbursementData?.status !== 'pending' &&
    singleReimbursementData?.reviewer !== null
  )
    approvers.push({
      user: singleReimbursementData?.reviewer,
      date: getFormattedDate(singleReimbursementData?.reviewed_on),
      status: singleReimbursementData?.status,
      message: singleReimbursementData?.note ?? '',
    });

  let reviewedOn =
    reviewed_on ||
    (completedApprovals.length &&
      completedApprovals[completedApprovals.length - 1].updated_at);

  useEffect(() => {
    if (yourTurnToApprove && key === 'approve') {
      attemptApprove(false, false);
    }
    if (yourTurnToApprove && key === 'decline') {
      setStep(2);
    }
  }, []);

  const handleCancelRequest = () => {
    setStep(2);
  };

  const openRule = (code) => {
    setRuleCode(code);
  };

  const bodyData = [
    {
      title: 'Requested by',
      value: `${singleData?.reimbursement?.user?.firstName} ${singleData?.reimbursement?.user?.lastName}`,
      icon: (
        <span>
          <ImgCard initials={singleData?.reimbursement?.user?.firstName?.slice('')[0]} />
        </span>
      ),
      withBadge: true,
    },
    {
      title: 'Expense date',
      value: selectReimbursement?.creationDate,
    },
    {
      title: 'Deadline on',
      value: selectReimbursement?.deadLine || (
        <span style={{ color: '#D28B28' }}>Add a deadline</span>
      ),
      onClick: () => {
        setAddDeadLine(true);
      },
    },
    {
      title: 'Reimbursement ID',
      value: selectReimbursement?.reimbursementsData?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setSaveButton(false);
    setReceiptCode('');
  };

  const closeApprovalRule = () => setRuleCode(null);
  const { BankAccounts = [] } = singleReimbursementData?.user ?? {};

  const isBalance = singleData?.reimbursement?.budget?.code?.startsWith('blc_');
  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  function checkConditions(approvers) {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  }

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            attemptApprove(false, false);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>
        <div
          className="actionLink"
          onClick={() => {
            attemptApprove(true, true);
            handleButtonToggle();
          }}
        >
          Approve and schedule payment
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (approvalSuccess || approveSuccess) {
      setIsApproving(false);
    }
  }, [approvalSuccess, approveSuccess]);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const [isPay, setIsPay] = useState(false);
  const [source, setSource] = useState();

  const handlePayTransactionModal = () => {
    setIsPay(!isPay);
  };

  const payTransaction = () => {
    const transactionCode = singleData?.reimbursement?.transaction?.code;

    if (
      singleData?.reimbursement?.source?.code ||
      singleData?.reimbursement?.budget?.code
    ) {
      return dispatch(payNowAction({ code: transactionCode }));
    } else if (!source) {
      return toastError('Select a source');
    }
    return dispatch(payNowAction({ code: transactionCode, source: source.value }));
  };

  useEffect(() => {
    if (successPayNow) {
      setIsPay(false);
    }
  }, [successPayNow]);

  const recipientName = selectReimbursement?.vendor;

  const recipientCode = selectReimbursement?.reimbursementsData?.vendor?.code;

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [tabKey, setTabKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setTabKey(tabKey);
    setScrollSize(0);
  };

  const showMakePayment =
    canMakePayment &&
    singleData?.reimbursement?.transaction?.code &&
    !saveButton &&
    ['approved'].includes(singleData?.reimbursement?.status);

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };
  const createdByMe = user?.code === singleData?.reimbursement?.user?.code;

  return (
    <>
      <div
        className={classNames(
          'px-4 bg-white fixed-header',
          scrollSize > 80 ? 'pb-0' : 'pb-2',
        )}
      >
        <PaymentHeader
          fontSize={fontSize}
          iconSize={iconSize}
          scrollSize={scrollSize}
          status={singleData?.reimbursement?.status}
          recipientLink={`/expenses/vendors/profile/${recipientCode}`}
          receipient={recipientName}
          moneyData={{
            currency: selectReimbursement?.reimbursementsData?.currency,
            amount: selectReimbursement?.reimbursementsData?.amount,
          }}
          badgeIcon={
            !!singleData?.violations?.length ? <WarningIcon className="me-1" /> : null
          }
          imageIcon={WalletIconO1}
          isRequest
          createdByMe={createdByMe}
          onClick={() =>
            setEditAmount(singleData?.reimbursement?.status === STATUS.PENDING)
          }
        />
      </div>

      <Tabs
        id="contolled-tab-example"
        activeKey={tabKey}
        onSelect={handleSelectKey}
        className="bg-white position-fixed w-100 pt-0 mt-0"
        style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
      >
        <Tab
          eventKey="details"
          title={<div className="d-flex">Details</div>}
          tabClassName="new-tab ms-4"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right pb-5">
            <div className="details-tab p-4">
              <div className="details-view mb-4">
                <p>Overview</p>
                <div className="container">
                  <DrawerBody data={bodyData} />

                  {singleData?.reimbursement?.transaction && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Transaction ID"
                        isLink={
                          singleData?.reimbursement?.transaction?.code
                            ? `/transactions/payments/${singleData?.reimbursement?.transaction?.code}/details`
                            : false
                        }
                        withAction={singleData?.reimbursement?.transaction?.code}
                        value={singleData?.reimbursement?.transaction?.code ?? '-'}
                        icon={
                          <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="details-view mb-4">
                <p>Recipient details</p>
                <div className="container">
                  <div className="mb-3">
                    <ItemInfo
                      title="Vendor name"
                      withAction
                      value={
                        <div className="d-flex align-items-center gap-2">
                          <ImgCard
                            size="small"
                            fullWidth={false}
                            initials={singleReimbursementData?.vendor?.name?.charAt(0)}
                          />
                          <span>{singleReimbursementData?.vendor?.name}</span>
                        </div>
                      }
                      action={() =>
                        history.push(
                          `/expenses/vendors/profile/${singleReimbursementData?.vendor?.code}`,
                        )
                      }
                      icon={<ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />}
                    />
                  </div>

                  {BankAccounts[0]?.bankName && (
                    <div className="mb-3">
                      <ItemInfo
                        title="Bank"
                        value={BankAccounts[0]?.bankName}
                        withBadge
                        icon={
                          <ImgCard
                            size="small"
                            fullWidth={false}
                            initials={BankAccounts[0]?.bankName?.charAt(0)}
                          >
                            <img
                              onError={handleImgError}
                              className="account-logo"
                              src={
                                BanksIcons[BankAccounts[0]?.bankName?.toLowerCase()] ??
                                bankIcon
                              }
                            />
                          </ImgCard>
                        }
                      />
                    </div>
                  )}

                  {BankAccounts[0]?.number && (
                    <ItemInfo title="Account number" value={BankAccounts[0]?.number} />
                  )}
                </div>
              </div>

              <div className="details-view mb-4">
                <p>Payment details</p>
                <div className="container">
                  {' '}
                  <div>
                    <ItemInfo
                      title="Category"
                      isLink={
                        singleData?.reimbursement?.category?.code
                          ? `/compliances/categories/${singleData?.reimbursement?.category?.code}/details`
                          : false
                      }
                      hideLinkIcon
                      value={
                        <BadgeType
                          value={{
                            value:
                              expenseCategories.value.label ??
                              singleData?.reimbursement?.category?.name ??
                              '-',
                            rightIcon: singleData?.reimbursement?.category?.code && (
                              <ArrowUpRightIcon
                                stroke={asciiToHex(
                                  expenseCategories.value.label ??
                                    singleData?.reimbursement?.category?.name ??
                                    '-',
                                )}
                                width="16"
                                height="16"
                              />
                            ),
                            color: getColor(
                              expenseCategories.value.label ??
                                singleData?.reimbursement?.category?.name ??
                                '-',
                            ),
                          }}
                        />
                      }
                      withAction={
                        ![STATUS.FAILED, STATUS.DECLINED].includes(status) &&
                        singleData?.reimbursement?.category?.name
                      }
                      action={() => setCategoryVisible(!categoryVisible)}
                      isLoading={isEditingCategory}
                      icon={<PencilIcon stroke="#D28B28" width="18" height="18" />}
                    />
                  </div>
                  {(!singleData?.reimbursement?.category?.name || categoryVisible) && (
                    <div className="padding-top-xxs">
                      <CategoryDropdown
                        placeholder="Select a category"
                        onChange={(val) => onHandleGetSelected(val)}
                        value={
                          expenseCategories.value ?? {
                            value: singleData?.reimbursement?.category?.code,
                            label: singleData?.reimbursement?.category?.name,
                          }
                        }
                        defaultValue={{
                          value: singleData?.reimbursement?.category?.code,
                          label: singleData?.reimbursement?.category?.name,
                        }}
                        name="category"
                      />
                    </div>
                  )}
                  <div className="padding-top-2">
                    <ItemInfo
                      title="Source"
                      isLink={
                        singleData?.reimbursement?.source?.name &&
                        (isBalance
                          ? `/accounts/${singleData?.reimbursement?.source?.code}`
                          : !isBalance
                          ? `/expenses/budgets/${singleData?.reimbursement?.source?.code}/overview`
                          : false)
                      }
                      withAction={
                        !['success', 'failed', 'approved'].includes(status) &&
                        singleData?.reimbursement?.source?.name
                      }
                      action={() => setBudgetVisible(!budgetVisible)}
                      value={
                        <div className="d-flex align-items-center gap-2">
                          {(singleData?.reimbursement?.source?.name ||
                            singleData?.reimbursement?.budget?.name) && (
                            <ImgCard
                              size="small"
                              fullWidth={false}
                              initials={(
                                singleData?.reimbursement?.source?.name ||
                                singleData?.reimbursement?.budget?.name
                              )?.charAt(0)}
                            >
                              <img
                                onError={handleImgError}
                                className="account-logo"
                                src={bankIcon}
                              />
                            </ImgCard>
                          )}

                          <span>
                            {(singleData?.reimbursement?.source?.name ||
                              singleData?.reimbursement?.budget?.name) ??
                              (status === 'pending' ? 'Add source' : '-')}
                          </span>
                        </div>
                      }
                      isLoading={budgetLoading}
                      icon={
                        !['success', 'failed', 'approved'].includes(status) && (
                          <PencilIcon stroke="#D28B28" width="18" height="18" />
                        )
                      }
                    />
                  </div>
                  {(!singleData?.reimbursement?.source?.name || budgetVisible) && (
                    <div className="padding-top-xxs">
                      <CustomSelectRadio
                        placeholder="Select a source"
                        name="source"
                        onChange={(val) => saveBudget(val)}
                        defaultValue={
                          singleData?.reimbursement?.source?.code && {
                            value: singleData?.reimbursement?.source?.code,
                            label: singleData?.reimbursement?.source?.name,
                          }
                        }
                        isLoading={loadingBalances}
                        isDisabled={loadingBalances}
                        loadOptions={loadOptions}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="padding-top-xxs">
                  <CustomTextarea
                    label="Description"
                    action={() => {
                      setDescription({ ...description, visible: !description.visible });
                      setSaveButton(!saveButton);
                    }}
                    isLoading={description.isEditingDescription}
                    icon={
                      ![STATUS.DECLINED, STATUS.PROCESSING].includes(status) && (
                        <PencilIcon stroke="#D28B28" width="18" height="18" />
                      )
                    }
                    name="description"
                    placeholder="Description"
                    value={description?.data}
                    disabled={
                      !description?.visible ||
                      [STATUS.DECLINED, STATUS.PROCESSING].includes(status)
                    }
                    onChange={handleDescriptionChange}
                    rowSize={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="attachements"
          title={<div className="d-flex">Attachments</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <p>Receipts</p>
                <div className="container d-flex gap-2 overflow-x-scroll">
                  {loadingReceipt && !receiptAssets?.length && <LoadingReciept />}
                  <NewReceiptUpload
                    receiptAssets={receiptAssets}
                    viewImgUrl={viewImgUrl}
                    addMore={true}
                    removeFile={!receiptCode.receipt?.length}
                    onChange={(value) =>
                      setReceiptCode({
                        receipt: value?.map((item) => item.assetCode),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="updates"
          title={<div className="d-flex">Updates</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <Timeline
                  data={approvers}
                  multipleRule={
                    singleReimbursementData?.relatedApprovalRequests?.length > 1
                  }
                  goToRule={openRule}
                  code={code}
                  pageFrom={`/requests/reimbursements`}
                />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>

      <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
        {showMakePayment && (
          <div className="d-flex align-items-center justify-content-end">
            <CustomButton
              className="base-button text-sm font-medium dark-button"
              onClick={handlePayTransactionModal}
              disabled={loadingPayNow}
              loading={loadingPayNow}
            >
              Make payment
            </CustomButton>
          </div>
        )}

        {saveButton && (
          <div className="d-flex align-items-center justify-content-end gap-2">
            <CustomButton
              onClick={cancelDescriptionEdit}
              className="base-button text-sm font-medium  black-transparent"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleDescription}
              className="base-button text-sm font-medium dark-button"
            >
              {description?.isEditingDescription ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                'Save changes'
              )}
            </CustomButton>
          </div>
        )}
        {yourTurnToApprove && !saveButton && (
          <div className="d-flex align-items-center justify-content-end">
            <CustomButton
              className="base-button danger-button text-sm font-medium"
              withoutBg
              onClick={handleCancelRequest}
              disabled={isLoading && approve}
              style={{ width: 'fit-content', padding: '10px', minWidth: 0 }}
            >
              Decline
            </CustomButton>

            <div className="d-flex">
              <button
                disabled={(isLoading && approve) || loadingBalances}
                onClick={() => {
                  attemptApprove(false, true);
                  setPayNow(canApprovePay);
                }}
                className={classNames('px-3 nowrap dropdown-btn action-btn text-white', {
                  ['rounded-3 px-4']: !canApprovePay,
                })}
              >
                {canApprovePay ? 'Approve and pay' : 'Approve only'}
              </button>

              {canApprovePay && (
                <CustomPopover
                  placement="top-start"
                  id="invoice-schedule"
                  zIndex="9999"
                  content={<Actions />}
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                >
                  <button
                    className="dropdown-btn"
                    disabled={(isLoading && approve) || loadingBalances}
                    onClick={handleButtonToggle}
                  >
                    <ChevronDown
                      color="#ffffff"
                      className={`icon ${isButtonToggle && 'is-toggled'}`}
                    />
                  </button>
                </CustomPopover>
              )}
            </div>

            {/* <CustomButton
              className="base-button text-sm font-medium dark-button"
              onClick={attemptApprove}
              disabled={(isLoading && approve) || loadingBalances}
              loading={isLoading}
            >
              Approve request
            </CustomButton> */}
          </div>
        )}
      </div>

      <Modal show={isPay} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Pay transaction`}
          subTitle={<>Are you sure you want to pay this transaction?</>}
          extraChild={
            !singleData?.reimbursement?.source?.code && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => setSource(val)}
                    value={source}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
          onConfirm={payTransaction}
          loading={loadingPayNow}
          onCancel={() => handlePayTransactionModal()}
          isDeleteDialog={false}
        />
      </Modal>

      <Modal show={editAmount} centered dialogClassName="custom-dialog">
        <InputDialog
          onClose={() => setEditAmount(false)}
          loading={isLoading}
          title="Update the amount"
          label="Enter the amount"
          onSubmit={({ amount, currency }) => {
            dispatch(
              updateReimbursements({
                code,
                amount,
              }),
            );
            setTimeout(() => dispatch(getReimbursements()), 300);
          }}
          defaultValue={selectReimbursement?.reimbursementsData?.amount / 100}
          currency={selectReimbursement?.reimbursementsData?.currency}
        />
      </Modal>
      <Modal show={addDeadLine} centered dialogClassName="custom-dialog">
        <DeadlinePicker
          onClose={() => setAddDeadLine(false)}
          loading={isLoading}
          onDatePicked={({ pick }) => {
            dispatch(
              updateReimbursements({
                code,
                deadLine: pick.date,
              }),
            );
          }}
        />
      </Modal>
      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => setIsSchedule(false)}
          loading={isLoading}
          setSchedule={approveReimbursement}
        />
      </Modal>
      {showCategoryDeleteModal && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete ${selectedCategory?.name} Category`}
            subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
            onCancel={() => setShowCategoryDeleteModal(false)}
            onDelete={onHandleDeleteCategory}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}
      {approve && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title="Approve request"
            subTitle="Are you sure you want to approve this request"
            onConfirm={approveReimbursement}
            loading={loadingApproval || loading}
            onCancel={() => setIsApproving(false)}
            isDeleteDialog={false}
          />
        </Modal>
      )}
    </>
  );
};

export default ReimbursementDetails;
