import { PlusOutlined } from '@ant-design/icons';
import { CancelICon, EditPencilIcon, TrashIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomTable from 'components/Table';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import { toastError } from 'components/UI/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  buildBulkBeneficiariesPayload,
  bulkCreateBeneficiaries,
  filterBeneficiaries,
  getBeneficiaries,
} from 'redux/actions/BeneficiariesAction';
import { getRoles } from 'redux/actions/RolesAction';
import { getTeams } from 'redux/actions/TeamsAction';
import { validateFormTable } from 'utils';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildTemporaryBeneficiariesTableData,
  capitalizeFirstLetter,
  checkPattern,
} from 'utils/helper';
import { temporaryColumnsBeneficiaries } from 'utils/mockData';
import { ReactComponent as UploadDialogIcon } from '../../assets/icons/upload-cloud.svg';
import '../BatchPayment/styles.scss';
import { fetchZohoPeople } from 'redux/actions/IntegrationsActions';
import Loading from 'components/UI/Loading';

const BulkAction = ({ rows, unSetRows, selectAllRow }) => {
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [checkall, setCheckAll] = useState(true);
  const dispatch = useDispatch();

  const {
    bulkCreateBeneficiaries: { loading: isLoading, success: isSuccess },
    bulkBeneficiariesPayload,
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      unSetRows();
      dispatch(
        buildBulkBeneficiariesPayload({
          ids: [],
          cancel: false,
          payload: [],
          actionType: null,
        }),
      );
      window.history.replaceState({}, '');
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setConfirmModal(false);
      dispatch(buildBulkBeneficiariesPayload({ cancel: false, payload: [] }));
      dispatch(filterBeneficiaries(true));
      history.push('/teams/people');
    }
  }, [isSuccess]);

  useEffect(() => {
    setCheckAll(true);
  }, [bulkBeneficiariesPayload.data]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (rows.length === 0 && checkall) {
      timeoutRef.current = setTimeout(() => {
        selectAllRow();
        setCheckAll(false);
      }, 300);
    }
  }, [rows]);

  const handleConfirm = () => {
    const payload = rows.map(({ beneficiaryData: option }) => ({
      ...option,
      manager: option.manager?.code,
      role: option.role?.code,
      team: option.team?.code,
      id: undefined,
    }));
    dispatch(bulkCreateBeneficiaries(payload));
  };

  const handleEdit = () => {
    dispatch(buildBulkBeneficiariesPayload({ actionType: 'edit' }));
  };

  return (
    <>
      {!!rows.length && (
        <div className="batch-footer position-sticky bottom-0 right-0 bg-black border-top h-auto w-100 bg-white px-2 py-3 d-flex justify-content-between align-items-center">
          <div>
            Total selected {rows.length}
            {rows.length > 1 ? ' members' : ' member'}
          </div>
          <div className="d-flex gap-2">
            <CustomButton
              className="border-btn px-4 text-sm"
              onClick={handleEdit}
              customClass={true}
            >
              Back to edit
            </CustomButton>
            <CustomButton
              className="base-button dark-button font-medium text-sm"
              onClick={() => setConfirmModal(true)}
            >
              Save
            </CustomButton>
          </div>
        </div>
      )}

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Please confirm your add`}
          subTitle={`You are about to create ${rows.length} ${
            rows.length > 1 ? 'members' : 'member'
          }.`}
          onConfirm={handleConfirm}
          onCancel={() => setConfirmModal(false)}
          isDeleteDialog={false}
          dialogIcon={<UploadDialogIcon />}
          actionBtnText="Confirm"
          loading={isLoading}
        />
      </Modal>
    </>
  );
};

const BulkMemberCreation = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const initial = {
    id: 0,
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    team: '',
    manager: '',
  };
  const initialMeta = {
    perPage: 100,
    totalPage: 0,
    currentPage: 1,
    startIndex: 0,
    endIndex: 100,
  };
  const columns = ['First name', 'Last name', 'Email', 'Role', 'Manager', 'Team'];
  const stickyColumns = ['First name', 'Last name'];

  const menuPortalTarget = document.body;
  const [beneficiariesTableData, setBeneficiariesTableData] = useState([]);
  const [formRows, setFormRows] = useState([{ ...initial }]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  const [meta, setMeta] = useState(initialMeta);
  const [actionType, setActionType] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { perPage } = meta;
  const { permissions } = allPermissions();
  const [lastId, setLastId] = useState(0);
  const [columnWidth, setColumnWidth] = useState({});
  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit', 'employee-create'],
  });

  const tableColumn = temporaryColumnsBeneficiaries.filter((item) =>
    canEdit ? item : item.Header !== 'Actions',
  );

  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles, success: isRolesFetched },
  } = useSelector(({ roles }) => roles);
  const {
    getTeams: { data: teamsData = {}, loading: isFetchingTeams, success: isTeamsFetched },
  } = useSelector(({ teams }) => teams);

  const {
    getBeneficiaries: {
      data: beneficiariesData = {},
      loading: isFetchingBeneficiaries,
      success: isBeneficiariesFetched,
    },
    bulkBeneficiariesPayload: { data: members, cancel, ids, actionType: typeOfAction },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    fetchZohoPeople: { loading: fetchingZohoUsers, data: zohoPeople = [], ...rest },
  } = useSelector(({ integrations }) => integrations);

  const inputRef = useRef(null);

  const tableRef = useRef(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const fetchPeople = () => {
    dispatch(fetchZohoPeople());
  };

  useEffect(() => {
    if (Array.isArray(zohoPeople) && zohoPeople.length) {
      setFormRows(zohoPeople);
      setLastId(zohoPeople.length);
    }
    return () => {
      window.history.replaceState({}, '');
    };
  }, [zohoPeople]);

  useEffect(() => {
    if (location?.state?.importZohoUsers) fetchPeople();
  }, []);

  useEffect(() => {
    const table = tableRef.current;

    if (!table) return;

    const updateColumnWidth = () => {
      const columnsHeads = table.querySelectorAll('thead th.sticky-column');
      const columns = Array.from(table.querySelectorAll('tbody td.sticky-column'));
      const widths = {};

      let sliceValues = {
        startIndex: 0,
        endIndex: stickyColumns.length + 1,
      };

      formRows.forEach((row) => {
        const columnSet = columns.slice(sliceValues.startIndex, sliceValues.endIndex);

        columnSet.forEach((column, index) => {
          if (index > 0) {
            const prevColumnWidth = widths[`column${index}`] || 0;
            const prevColumnOffset = widths[`offset${index}`] || 0;
            const prevColumnTotalWidth = prevColumnWidth + prevColumnOffset;

            column.style.left = `${prevColumnTotalWidth}px`;
            if (columnsHeads?.[index]) {
              columnsHeads[index].style.left = `${prevColumnTotalWidth}px`;
            }
          } else if (index == 0) {
            column.style.left = `${0}px`;
            columnsHeads[index].style.left = `${0}px`;
          }

          widths[`column${index + 1}`] = column.offsetWidth;
          widths[`offset${index + 1}`] = parseInt(column.style.left, 10) || 0;
        });

        setColumnWidth(widths);

        sliceValues = {
          startIndex: sliceValues.endIndex - 1,
          endIndex: sliceValues.endIndex + stickyColumns.length,
        };
      });
    };

    updateColumnWidth();
    // focusInput();

    if (formRows.length == 1) {
      window.addEventListener('resize', updateColumnWidth);
    }

    return () => {
      window.removeEventListener('resize', updateColumnWidth);
    };
  }, [formRows]);

  useEffect(() => {
    if (typeOfAction === 'edit') {
      setFormRows(members);
      dispatch(
        buildBulkBeneficiariesPayload({
          payload: [],
        }),
      );
    }
  }, [typeOfAction]);

  useEffect(() => {
    if (members.length) {
      const totalPage = Math.ceil((members.length + 1) / perPage);
      setMeta((prevValue) => ({ ...prevValue, totalPage }));
      setBeneficiariesTableData(
        buildTemporaryBeneficiariesTableData(
          members.slice(meta.startIndex, meta.endIndex),
        ),
      );
    }
  }, [members, meta.startIndex, meta.endIndex]);

  const handleNextPage = () => {
    setMeta((prevValue) => ({
      ...prevValue,
      currentPage: prevValue.currentPage + 1,
      startIndex: prevValue.endIndex,
      endIndex: prevValue.endIndex + perPage,
    }));
  };

  const handlePreviousPage = () => {
    setMeta((prevValue) => ({
      ...prevValue,
      currentPage: prevValue.currentPage - 1,
      startIndex: prevValue.startIndex - perPage,
      endIndex: prevValue.endIndex - perPage,
    }));
  };

  const handleAddAnotherMemeber = () => {
    setFormRows((prevValue) => [...prevValue, { ...initial, id: lastId + 1 }]);
    setLastId((prevValue) => prevValue + 1);
  };

  const handleSaveMembers = () => {
    let hasError = false;

    const unRequiredFields = ['manager', 'team'];

    const unTouchedRows = [];

    for (let i = 0; i < formRows.length; i++) {
      const row = formRows[i];
      const isValidRow = validateFormTable(row, unRequiredFields).isValid;

      if (!isValidRow) {
        toastError(validateFormTable(row, unRequiredFields).message);

        hasError = true;
        break; // Exit loop if any row has an error
      }

      if (validateFormTable(row, unRequiredFields).unTouchedRow !== null) {
        unTouchedRows.push(validateFormTable(row, unRequiredFields).unTouchedRow);
      } else if (!checkPattern(row.email, /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        toastError('Please enter a valid email');

        hasError = true;
        break; // Exit loop if any row has an error
      }
    }

    if (hasError) {
      return;
    }

    const filteredRows = formRows.filter((option, index) => {
      if (formRows.length === unTouchedRows.length) {
        //return the first row so that all the rows on the table don't disappear
        return index === 0;
      } else {
        return !unTouchedRows.includes(option.id);
      }
    });

    if (formRows.length !== unTouchedRows.length) {
      if (!!filteredRows.length) {
        dispatch(
          buildBulkBeneficiariesPayload({
            payload: [...filteredRows],
          }),
        );
      }
      setFormRows([]);
    } else if (formRows.length !== unTouchedRows.length && members.length === 0) {
      setFormRows([{ ...initial }]);
    }
  };

  const rolesOptionMapper = (data) => {
    return setRoleOptions(
      data?.map((datum) => {
        return {
          label: datum?.name,
          value: (
            <>
              <p>{datum?.description ?? <RoleParagraphs datum={datum} />}</p>
            </>
          ),
          code: datum?.code,
        };
      }),
    );
  };

  const handleOnChange = (index, key, value) => {
    setFormRows((prevFormDataList) =>
      prevFormDataList.map((formData) => {
        if (formData.id === index) {
          return { ...formData, [key]: value };
        }
        return formData;
      }),
    );
  };

  const handleRemoveRow = (index) => {
    if (formRows.length === 1 || members.length === 1) {
      setFormRows([{ ...initial }]);
      dispatch(
        buildBulkBeneficiariesPayload({
          payload: [],
        }),
      );
      return;
    }
    const updatedRows = formRows.filter((option) => option.id != index);

    const updatedTable = members.filter((option) => option.id != index);
    setFormRows(updatedRows);
    dispatch(
      buildBulkBeneficiariesPayload({
        payload: updatedTable,
      }),
    );
  };

  useEffect(() => {
    if (!isFetchingRoles && !rolesData?.length) dispatch(getRoles());
    else rolesOptionMapper(rolesData);
  }, [rolesData, isFetchingRoles]);

  useEffect(() => {
    if (!isFetchingTeams && !teamsData?.teams?.length) dispatch(getTeams());
    else
      setTeamOptions(
        teamsData?.teams?.map(({ code, name }) => ({
          value: name,
          code,
          label: capitalizeFirstLetter(name),
        })),
      );
  }, [teamsData, isFetchingTeams]);

  useEffect(() => {
    if (!isFetchingBeneficiaries && !beneficiariesData?.beneficiaries?.length)
      dispatch(getBeneficiaries());
    else
      setBeneficiaryOptions(
        beneficiariesData?.beneficiaries?.map(
          ({ user: { code, role, firstName, lastName } }) => ({
            value: role?.name,
            code,
            label: `${firstName} ${lastName}`,
          }),
        ),
      );
  }, [beneficiariesData, isFetchingBeneficiaries]);

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setActionType(type);
    if (type?.toLowerCase() === 'edit') {
      const updatedRows = members.filter(
        (option) => option.id != value?.beneficiaryData.id,
      );
      const selectedRow = members.filter(
        (option) => value?.beneficiaryData.id === option.id,
      );
      updatedRows.splice(0, 0, selectedRow[0]);
      setFormRows(updatedRows);
      dispatch(
        buildBulkBeneficiariesPayload({
          payload: [],
        }),
      );
      // focusInput();
    } else if (type?.toLowerCase() === 'cancel') {
      handleRemoveRow(value?.beneficiaryData.id);
    }
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', list)}
        >
          <EditPencilIcon /> Edit
        </div>
        <div
          className="actionLink svg-danger text-danger action-link-svg"
          onClick={(event) => actionHandler(event, 'cancel', list)}
        >
          <TrashIcon stroke="#79716B" width="16" height="16" className="mr-4" /> Delete
        </div>
      </div>
    );
  };

  if (fetchingZohoUsers) return <Loading color="#D28B28" size={18} />;

  return (
    <Container className="px-0 pt-4 members-page">
      {!!formRows.length && (
        <>
          <div className="mb-3">
            <h5 className="m-0">Type in details</h5>
            <h6 className="subtext mt-2">Upload details at your convenience.</h6>
          </div>
          <div ref={tableRef}>
            <Table
              className="custom-table-dimension members-table overflow-x-scroll"
              responsive
            >
              <thead>
                <tr>
                  {columns.map((option, indx) => {
                    return (
                      <React.Fragment key={indx}>
                        {stickyColumns.includes(option) ? (
                          <th className="position-sticky sticky-column">{option}</th>
                        ) : (
                          <th>{option}</th>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {formRows.map((formRow) => {
                  return (
                    <tr key={formRow.id}>
                      <td className="position-sticky sticky-column">
                        <CustomInput
                          label=""
                          placeholder="Enter first name"
                          type="text"
                          className="w-100 custon-form-field"
                          style={{ minWidth: 230 }}
                          onChange={({ target: { value } }) =>
                            handleOnChange(formRow.id, 'firstName', value)
                          }
                          value={formRow.firstName}
                        />
                      </td>
                      <td className="position-sticky sticky-column">
                        <CustomInput
                          label=""
                          placeholder="Enter last name"
                          type="text"
                          className="w-100 custon-form-field"
                          style={{ minWidth: 230 }}
                          onChange={({ target: { value } }) =>
                            handleOnChange(formRow.id, 'lastName', value)
                          }
                          value={formRow.lastName}
                        />
                      </td>
                      <td>
                        <CustomInput
                          label=""
                          placeholder="Enter email address"
                          type="email"
                          className="w-100 custon-form-field"
                          style={{ minWidth: 230 }}
                          onChange={({ target: { value } }) =>
                            handleOnChange(formRow.id, 'email', value)
                          }
                          value={formRow.email}
                        />
                      </td>
                      <td>
                        <CustomRoleSelect
                          label=""
                          options={roleOptions}
                          placeholder="Select a role"
                          textStyles={{
                            size: '14px',
                            color: '#79716b',
                            placeholder: '#C5C5C5',
                          }}
                          onChange={(value) => handleOnChange(formRow.id, 'role', value)}
                          value={formRow.role}
                          customStyles={{ control: { minWidth: 230 } }}
                          truncateClass={'limit-paragraph'}
                          menuPortalTarget={menuPortalTarget}
                        />
                      </td>
                      <td>
                        <CustomRoleSelect
                          label=""
                          textStyles={{
                            size: '14px',
                            color: '#79716b',
                            placeholder: '#C5C5C5',
                          }}
                          options={beneficiaryOptions}
                          placeholder="Select a manager"
                          value={formRow.manager}
                          customStyles={{ control: { minWidth: 230 } }}
                          onChange={(value) =>
                            handleOnChange(formRow.id, 'manager', value)
                          }
                          menuPortalTarget={menuPortalTarget}
                        />
                      </td>
                      <td>
                        <CustomRoleSelect
                          label=""
                          textStyles={{
                            size: '14px',
                            color: '#79716b',
                            placeholder: '#C5C5C5',
                          }}
                          options={teamOptions}
                          placeholder="Select a team"
                          onChange={(value) => {
                            handleOnChange(formRow.id, 'team', value);
                          }}
                          value={formRow.team}
                          customStyles={{ control: { minWidth: 230 } }}
                          labelClass={'fw-normal'}
                          showValue={false}
                          menuPortalTarget={menuPortalTarget}
                        />
                      </td>
                      <td className="position-sticky cancel">
                        <button
                          style={{ height: 48, appearance: 'none' }}
                          className="bg-white p-1 w-100 d-flex btn align-items-center justify-content-center"
                          onClick={() => {
                            handleRemoveRow(formRow.id);
                          }}
                        >
                          <CancelICon />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="w-100 batch-footer d-flex gap-2 justify-content-end">
            <button
              onClick={() => {
                window.history.replaceState({}, '');
                history.push('/teams/people');
              }}
              className="add-button add-action "
            >
              <span className="ms-1">Cancel</span>
            </button>
            <button onClick={handleAddAnotherMemeber} className="add-button add-action ">
              <PlusOutlined
                style={{
                  verticalAlign: 0,
                  fontSize: 14,
                  paddingLeft: 5,
                }}
              />
              <span className="ms-1">Add another</span>
            </button>
            <CustomButton
              onClick={handleSaveMembers}
              className="button-dimension-fit-content"
            >
              Save
            </CustomButton>
          </div>
        </>
      )}

      {!!members.length && (
        <Container className="px-0 mt-2">
          <h5>Member review</h5>
          <Row className="pt-2 pb-4">
            <Col xs={12} className="mb-4">
              <CustomTable
                columns={tableColumn}
                data={beneficiariesTableData}
                pagination
                currentPage={meta.currentPage}
                nextPage={() => handleNextPage()}
                previousPage={() => handlePreviousPage()}
                totalPage={meta.totalPage}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                getSelectedRows={BulkAction}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default BulkMemberCreation;
